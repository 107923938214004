import {get, post} from './Api';

export function SearchPoint({params}) {
  return get({url: '/searchPoint', params});
}
export function SearchEvent({params}) {
  return get({url: '/searchEvent', params})
}
export function SearchQRPointHistories({params}) {
  return get({url: '/searchQRPointHistories', params})
}
export function SearchQRPointShopHistories({params}) {
  return get({url: '/SearchQRPointShopHistories', params})
}
export function SearchEventPointHistories({params}) {
  return get({url: '/searchEventPointHistories', params})
}
// export function SearchExpirePointHistories({params}) {
//   return get({url: '/searchExpirePointHistories', params})
// }
export function SearchPointExpire({params}) {
  return get({url: '/searchPointExpire', params})
}
export function EventPointsHistory({params}) {
  return get({url: '/eventPointsHistory', params})
}
export function CreateCollectPoint({data}) {
  return post({url: '/createCollectPoint', data})
}
export function CreateCollectPointByQR({data}) {
  return post({url: '/createCollectPointByQR', data})
}
export function CreateCollectPointApprove({data}) {
  return post({url: '/createCollectPointApprove', data})
}
export function SearchDownloadPointHistory({params}) {
  return get({url: '/searchDownloadPointHistory', params});
}

export function SearchHistoryCoupons({params}) {
  return get({url: '/searchHistoryCoupons', params});
}