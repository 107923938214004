import React from "react";
// import RegisterMemberUnselect from "../../assets/images/register/register-member-unselect.png";
// import RegisterMemberSelected from "../../assets/images/register/register-member-selected.png";
// import RegisterShopUnselect from "../../assets/images/register/register-shop-unselect.png";
// import RegisterShopSelected from "../../assets/images/register/register-shop-selected.png";
import PrimaryButton from '../../components/button/PrimaryButton';
import { useNavigate } from 'react-router-dom'
import { Drawer } from '@mui/material';
import SecondaryButton from '../../components/button/SecondaryButton';
import ConditionDialog from '../../components/dialog/ConditionDialog';
import TopBanner from '../../components/layout/TopBanner';
import Cookies from '../../components/text/Cookies';
import { SearchRegister } from '../../services/Register'
import { useDispatch, useSelector } from 'react-redux';
import { setRegisterMain } from '../../features/register/RegisterMainSlice';
import { useLiff } from 'react-liff';
import { resetRegisterPetShop } from '../../features/register/RegisterPetShopSlice';
import TopLogo from '../../components/layout/TopLogo';

const HOST = window.location.origin
const URL_PATH = HOST + "/assets/img/ci/register/choice"

const RegisterMemberUnselect = URL_PATH + "/img-member-unselect.png"
const RegisterMemberSelected = URL_PATH + "/member-cat.png"
const RegisterShopUnselect = URL_PATH + "/img-shop-unselect.png"
const RegisterShopSelected = URL_PATH + "/img-shop-selected.png"

// import RegisterMemberUnselect from "../../assets/images/register/register-member-unselect.png";
// import RegisterMemberSelected from "../../assets/images/register/register-member-selected.png";
// import RegisterShopUnselect from "../../assets/images/register/register-shop-unselect.png";
// import RegisterShopSelected from "../../assets/images/register/register-shop-selected.png";

export default function RegisterMain() {
  const store = useSelector((state) => state.registerMain)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  // lineId
  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  React.useEffect(() => {
    getSearchRegister();
  }, []);

  function fnSetData(name, value) {
    const tmpObj = {};
    tmpObj[name] = value;
    dispatch(setRegisterMain(tmpObj));
  }

  async function getSearchRegister() {
    try {
      if (store['active'] === 0) {
        onClickImage(1);
      }
      const { data } = await SearchRegister({ params: { lineId } })
      if (+data['resultCode'] === 20000) {
        const body = data.resultData.body || {};
        console.log('body', data.resultData);
        if (body['memberId']) {
          navigate('/dogbook')
        } else if (body['employeeId'] && body['clinicPetShopId']) {
          // go MemberCardOwner
          navigate('/member-card-owner')
        }
      } else {

      }
    } catch (error) {
      console.log('getSearchRegister error', error)
    }

  }

  function onClickLinkCookie() {
    fnSetData('openCookieDialog', true);
  }

  function onClickNext() {
    dispatch(resetRegisterPetShop());
    if (store['active'] === 1) {
      navigate('/register-owner')
    } else if (store['active'] === 2) {
      navigate('/register-pet-shop')
    }
  }

  function checkAllowCookie() {
    let allowCookie;
    const allowCookieStorage = localStorage.getItem('allow_cookie')
    allowCookie = allowCookieStorage === 'true';
    return allowCookie;
  }

  function fnSetActiveImage(active) {
    if (active === 1) {
      fnSetData('imageList', [RegisterMemberSelected, RegisterShopUnselect]);
      fnSetData('textColorList', ['bText-primary-d', 'bText-gray']);
    } else if (active === 2) {
      fnSetData('imageList', [RegisterMemberUnselect, RegisterShopSelected]);
      fnSetData('textColorList', ['bText-gray', 'bText-primary-d']);
    } else {
      fnSetData('imageList', [RegisterMemberUnselect, RegisterShopUnselect]);
      fnSetData('textColorList', ['bText-gray', 'bText-gray']);
    }
  }

  function onClickImage(active) {
    fnSetData('active', active);
    if (checkAllowCookie()) {
      fnSetActiveImage(active)
    } else {
      // show popup allow cookie
      fnSetData('openCookieDrawer', true);
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    fnSetData('openCookieDrawer', open);
  };

  const toggleCookieDialog = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    fnSetData('openCookieDrawer', open);
  };

  function onOk(e) {
    console.log('on ok', e)
    localStorage.setItem('allow_cookie', 'true');
    fnSetData('openCookieDialog', false);
    fnSetData('openCookieDrawer', false);
    fnSetActiveImage(store['active']);
  }

  function onCancel(e) {
    console.log('on cancel', e)
    fnSetData('openCookieDialog', false);
    fnSetData('openCookieDrawer', false);
  }

  function onDrawerOk(e) {
    console.log('on onDrawerOk', e)
    localStorage.setItem('allow_cookie', 'true');
    fnSetData('openCookieDrawer', false);
    fnSetActiveImage(store['active'])
  }

  function onDrawerCancel(e) {
    console.log('on onDrawerOk', e)
    fnSetData('openCookieDrawer', false);
  }

  return (
    <>
      <div id="PageRegister">
        <TopLogo />
        <TopBanner type={"register"} />
        <div className="bContentMain">
          <div className="bContent">
            <div className="bSteper registerStep1" />
            <div className="text-[30px] text-center py-[30px]">
              ลงทะเบียนสำหรับ
            </div>
            <div className="bChoice">
              <div className="bChItem">
                <img
                  src={store['imageList'][0]}
                  className="w-[40%] mx-auto"
                  alt="register-member-unselect"
                  onClick={() => onClickImage(1)}
                />
                <div className={`text-[28px] pt-[12px] ${store['textColorList'][0]}`}>ผู้เลี้ยงแมว</div>
              </div>
              {/* <div className="bChItem">
                <img
                  src={store['imageList'][1]}
                  alt="register-shop-unselect"
                  className="w-[80%] mx-auto"
                  onClick={() => onClickImage(2)}
                />
                <div className={`text-[20px] leading-5 pt-1 ${store['textColorList'][1]}`}>
                  โรงพยาบาลสัตว์<br/>คลินิก และร้านเพ็ทช็อป
                </div>
              </div> */}
            </div>
            <div className="text-center pt-[50px] pb-[30px]">
              {/* <PrimaryButton variant="contained" onClick={onClickNext}>
                ถัดไป
              </PrimaryButton> */}
              <button className="btn" onClick={onClickNext}>
                ถัดไป
              </button>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="p-[10px] leading-[100%]">
            <p>
              Copyright © 2021 Boehringer Ingelheim Animal Health (Thailand) Ltd.
              All rights reserved. Use of this Site is subject to the Terms of Use
              and Privacy Statement.
            </p>
            <hr className="bText-gray my-2" />
            <p style={{ color: "gray" }}>
              This website is intended for residents of Thailand. The contents of
              this website are for information purposes only and are not intended
              to substitute for advice, treatment or recommendations of a
              veterinarian or healthcare professional. For questions about
              symptoms, treatment or medication, consult an appropriate veterinary
              or medical professional.
            </p>
          </div>
        </div>
      </div>
      {/*Drawer Allow Cookie*/}
      <Drawer
        anchor="bottom"
        open={store['openCookieDrawer']}
        onClose={toggleDrawer(false)}
      >
        <div className="p-4">
          <div>
            เราใช้คุกกี้เพื่อเพิ่มประสบการณ์ที่ดีของผู้ใช้และวิเคราะห์การเข้าถึง เมื่อท่านกด ‘ยอมรับ’
            ถือว่าท่านยินยอมในการใช้
            <span className="bText-primary-d" onClick={onClickLinkCookie}>
              นโยบายการใช้คุกกี้
            </span>
            ของเราแล้ว
          </div>
          <br />
          <div className="flex flex-row place-content-end gap-1">
            <PrimaryButton variant="contained" onClick={onDrawerOk}>
              ยอมรับ
            </PrimaryButton>
            <SecondaryButton variant="outlined" onClick={onDrawerCancel}>
              ไม่ยอมรับ
            </SecondaryButton>
          </div>
        </div>
      </Drawer>

      {/*Dialog Cookie*/}
      <ConditionDialog
        title="นโยบายการใช้คุกกี้"
        open={store['openCookieDialog']}
        toggle={toggleCookieDialog}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Cookies />
      </ConditionDialog>
    </>
  );
}