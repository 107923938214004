import React from "react";

import useWindowDimensions from "../../functions/getScreen";
import getTime from "../../functions/getTime";

import helper from "../../functions/helper";
const welcomeCard = helper.PUBLICPATH("/assets/img/ci/member-card/shop/welcome.png")
const blackCard = helper.PUBLICPATH("/assets/img/ci/member-card/shop/black.png")
const ultimateCard = helper.PUBLICPATH("/assets/img/ci/member-card/shop/ultimate.png")

export default function ShopCard(props) {
    const { width } = useWindowDimensions();

    const shopCardImage = () => {
        if (props.data) {
            if (props.data.tier === "WELCOME") return welcomeCard;
            else if (props.data.tier === "BLACK") return blackCard;
            else if (props.data.tier === "ULTIMATE") return ultimateCard;
            return null;
        }
    };

    return (
        <React.Fragment>
            {props.data && (
                <div className="relative">
                    <img className="w-full mt-[-20px]" src={shopCardImage()} alt="shopcard" />
                    <span
                        className="text-white absolute"
                        style={{
                            bottom: '10%',
                            left: '7%',
                        }}
                    >
                        <p style={{ fontSize: 'min(14px, 3vw)'}}>{`${props.data.shopName}`}</p>
                    </span>

                    <span
                        className="text-white absolute"
                        style={{
                            bottom: '10%',
                            left: '59%',
                        }}
                    >
                        <p style={{ fontSize: 'min(14px, 3vw)'}}>{getTime.getTimeString(props.data.createdDate)}</p>
                    </span>
                </div>
            )}
        </React.Fragment>
    )
}