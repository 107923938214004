/* eslint-disable react/jsx-no-undef */
import React from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import PetShopCircle from "../../assets/images/register/pet-shop-circle.png";
import LineBlue from "../../assets/images/icon/lineBlue.svg";

import Stack from "@mui/material/Stack";
import TopBanner from '../../components/layout/TopBanner';
import registerStep2 from '../../assets/images/register/register-step-2.svg';

import {Controller, useForm} from "react-hook-form";
import SecondaryButton from '../../components/button/SecondaryButton';
import PrimaryButton from '../../components/button/PrimaryButton';
import {useNavigate} from 'react-router-dom';
import {SearchClinicPetShop} from '../../services/Register';
import CustomPopup from '../../components/popup/CustomPopup';
import ConfirmShopPopup from '../../components/popup/ConfirmShopPopup';
import {useDispatch, useSelector} from 'react-redux';
import {setRegisterPetShop} from '../../features/register/RegisterPetShopSlice';
import TopLogo from '../../components/layout/TopLogo';

export default function RegisterPetShop() {
  const store = useSelector((state) => state.registerPetShop)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const {register, formState: {errors}, handleSubmit, control, watch} = useForm({
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      shopNo: store['shopNo'],
      isOwner: store['isOwner'],
      firstName: store['firstName'],
      lastName: store['lastName'],
      email: store['email'],
      mobileNumber: store['mobileNumber'],
    }
  });


  React.useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      if (name && type) {
        console.log(value, name, type)
        fnSetData(name, value[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    console.log('data', data);
    getSearchClinicPetShop(data);
  };

  function fnSetData(name, value) {
    const tmpObj = {};
    tmpObj[name] = value;
    dispatch(setRegisterPetShop(tmpObj));
  }


  async function getSearchClinicPetShop(formData) {
    try {
      const {data} = await SearchClinicPetShop({params: {shopNo: formData.shopNo}})
      if (+data['resultCode'] === 20000) {
        const body = data.body || {};
        console.log('body', body);
        if (Object.keys(body).length === 0) {
          fnSetData('openCancelPopup', true);
        } else {
          fnSetData('openConfirmShopPopup', true);
          if (body['type'] === 'CLINIC') {
            body['typeDisplay'] = 'คลินิก';
          } else if (body['type'] === 'SHOP') {
            body['typeDisplay'] = 'ร้านเพ็ทช็อป';
          } else {
            body['typeDisplay'] = '-';
          }
          fnSetData('clinicPetShopData', body);
        }
      } else {

      }
    } catch (error) {
      console.log('getSearchClinicPetShop error', error)
    }

  }

  function onClickBack() {
    navigate(-1)
  }

  function onClickOKConfirmShop() {
    const clinicPetShopData = store['clinicPetShopData'];
    const shopAddress = clinicPetShopData['shopAddress']
    const addressDetails = clinicPetShopData['addressDetails'] || ''
    const province = shopAddress['province']
    const subDistrict = shopAddress['subDistrict']
    const amphoe = shopAddress['amphoe']
    const zipcode = shopAddress['zipcode']
    fnSetData('address', `${addressDetails} ${subDistrict} ${amphoe} ${province} ${zipcode}`)
    fnSetData('deAddressId', shopAddress.id)
    fnSetData('deAddressDetail', addressDetails)
    navigate('/register-pet-shop-confirm')
  }

  return (
    <div id="PageRegister">
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete='off'>
        <TopLogo/>
        <TopBanner/>
        <div className="bContentMain">
          <div className="bContent">
            <div className="bSteper registerStep2"/>
              <div className="bg-white">
                <div className="p-3">
                  <div className="text-[30px] text-center">
                    ลงทะเบียนสำหรับ โรงพยาบาลสัตว์<br/>คลินิก และร้านเพ็ทช็อป
                  </div>
                  <div className="text-center">
                    <img src={PetShopCircle} alt="logo" className="w-[25%] inline-block"/>
                  </div>
                  <div className="py-[0px] px-[10px]">
                    <div className="primary-color title-size">
                      <div>ข้อมูลส่วนตัว</div>
                    </div>
                    <div>
                      <br/>
                      <div className="title-size">
                        กรอกรหัสร้านค้าของคุณ
                        <span className="text-red">*</span>
                      </div>
                      <div className="h-input">
                        <TextField
                          fullWidth required label="รหัสร้านค้า"
                          variant="standard"
                          error={!!errors?.shopNo}
                          helperText={errors?.shopNo?.message}
                          {...register("shopNo", {required: 'กรุณากรอกรหัสร้านค้า', value: store['shopNo']})}/>
                      </div>
                      <div className="text-[16px]">
                        สามารถรับรหัสร้านค้าได้ที่ผู้แทนบริษัทฯ หรือติดต่อ
                      </div>
                      <a className="text-[20px] bText-primary-d flex justify-start gap-1 w-[125px]" href="https://line.me/ti/p/@NextComboCat">
                        <img src={LineBlue} alt="line"/>
                        @NextComboCat
                      </a>
                      <div className="title-size pt-1">
                        เลือกตำแหน่ง
                        <span className="text-red">*</span>
                      </div>
                      <Controller
                        name="isOwner"
                        control={control}
                        render={({field}) => (
                          <RadioGroup
                            {...field}>
                            <FormControlLabel
                              value="yes"
                              control={<Radio/>}
                              label="เจ้าของร้าน/ผู้จัดการฝ่ายจัดซื้อ/ผู้จัดการสาขา"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio/>}
                              label="พนักงาน"
                            />
                          </RadioGroup>
                        )}
                      />
                      <div className="flex justify-between gap-[20px] h-input">
                        <TextField
                          fullWidth
                          required
                          label="ชื่อ"
                          variant="standard"
                          error={!!errors?.firstName}
                          helperText={errors?.firstName?.message}
                          {...register("firstName", {required: 'กรุณากรอกชื่อ'})}
                        />
                        <TextField
                          required
                          fullWidth
                          label="นามสกุล"
                          variant="standard"
                          error={!!errors?.lastName}
                          helperText={errors?.lastName?.message}
                          {...register("lastName", {required: 'กรุณากรอกนามสกุล'})}
                        />
                      </div>

                      {
                        store['isOwner'] === 'yes' &&
                        <div className="h-input">
                          <TextField
                            fullWidth
                            label="อีเมล"
                            variant="standard"
                            {...register("email")}
                          />
                        </div>
                      }
                      {
                        store['isOwner'] === 'yes' &&
                        <div className="h-input">
                          <TextField
                            required
                            fullWidth
                            label="เบอร์โทรศัพท์"
                            variant="standard"
                            error={!!errors?.mobileNumber}
                            helperText={errors?.mobileNumber?.message}
                            inputProps={{maxLength: 10}}
                            {...register("mobileNumber", {
                              required: "กรุณากรอกเบอร์โทรศัพท์",
                              minLength: {
                                value: 10,
                                message: "กรุณากรอกเบอร์โทรศัพท์อย่างน้อย 10 หลัก",
                              },
                            })}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="bg-white mt-[20px] p-[20px] flex gap-[12px]">
          <button type="button" className="btn _outline w-full"  onClick={onClickBack}>กลับ</button>
          <button className="btn w-full">ยืนยัน</button>
        </div>
      </form>

      <CustomPopup
        desc={store['popupDesc']}
        cancelText="ปิด"
        cancelFunction={() => {
          fnSetData('openCancelPopup', false);
        }}
        open={store['openCancelPopup']}
        onClose={() => {
          // setOpenCancelPopup(false);
        }}
      />
      <ConfirmShopPopup
        title={"ยืนยันข้อมูลร้านค้าของคุณ"}
        data={store['clinicPetShopData']}
        open={store['openConfirmShopPopup']}
        actionFunction={onClickOKConfirmShop}
        cancelFunction={() => {
          fnSetData('openConfirmShopPopup', false);
        }}
      />
    </div>
  )
}