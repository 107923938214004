import { get, post } from "./Api";

// const _mockupLineId = () => {
//   return "";
//   // return 'Ucbe4889b0204a5f20b2676d834080039'; // พี่ต้า
//   // return 'Uaac2ca5a02feab67a18d5521b572b5aa'; // ปุ้ย
//   // return 'U3b5b8cee10e8228099ea78672c3313b9'; // ขิง
//   // return 'U0cf7a131d328f8b211c5397a9cdde79b'; // อิ้ง
//   // return 'Ud78e279e89e2df2b6add3c8774fe5f29'; // ตั้ม
// };

export function SearchId({ params }) {
  return get({ url: `/searchId`, params });
}

export function UpdateMemberWelcomeBack({ data }) {
  return post({ url: `/updateMemberWelcomeBack`, data });
}