import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "swiper/css";
import "swiper/css/pagination";
import { useLiff } from "react-liff";
import { useDispatch, useSelector } from 'react-redux';
import { setMyPoint } from '../../features/MyPointSlice';
import { setLoading } from '../../features/LoadingSlice';
import PrimaryButton from '../../components/button/PrimaryButton';
import { useNavigate, useLocation } from 'react-router-dom';
import TopLogo from '../../components/layout/TopLogo';
import { CreateCollectPointApprove } from "../../services/MyPoint";
import MyPointSuccess from "./MyPointSuccess";
import helper from "../../functions/helper";

const initialQrCodePopup = {
  title: "แจ้งเตือน",
  desc: "",
  open: false
}
const initialPointSSPopup = {
  points: 0,
  listDetail: [
    { name: '', value: ''}
  ]
}

export default function MyPointPhotoUpload() {
  const store = useSelector((state) => state.myPoint)
  const store2 = useSelector((state) => state.loading)
  const [openSSPopup, setOpenSSPopup] = useState(false)
  const [pointSSPopup, setPointSSPopup] = useState(initialPointSSPopup)

  const [qrCodePopup, setQrCodePopup] = React.useState(initialQrCodePopup);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  useEffect(()=> {
    if (!store['selectedImage']) {
      navigate('/my-point')
    }
  }, [])

  const handleImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const urlPicture = reader.result.substring(reader.result.indexOf(",") + 1)
      console.log('urlPicture >>', `${urlPicture}`.substring(0,15));
      fnSetData('selectedImage', urlPicture)
    };
    reader.readAsDataURL(file);
  };

  function fnSetData(name, value) {
    const tmpObj = {};
    tmpObj[name] = value;
    dispatch(setMyPoint(tmpObj));
  }

  function fnSetLoading(value) {
    let tmpObj = {};
    tmpObj.loading = value;
    dispatch(setLoading(tmpObj));
  }

  async function createCollectPointApprove() {
    const imageResize = await helper.toImageSmall(store['selectedImage'])
    const payload = { memberId: store['memberId'], urlPicture: imageResize }
    console.log("payload Success >>", payload);
    fnSetLoading(true)

    try {
      const { data } = await CreateCollectPointApprove({ data: payload });
      console.log("resData.data >>", data);
      if (data.resultCode === 20000) {
        const createdOn = dayjs(data.body.createdOn).format('DD/MM/YYYY HH:mm:ss')
        const { points } = data.body
        const tmp = { ...pointSSPopup }
        tmp.points = points
        tmp.listDetail = [ 
          { name: 'วันที่/เวลา', value: createdOn },
          { name: 'สถานะ', value: "รอตรวจสอบ"},
        ]
        console.log('tmp >>', tmp)
        setPointSSPopup(tmp)
        // setOpenSSPopup(true)
        navigate(-1)
      } else {
        let tmp = { ...qrCodePopup }
        tmp.open = true
        tmp.desc = data.resultDescription
        setQrCodePopup(tmp);
      }
      fnSetLoading(false)
      fnSetData('selectedImage', null)
    } catch (error) {
      fnSetLoading(false)
      console.log("create pet error", error);
    }
  }
  if (openSSPopup) {
    return <MyPointSuccess title={"อัพโหลดรูป สำเร็จแล้ว"} points={pointSSPopup.points} listDetail={pointSSPopup.listDetail} toPage={-1} open={openSSPopup}/>
  }

  return (
    <div id="PageMyPointPhotoUpload">
      <TopLogo />
      <div id="my-point-upload-page" className="">
        <div id="my-point" className="bgPrimaryGD px-[20px] h-screen-logo">
          <div className="text-white text-center pt-[35px]">
            <p className="text-[28px]">
              อัพโหลดรูป
            </p>
            <p className="text-[36px] leading-[30px]">
              เพื่อสะสม NexCoin
            </p>
          </div>

          <div className="relative flex flex-col justify-center items-center h-[118px] mb-[17px]">
            <div className="flex justify-center items-center pt-[26px]">
              <div className="relative flex flex-col justify-center items-center gap-[5px] rounded-full h-[118px] w-[118px] bg-blueGray">
                { store['selectedImage'] ? (
                  <img
                    className="rounded-full object-cover h-[118px] w-[118px]"
                    src={`data:image/png;base64,${store['selectedImage']}`}
                    alt=""
                  />
                ) :
                  <p className="bText-primary-d text-[16px]">Sample</p>
                }
              </div>
              <p className="absolute bottom-[1px] text-white text-[16px]">
                เปลี่ยนรูปภาพ
              </p>
              <input
                className="opacity-0 absolute left-0 top-0 bottom-0 right-0"
                type="file"
                accept="image/*"
                onChange={(event) => handleImage(event)}
              />
            </div>
          </div>
          <hr className="border-blue my-[26px]" />
          <p className="text-white font-light text-[20px] leading-[24px] px-[7px]">
            {`หลังจากอัพโหลดรูปภาพ แอดมินจะทำการตรวจสอบรูปภาพ\nเมื่อตรวจสอบเสร็จสิ้น NexCoin จะถูกโอนให้คุณอัตโนมัติ`}
          </p>
        </div>
        <div
          className="flex flex-col justify-center bg-white px-[20px] pt-[16px] pb-[24px] fixed bottom-0 left-0 right-0 bFixedMaxScreen">
          { 
            store2.loading
            ?
            <PrimaryButton variant="contained" disabled={true}>
              <p className="text-[24px] text-white text-center">
                อัพโหลดรูปภาพ
              </p>
            </PrimaryButton>
            :
            <PrimaryButton variant="contained" onClick={createCollectPointApprove}>
              <p className="text-[24px] text-white text-center">
                อัพโหลดรูปภาพ
              </p>
            </PrimaryButton>
          }
        </div>
      </div>
    </div>
  );
 
}
