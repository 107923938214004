import {Dialog, DialogActions, DialogContent, DialogTitle, styled} from '@mui/material';
import Close from '../../assets/images/icon/close-gray.svg';
import SecondaryButtonSmall from '../button/SecondaryButtonSmall';
import PrimaryButtonSmall from '../button/PrimaryButtonSmall';
import React from 'react';

const CustomDialog = styled(Dialog)({
    '& .MuiTypography-root': {
        fontFamily: 'inherit',
    },
    '& .MuiDialog-container': {
        marginLeft: 25,
        marginRight: 25,
    },
    '& .MuiDialogTitle-root': {
        fontFamily: 'inherit',
        paddingRight: 15,
        paddingLeft: 0,
        paddingBottom: 0
    },
    '& .MuiPaper-root': {
        fontFamily: 'inherit',
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        maxHeight: '100%',
        borderRadius: 5
    },
    '& .MuiDialogContent-root': {
        fontFamily: 'inherit',
        marginLeft: 1,
        marginRight: 1,
        padding: 0,
        '& .MuiTypography-root': {
            color: 'black',
            // fontSize: 'calc(100vh * 0.0159)'
        },
    },
    '& .MuiDialogActions-root': {
        display: 'block',
        padding: '20px 5%',
    },
})
export default function ConditionDialog({title, open, toggle, children, onOk, onCancel}) {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }

    React.useEffect(() => {
        console.log('width', width)
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const {width} = windowDimensions;
    return (
        <>
            <CustomDialog
                fullScreen={true}
                open={open}
                onClose={toggle(false)}
                maxWidth="xl"
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    <div className="flex flex-row-reverse">
                        <img src={Close} alt="close" onClick={toggle(false)} className="h-6"/>
                    </div>
                    <div className="text-[28px] text-[#00B960] pl-4 mt-[-10px]">
                        {title}
                    </div>
                </DialogTitle>
                <DialogContent dividers={false}>
                    <div className={'pl-[7%] pr-[3%] pt-4 bg-[#F8F8F8]'} style={{fontSize: width * 0.04}}>
                        <main>{children}</main>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="grid grid-cols-2 gap-2">
                        <SecondaryButtonSmall variant="outlined" className="w-[100%]" onClick={onCancel}>
                            ไม่ยินยอม
                        </SecondaryButtonSmall>
                        <PrimaryButtonSmall variant="contained" className="w-[100%]" onClick={onOk}>
                            ยินยอม
                        </PrimaryButtonSmall>
                    </div>
                </DialogActions>
            </CustomDialog>
        </>
    )
}