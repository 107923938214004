import { get, post } from "./Api";

export function SearchShopProfile({ params, employeeId, clinicPetShopId }) {
  return get({ url: `/searchShopProfile?employeeId=${employeeId}&clinicPetShopId=${clinicPetShopId}`, params });
}

export function SearchEmployeeDetail({ params, clinicPetShopId }) {
  return get({ url: `/searchEmployeeDetail?clinicPetShopId=${clinicPetShopId}`, params });
}

export function PostUpdateShopProfile({ data }) {
  return post({ url: `/updateShopProfile`, data });
}

export function PostUpdateEmployee({ data }) {
  return post({ url: `/updateEmployee`, data });
}

export function PostDeleteEmployee({ data }) {
  return post({ url: `/deleteEmployee`, data });
}