import axios from "axios";
import { Logger } from "./Logger"

console.log('process.env >>', process.env)
export const baseUrl = process.env.REACT_APP_BASE_URL || 'https://api-ngs.scapp.work'

export const tempLineId1 = 'Ud78e279e89e2df2b6add3c8774fe5f29';
export const headers = {
    Accept: 'application/form-data',
    'Content-Type': 'application/json',
};

axios.interceptors.request.use(function (config) {
    const tranId = Logger.getTranID()
    config.tranId = tranId
    Logger.info(`${config.method} REQ: ${tranId} => ${config.url}`, { params: config.params, payload: config.data })
    return config;
  }, function (error) {
    Logger.error(`ERROR Request`, { error: `Error ${error}` })
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    const { config, data } = response
    Logger.info(`${config.method} RES: ${config.tranId} => ${config.url}`, { params: config.params, payload: config.data, data: data })
    return response;
  }, function (error) {
    Logger.error(`ERROR Response`, { error: `Error ${error}` })
    return Promise.reject(error);
  });

export function get({url, params}) {
    return axios({
        url,
        method: 'get',
        baseURL: baseUrl,
        headers: headers,
        params,
        timeout: 20000
    })
}

export function post({url, params, data}) {
    return axios({
        url,
        method: 'post',
        baseURL: baseUrl,
        headers: headers,
        params,
        data,
        timeout: 20000
    })
}