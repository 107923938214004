function getTimeString(date) {
  var getDate = new Date(date);
  var year = getDate.getFullYear();
  var month = (getDate.getMonth() + 1).toString().padStart(2, "0");

  return month + "/" + year;
}

function getFullTimeString(date) {
  var getDate = new Date(date);
  var year = getDate.getFullYear();
  var day = ("0" + getDate.getDate()).slice(-2);
  var month = (getDate.getMonth() + 1).toString().padStart(2, "0");

  return day + "/" + month + "/" + year;
}

function getYearRange(date) {
  var getDate = new Date(date);
  const now = new Date();
  const ageInMonths =
    (now.getFullYear() - getDate.getFullYear()) * 12 +
    (now.getMonth() - getDate.getMonth());
  if (ageInMonths < 1) {
    const ageInDays = Math.floor((now - getDate) / (1000 * 60 * 60 * 24));
    return ageInDays + " วัน";
  } else if (ageInMonths < 12) {
    return ageInMonths + " เดือน";
  } else {
    const ageInYears = Math.floor(ageInMonths / 12);
    return ageInYears + " ปี";
  }
}

const getTimeFunction = { getTimeString, getFullTimeString, getYearRange };

export default getTimeFunction;
