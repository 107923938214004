import React, { useEffect } from "react";
import { useLiff } from "react-liff";

export default function Game01() {
  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;
  // useEffect(()=>{
  //   const reUrl = `https://dev-game-ngs.scapp.work/?userId=${lineId}`
  //   alert('reUrl >> ' + reUrl)
  //   // window.location.href = reUrl
  //   liff.openWindow({ url: reUrl, external: false });
  // }, [])
  const reUrl = `https://dev-game-ngs.scapp.work/?userId=${lineId}`
  if (liff.isLoggedIn()) {
    return (
      <div className="game-page">
        <div style={{ width: '100vw', height: '100vh'}}>
          {/* Game01 : {lineId} */}
          <iframe src={`https://sourcecode.co.th`} title="little-pet" width="100%" height="100%">
          </iframe>
  
        </div>
      </div>
    )
  }
  return (
    <div>Loading...</div>
  )
  
}
