import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import BottomNavigation from "@mui/material/BottomNavigation";
import Paper from "@mui/material/Paper";
import stylez from "@emotion/styled";
import { styled } from "@mui/material/styles";

import location from "../../assets/images/icon/location.svg";
import paperplane from "../../assets/images/icon/paperplane.png";
import telephone from "../../assets/images/icon/phone.svg";
import facebook from "../../assets/images/icon/facebook.svg";
import line from "../../assets/images/icon/line.svg";
import LogoHeader from "../../assets/images/logo-header.png";
import PrimaryButton from "../../components/button/PrimaryButton";
// import SpectraNearYouCoupon from "./SpectraNearYouCoupon"
import SpectraNearYouDetail from "./SpectraNearYouDetail";

const Div = stylez.div`
    .container {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #dc143c;
}

.card {
  width: 400px;
  height: 180px;
  border-radius: 5px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 10px 10px;
  position: relative;
}

.main,
.copy-button {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}
.card::after {
  position: absolute;
  content: "";
  height: 40px;
  right: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 70px;
  background-color: #dc143c;
  width: 40px;
}

.card::before {
  position: absolute;
  content: "";
  height: 40px;
  left: -20px;
  border-radius: 40px;
  z-index: 1;
  top: 70px;
  background-color: #dc143c;
  width: 40px;
}

.co-img img {
  width: 100px;
  height: 100px;
}
  `;

const RouteButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "showLabel",
})({
  fontFamily: "inherit",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "15px",
  height: "15px",
  borderRadius: "28px",
  backgroundColor: "#00693E",
  color: "white",
  minWidth: "100px",
  gap: "10px",
  justifyContent: "center",
  alignItems: "center",
  flex: "1",
  display: "flex",
  padding: "15px",
});

const InfoButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "showLabel",
})({
  fontFamily: "inherit",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "15px",
  height: "15px",
  borderRadius: "28px",
  backgroundColor: "#E1E8F2",
  color: "#00693E",
  minWidth: "100px",
  gap: "10px",
  justifyContent: "center",
  alignItems: "center",
  flex: "1",
  display: "flex",
  padding: "15px",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ShowRouteButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "showLabel",
})({
  fontFamily: "inherit",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "21px",
  height: "48px",
  borderRadius: "28px",
  backgroundColor: "#00693E",
  color: "white",
  minWidth: "100px",
  gap: "10px",
  justifyContent: "center",
  alignItems: "center",
  flex: "1",
  display: "flex",
  padding: "15px",
  marginTop: "20px",
});

const BackButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "showLabel",
})({
  fontFamily: "inherit",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "24px",
  height: "48px",
  borderRadius: "28px",
  backgroundColor: "white",
  color: "#00693E",
  minWidth: "100px",
  gap: "10px",
  justifyContent: "center",
  alignItems: "center",
  flex: "1",
  display: "flex",
  padding: "15px",
});

export default function SpectraNearYouCard(props) {
  const [distance, setDistance] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const HaveTime =
    props.openTime !== null &&
    props.openTime !== undefined &&
    props.openTime !== "";

  // const pull_data = (data) => {
  //     setOpen2(data);
  // }

  const send_data_back = () => {
    console.log("propz", props);
    props.funz(props);
    props.func(false);
  };

  const handleDistance = () => {
    if (props.distance < 1000) {
      setDistance(Math.round(props.distance) + " ม.");
    } else {
      setDistance((Math.round(props.distance) / 1000).toFixed(1) + " กม.");
    }
  };

  const handleRoute = () => {
    if (props.distance < 1000) {
      return (
        "https://www.google.com/maps/dir/?api=1&destination=" +
        props.latitude +
        "," +
        props.longitude
      );
    } else {
      return (
        "https://www.google.com/maps/dir/?api=1&destination=" +
        props.latitude +
        "," +
        props.longitude
      );
    }
  };

  React.useEffect(() => {
    handleDistance();
  }, []);

  return (
    <React.Fragment>
      <Card sx={{ minWidth: 275, marginBottom: "15px" }}>
        <CardContent>
          <Typography
            sx={{
              fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
              fontSize: 24,
            }}
            color="text.primary"
            component={"div"}
          >
            <img src={location} alt="location" style={{ display: "inline" , marginTop:"-8px" }} />{" "}
            {props.shopName}
          </Typography>

          <Typography sx={{ mb: 1.5 }} color="text.secondary" component={"div"}>
            <Grid container spacing={2}>
              <Grid item xs={6} display="flex">
                ช่องทางการติดต่อ:
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="end"
                alignItems="end"
              >
                {props.mobileNumber && (
                  <Link href={"tel:" + props.mobileNumber} target="_blank">
                    <img src={telephone} alt="telephone" style={{marginRight : "5px", marginBottom :"5px"}} />
                  </Link>
                )}
                {props.facebook && (
                  <Link href={props.facebook} target="_blank">
                    <img src={facebook} alt="facebook" style={{marginRight : "5px", marginBottom :"5px"}}/>
                  </Link>
                )}
                {props.line && (
                  <Link
                    href={"http://line.me/ti/p/~" + props.line}
                    target="_blank"
                  >
                    <img src={line} alt="line" style={{marginRight : "5px", marginBottom :"5px"}}/>
                  </Link>
                )}
              </Grid>
            </Grid>
            <Divider light/>
          </Typography>

          <Typography variant="body1" component={"div"}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <RouteButton
                  variant="contained"
                  href={handleRoute()}
                  target="_blank"
                >
                  <img src={paperplane} alt="arrow" /> แสดงเส้นทาง {distance}
                </RouteButton>
              </Grid>
              <Grid
                item
                xs={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <InfoButton variant="contained" onClick={send_data_back}>
                  ข้อมูลและสิทธิ์พิเศษ
                </InfoButton>
              </Grid>
            </Grid>
          </Typography>
        </CardContent>
      </Card>

      {/* <div>
                <React.Fragment>
                    <div className="flex justify-center text-center top-logo">
                        <img src={LogoHeader} alt="logo" className="w-[60px]" />
                    </div>
                    <div
                        style={{
                            background:
                                "linear-gradient(152.04deg, rgb(10, 65, 151) -0.97%, rgb(32, 159, 237) 81.57%)",
                            minHeight: "calc(100vh)",
                            padding: '50px 10px 10px 10px'
                        }}
                    >
                        <Card sx={{ minWidth: 275, marginBottom: '15px' }}>
                            <CardContent>
                                <Typography sx={{
                                    fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                                    fontSize: 30
                                }} color="text.primary"
                                    component={'div'}
                                >
                                    {props.shopName}
                                </Typography>

                                <Typography sx={{
                                    mb: 1.5,
                                    fontSize: 20
                                }} color="text.secondary" component={'div'}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <p>ระยะทาง:</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p style={{
                                                textAlign: 'end',
                                                color: '#29A3EE',
                                                fontSize: 35
                                            }}>{distance}</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p>เวลาทำการ:</p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {HaveTime ?
                                                (<p style={{ textAlign: 'end' }}>{`${props.openTime.substring(0, 5)} - ${props.closeTime.substring(0, 5)}`} น.</p>) :
                                                (<p style={{ textAlign: 'end' }}>ไม่มีข้อมูล</p>)
                                            }
                                        </Grid>
                                    </Grid>
                                </Typography>

                                <Typography sx={{ mb: 1.5 }} color="text.secondary" component={'div'}>
                                    <p>ช่องทางการติดต่อ</p>
                                    {props.mobileNumber &&
                                        <Link href={"tel:" + props.mobileNumber} target="_blank" underline="none" style={{
                                            float: 'left', clear: 'left'
                                        }}><img src={telephone} alt="telephone" style={{ display: 'inline' }} /> {props.mobileNumber}</Link>
                                    }
                                    {props.facebook &&
                                        <Link href={props.facebook} target="_blank" underline="none" style={{
                                            float: 'left', clear: 'left'
                                        }}><img src={facebook} alt="facebook" style={{ display: 'inline' }} /> {props.shopName}</Link>
                                    }
                                    {props.line &&
                                        <Link href="#" underline="none" style={{
                                            float: 'left', clear: 'left', cursor: 'default'
                                        }}><img src={line} alt="line" style={{ display: 'inline' }} /> {props.line}</Link>
                                    }
                                </Typography>

                                <Typography sx={{ mb: 1.5 }} color="text.secondary" component={'div'}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                            <ShowRouteButton
                                                variant="contained"
                                                href={handleRoute()}
                                                target="_blank"
                                                style={{
                                                    fontSize: '22px',
                                                }}
                                            >
                                                <img src={paperplane} alt="arrow" /> แสดงเส้นทาง
                                            </ShowRouteButton>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </CardContent>
                        </Card>
                        <PrimaryButton
                            variant="contained"
                            style={{
                                minWidth: "50px",
                                background: "#197FC5",
                                color: "white",
                                width: "90%",
                            }}
                            onClick={handleOpen2}
                        >
                            coupon
                        </PrimaryButton>


                        <div className="container">
                                <div className="card">
                                    <div className="main">
                                        <div className="co-img">
                                            <img
                                                src="https://i.pinimg.com/originals/c7/84/67/c78467db9ff497393cb548a48f02d451.png"
                                                alt=""
                                            />
                                        </div>
                                        <div className="vertical"></div>
                                        <div className="content">
                                            <h2>Mcdonalds</h2>
                                            <h1>10% <span>Coupon</span></h1>
                                            <p>Valid till 30 April 2021</p>
                                        </div>
                                    </div>
                                    <div className="copy-button">
                                        <input id="copyvalue" type="text" readonly value="GOFREE" />
                                        <button onclick="copyIt()" className="copybtn">COPY</button>
                                    </div>
                                </div>
                            </div>


                        <Paper sx={{
                            position: 'fixed', bottom: 0, left: 0, right: 0
                        }} elevation={1}>
                            <BottomNavigation
                                style={{
                                    alignItems: 'center', height: '90px'
                                }}
                            >
                                <BackButton
                                    variant="outlined"
                                    style={{
                                        minWidth: "90px",
                                        margin: "0px 15px",
                                    }}
                                    onClick={handleClose}
                                >
                                    ย้อนกลับ
                                </BackButton>
                            </BottomNavigation>
                        </Paper>
                    </div>
                </React.Fragment>
            </div> */}

      {/* <div>
                <Modal
                    open={open2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description">
                    <React.Fragment>
                        <SpectraNearYouCoupon func={pull_data} />
                    </React.Fragment>
                </Modal>
            </div> */}
    </React.Fragment>
  );
}
