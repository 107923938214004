import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, styled} from '@mui/material';
import Close from '../../assets/images/icon/close-gray.svg';
// import DeliveryUnselected from '../../assets/images/redeem-gift/delivery-unselected.png';
// import DeliverySelected from '../../assets/images/redeem-gift/delivery-selected.png';
// import SelfUnselected from '../../assets/images/redeem-gift/self-unselected.png';
// import SelfSelected from '../../assets/images/redeem-gift/self-selected.png';
import SecondaryButtonSmall from '../button/SecondaryButtonSmall';
import PrimaryButtonSmall from '../button/PrimaryButtonSmall';
import React, {useState} from 'react';
import PrimaryButton from '../button/PrimaryButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const HOST = window.location.origin
const URL_PATH = HOST + "/assets/img/ci/redeem-gift-member"

const DeliveryUnselected = URL_PATH + "/img-delivery-unselected.png"
const DeliverySelected = URL_PATH + "/img-delivery-selected.png"
const SelfUnselected = URL_PATH + "/img-self-unselected.png"
const SelfSelected = URL_PATH + "/img-self-selected.png"

const CustomDialog = styled(Dialog)({
  '& .MuiTypography-root': {
    fontFamily: 'inherit',
  },
  '& .MuiDialog-container': {
    marginLeft: 25,
    marginRight: 25,
  },
  '& .MuiDialogTitle-root': {
    fontFamily: 'inherit',
    paddingRight: 15,
    paddingLeft: 0,
    paddingBottom: 0
  },
  '& .MuiPaper-root': {
    fontFamily: 'inherit',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    maxHeight: '100%',
    borderRadius: 5
  },
  '& .MuiDialogContent-root': {
    fontFamily: 'inherit',
    marginLeft: 1,
    marginRight: 1,
    padding: 0,
    '& .MuiTypography-root': {
      color: 'black',
      // fontSize: 'calc(100vh * 0.0159)'
    },
  },
  '& .MuiDialogActions-root': {
    display: 'block',
    padding: '20px 5%',
  },
})
export default function SelectChannelDialog({title, open, toggle, onOk, onCancel, clinicList = []}) {
  const [channel, setChannel] = useState(0);
  const [shopId, setShopId] = useState("");

  React.useEffect(() => {

  }, []);

  function onClickChannel(no) {
    setChannel(no);
  }

  return (
    <>
      <CustomDialog
        fullScreen={false}
        open={open}
        onClose={toggle(false)}
        maxWidth="xl"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={'text-center'}
      >
        <DialogTitle id="scroll-dialog-title">
          <div className="flex flex-row-reverse">
            <img src={Close} alt="close" onClick={toggle(false)} className="h-6"/>
          </div>
          <div className="text-[28px] text-[#00B960] pl-4 mt-[-10px]">
            {title}
          </div>
        </DialogTitle>
        <DialogContent dividers={false}>
          <div className={'px-[20px]'}>
            <div className={'grid grid-cols-2 gap-5 text-[20px] font-[600]'}>
              <div onClick={() => onClickChannel(1)}>
                <img src={channel === 1 ? DeliverySelected : DeliveryUnselected} alt={'Delivery'}
                     className={'mx-auto pb-2'}/>
                <div className={channel === 1 ? 'bText-primary-d' : 'bText-gray'}>จัดส่งของรางวัล</div>
              </div>
              <div onClick={() => onClickChannel(2)}>
                <img src={channel === 2 ? SelfSelected : SelfUnselected} alt={'Self'} className={'mx-auto pb-2'}/>
                <div className={channel === 2 ? 'bText-primary-d' : 'bText-gray'}>รับด้วยตนเอง</div>
              </div>
            </div>
            {channel === 1 &&
              <div className={'text-red text-[16px] leading-5 pt-5'}>
                กรุณาตรวจสอบ ที่อยู่จัดส่ง ให้ถูกต้อง<br/>ในเมนู สมุดพกน้องแมวและข้อมูลผู้ใช้ ก่อนกดยืนยัน
              </div>
            }
            {channel === 2 &&
              <>
                <div className={'text-left text-[20px] bText-gray py-5'}>
                  รับได้ที่โรงพยาบาลสัตว์ คลินิก หรือร้านเพ็ทช็อป<br/>ที่เคยใช้บริการเท่านั้น :
                </div>
                <div>
                  <Select
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    fullWidth
                    name="shopId"
                    value={shopId || ""}
                    onChange={(event) =>
                      console.log('event', event)
                    }
                    variant="standard"
                  >
                    <MenuItem disabled  value="">
                      <span className={'bText-gray'}>เลือกโรงพยาบาล คลินิก หรือร้านเพ็ทช็อป</span>
                    </MenuItem>
                    {
                      clinicList.map((item, i) => (
                        <MenuItem value={item.id} key={i}>
                          {item.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </div>
                <div className={'text-red text-[16px] leading-5 pt-5'}>
                  กรุณากดยืนยันเมื่ออยู่ที่หน้าร้านค้าแล้วเท่านั้น<br/>หากกด 'ยืนยัน' ไม่สามารถแก้ไขเปลี่ยนแปลงข้อมูลได้
                </div>
              </>
            }
          </div>
        </DialogContent>
        <DialogActions>
          <div className="text-center">
            <PrimaryButton variant="contained" onClick={() => onOk({channel,shopId})}>
              <div className={'px-5'}>ยืนยัน</div>
            </PrimaryButton>
          </div>
        </DialogActions>
      </CustomDialog>
    </>
  )
}