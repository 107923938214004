import React, { useEffect, useState } from "react";
import { SearchId } from "../../services/SearchId";
import { useLiff } from "react-liff";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

export default function InviteFriendSuccess() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const [stateForm, setStateForm] = useState({ point: 0, createdOn: null });

  useEffect(() => {
    getSearchByLineId();
    // eslint-disable-next-line
  }, []);

  const getSearchByLineId = async () => {
    const memberData = await getSearchId();
    if (!memberData.result.memberId) {
      navigate("/register");
    } else {
      if (state.point === 0) {
        navigate("/dogbook");
      } else {
        console.log("log 1");
        setStateForm({ point: state.point, createdOn: state.createdOn });
      }
    }
  };

  const getSearchId = async () => {
    let _return = {
      status: false,
      result: null,
    };
    try {
      const { data } = await SearchId({ params: { lineId: lineId || null } });
      if (data.resultCode === 20000) {
        const resData = data.body;
        console.log("resData >>", resData);
        _return.status = true;
        _return.result = resData;
      }
    } catch (error) {
      console.log(error);
    }
    return _return;
  };

  return (
    <div id="inviteFriendSuccessPage">
      <div className="content bgGraphics">
        <div className="pInviteSuccessMain">
          <div className="bInviteSuccessContent">
            <div className="bHead">
              <i className="bIconCheck" data-icon="check"></i>
              <h1 className="tIconSuccess">แลก NexCoin เรียบร้อยแล้ว</h1>
            </div>
            <div className="bScore">
              <p className="tLabel">NexCoin ที่จะได้รับ</p>
              <p className="tPoint">{stateForm.point}</p>
              <p className="tPointUnit">คะแนน</p>
            </div>
            <div className="bInviteSuccessDesc">
              <h2>รายละเอียด</h2>
              <div className="tDesc">
                <div>
                  <p>
                    <span>วันที่/เวลาแลกของรางวัล:</span>
                  </p>
                  <p>
                    {moment(stateForm.createdOn).format("DD/MM/YYYY hh:mm:ss")}
                  </p>
                </div>
                <div>
                  <p>
                    <span>NexCoin ที่ได้รับ:</span>
                  </p>
                  <p>{stateForm.point} คะแนน</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bInviteConfirmFooter">
            <button
              className="btn _outline transparent"
              onClick={() => navigate("/dogbook")}
            >
              เสร็จสิ้น
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
