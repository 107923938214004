import {createSlice} from '@reduxjs/toolkit'
import React from 'react';


const defaultData = {
  shopNo: '',
  isOwner: 'yes',
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  openCancelPopup: false,
  openConfirmShopPopup: false,
  clinicPetShopData: {},
  address: '-',
  shippingTambol: null,
  shippingAmphoe: null,
  shippingProvince: null,
  shippingZipcode: null,
  shippingAddressId: null,
  popupDesc: 'กรอกรหัสร้านค้าไม่ถูกต้อง\nกรุณาลองใหม่อีกครั้ง',
  isDeliveryAddress: false,
  deAddressId: null,
  deAddressDetail: '',
  selectedAddress: {},
  isPetShop: false,
  recommenderId: null,
  favoriteProductId: '',
  isHaveCat: 'no'
};

export const RegisterPetShopSlice = createSlice({
  name: 'RegisterPetShop',
  initialState: defaultData,
  reducers: {
    setRegisterPetShop: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(key, value);
        state[key] = value;
      }
    },
    resetRegisterPetShop: () => defaultData
  },
})

// Action creators are generated for each case reducer function
export const {
  setRegisterPetShop,
  resetRegisterPetShop
} = RegisterPetShopSlice.actions

export default RegisterPetShopSlice.reducer