import helper from "../functions/helper";

const IconExport = {
  edit: helper.PUBLICPATH("/assets/img/ci/icon/edit.svg"),
  editWhite: helper.PUBLICPATH("/assets/img/ci/icon/edit-white.svg"),
  book: helper.PUBLICPATH("/assets/img/ci/icon/book.svg"),
  alarm: helper.PUBLICPATH("/assets/img/ci/icon/alarm.svg"),
  petFace: helper.PUBLICPATH("/assets/img/ci/icon/petFace.svg"),
  add: helper.PUBLICPATH("/assets/img/ci/icon/add.svg")
  
}

export default IconExport;