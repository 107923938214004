
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

import TopLogo from '../../components/layout/TopLogo';
import scanQR from "../../assets/images/scanQR.png";
import mobile from '../../assets/images/mobile.png';
import { useSelector } from 'react-redux';
import BackButton from '../../components/button/BackButton';
import { SearchHistoryCoupons } from "../../services/MyPoint";
import dayjs from 'dayjs';
import { SearchId } from "../../services/SearchId";
import { useLiff } from "react-liff";
import { SearchPetOwnerProfile } from "../../services/Dogbook";

const initialHistoryData = {
    scan: [
        // {
        //     "id": 1,
        //     "spCode": "SP1210D921234",
        //     "qrCode": "HW1210D921234",
        //     "clinicCode": "213453A564",
        //     "clinicName": "โรงพยาบาลเจ้าพระยา",
        //     "date": "12/12/2023",
        //     "time": "13:21:10 น.",
        //     "nexCoin": 250
        // },
        // {
        //     "id": 2,
        //     "spCode": "SP1210D921234",
        //     "qrCode": "HW1210D921234",
        //     "clinicCode": "213453A564",
        //     "clinicName": "โรงพยาบาลเจ้าพระยา",
        //     "date": "12/12/2023",
        //     "time": "13:21:10 น.",
        //     "nexCoin": 250
        // }
    ],
    event: [
        // {
        //     id: 1,
        //     createdDate: "21-01-2023",
        //     event: [
        //         { eventName: "Welcome Back" , points: 100 },
        //         { eventName: "Welcome Back" , points: 100 },
        //         { eventName: "Welcome Back" , points: 100 },
        //         { eventName: "Welcome Back" , points: 100 },
        //         { eventName: "Welcome Back" , points: 100 },
        //     ]
        // },
        // {
        //     id: 2,
        //     createdDate: "19-01-2023",
        //     event: [
        //         { eventName: "แต้มสำหรับการบันทึกสุขภาพน้องแมว" , points: 50 },
        //         { eventName: "แต้มสำหรับการบันทึกสุขภาพน้องแมว" , points: 50 },
        //     ]
        // }
    ]
}
const initialLoadMore = {
    scan: {
        limit: 3,
        offset: 0,
    },
    event: {
        limit: 3,
        offset: 0,
    }
}

const initialHistoryDataCount = {
    scan: 0,
    event: 0
}

const initCurrentDate = dayjs().format('YYYY-MM-DD');

export default function MyPointHistory() {
    const store = useSelector((state) => state.myPoint)
    const navigate = useNavigate();
    const [page, setPage] = React.useState("scan");
    const [historyData, setHistoryData] = React.useState(initialHistoryData);
    const [loadMore, setLoadmore] = React.useState(initialLoadMore);
    const [historyDataCount, setHistoryDataCount] = React.useState(initialHistoryDataCount);

    // คะแนนหมดอายุ
    const [currentDate, setCurrentDate] = React.useState(initCurrentDate);
    const [historyExpire, setHistoryExpire] = React.useState([]);
    const [historyExpireCount, setHistoryExpireCount] = React.useState(0);
    const [expLoadMore, setExpLoadMore] = React.useState({ limit: 3, offset: 0 });

    let [ownerProfile, setOwnerProfile] = useState(null);
    let [currentPoint, setCurrentPoint] = useState(0);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isWaiting, setIsWaiting] = React.useState(true);
    const [memberId, setMemberId] = React.useState(null);
    const { liff } = useLiff();
    const lineId = liff.getDecodedIDToken().sub;

    useEffect(() => {
        getSearchId();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (memberId) {
            if (loadMore.scan.limit > 0) {
                getSearchHistoryCoupon(memberId, true)
            }
        }
    }, [loadMore])

    async function getSearchId() {
        return await SearchId({
            params: { lineId: lineId || null },
        })
            .then(async (response) => {
                if (!response.data.body.memberId) {
                    navigate("/register");
                } else {
                    setMemberId(response.data.body.memberId)
                    getOwnerProfile(response.data.body.memberId)
                    // alert('A' + JSON.stringify({memberId, ...loadMore.scan}))
                    const res1 = await getSearchHistoryCoupon(response.data.body.memberId)
                    let tmp = { ...historyData }
                    tmp.scan = res1.tmp.scan
                    let tmp2 = { ...historyDataCount }
                    tmp2.scan = res1.tmp2.scan
                    setHistoryData(tmp)
                    setHistoryDataCount(tmp2)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function getOwnerProfile(member_id) {
        return await SearchPetOwnerProfile({
            params: { lineId: lineId || null },
            member_id: member_id,
        })
            .then(async (response) => {
                setOwnerProfile(response.data.body);
                setCurrentPoint(response.data?.body?.currentPoint)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function getSearchHistoryCoupon(_memberId, isRender = false) {
        const { offset, limit } = loadMore.scan
        return new Promise((resolve, reject) => {
            SearchHistoryCoupons({
                params: { memberId: _memberId ?? memberId, offset, limit },
            })
                .then(async (response) => {
                    let tmp = null
                    let tmp2 = null
                    if (response.data.resultCode === 20000) {
                        console.log('response.data.body getSearchHistoryCoupon >>', response.data.body)
                        let resData = response.data.body
                        resData = resData.map(item => {
                            item.isActive = false
                            return item
                        })
                        tmp = { ...historyData }
                        tmp.scan = [...tmp.scan, ...resData]

                        tmp2 = { ...historyDataCount }
                        tmp2.scan = response.data.rowCount

                    } else {
                        tmp = { ...historyData }
                        tmp.scan = []
                        tmp2 = { ...historyDataCount }
                    }

                    if (isRender) {
                        setHistoryData(tmp)
                        setHistoryDataCount(tmp2)
                    } else {
                        resolve({ tmp, tmp2 })
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        })
    }

    function onLoadMore(name) {
        console.log('name >>', name)
        console.log('loadMore A>>', loadMore[name].limit)
        console.log('loadMore B>>', historyData[name].length)
        if (historyData[name].length >= loadMore[name].limit) {
            setIsLoading(true)
            let tmp = { ...loadMore }
            tmp[name].offset = tmp[name].limit
            tmp[name].limit = tmp[name].limit + 1

            console.log('loadMore New >>', tmp[name])
            setLoadmore(tmp)
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 2500)
    }

    function ItemListHistory() {
        const onActive = (i) => {
            let tmp = { ...historyData }
            tmp.scan = [...historyData?.scan]
            tmp.scan[i].isActive = true
            setHistoryData(tmp)
        }
        return (
            <div className="overflow-y-auto" style={{ height: "calc(100vh - 330px)", }}>
                {
                    Array.isArray(historyData.scan) && historyData.scan.length
                        ? (
                            historyData.scan.map((item, i, arr) => (
                                <div id="single-card" key={item.id} >
                                    <div className="">
                                        <div id="section1" className="py-[6px]">
                                            <div className="flex justify-between items-center px-[16px]">
                                                <p className="text-lightBlue text-[20px] leading-[120%]">{item.code}</p>
                                                <p className="bText-gray text-[16px] leading-[120%]">{dayjs(item.createdDate).format('DD/MM/YYYY HH:mm:ss')} น.</p>
                                            </div>
                                        </div>
                                        <hr className="border-lightGray" />
                                        <div className="px-[16px] pb-[16px]">
                                            <div id="section2" className="py-[12px]">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={2}>
                                                        <div className="bg-lightGray rounded-[4px] w-12 h-12 flex justify-center items-center pt-[5px]">
                                                            <img src={mobile} alt="" width="35px" />
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={10} className="text-left flex-1">
                                                        <p className="text-black text-[20px] leading-[24px] pl-[8px]">{item.shopName}</p>
                                                        <p className="bText-gray text-[16px] leading-[17.6px] pl-[8px]">{item.shopNo}</p>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <hr className="border-lightGray" />
                                            <div id="section3" className="pt-[20px] pb-[6px] text-[16px] leading-[0px]">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={5}>
                                                        <p className="bText-gray">รหัสอ้างอิง</p>
                                                    </Grid>
                                                    <Grid item xs={7} className="text-end">
                                                        <p className="text-black">{item.code}</p>
                                                    </Grid>
                                                </Grid>
                                            </div>

                                        </div>
                                    </div>
                                    <div className={`historyList ${arr.length === i + 1}`}></div>
                                </div>
                            ))
                        )
                        :
                        <div className="flex justify-center items-center min-h-[147px] h-[60%]">
                            <div className="flex flex-col justify-center text-center">
                                <img className="mb-[23px] mx-auto" src={scanQR} alt="location" style={{ display: 'inline', width: 52 }} />
                                <p className="text-darkGray text-[24px] leading-[120%]">ยังไม่มีประวัติการใช้คูปอง</p>
                                <p className="bText-gray text-[16px] leading-[100%]">กรุณากลับมาเช็กข้อมูลอีกครั้งในโอกาสหน้า</p>
                                <p className="bText-gray text-[16px] leading-[100%]">หรือติดตามข่าวสารจาก Line @NextComboCat</p>
                            </div>
                        </div>
                }

                {/* <hr className="border-b-1px border-lightGray w-full" /> */}
                {/* <p className='py-[4px] text-center bText-primary-d cursor-pointer' onClick={() => onLoadMore('scan')}>โหลดเพิ่ม</p> */}
            </div>
        )
    }



    const fetchItems = () => {
        if (memberId && historyDataCount.event) {
            console.log('-----------------------------')
            console.log('fetchItems!')
            onLoadMore('event')
            console.log('-----------------------------')
        }
    }

    // const isLoading = true;

    const loader = (
        <div key="loader" className="loader text-center text-[20px] bText-gray">
            Loading ...
        </div>
    );

    return (
        <div id="my-point-history">
            <TopLogo />
            <div id="gradient" className="bgPrimaryGD px-[13px] overflow-hidden" style={{ height: "calc(100vh - 60px)" }}>
                <div id="viewport" className="">

                    <div id="label" className="pt-[36px]">
                        <p className="text-white font-light text-[28px] leading-5">ประวัติการใช้คูปอง</p>
                    </div>

                    <hr className="border-white opacity-25 my-[20px]" />

                    {/* <div id="collected-point" className="text-white font-normal text-[20px] leading-5">
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <p>NexCoin สะสมรวม</p>
                            </Grid>
                            <Grid item xs={7} className="text-end">
                                <span><p className="inline-block font-semibold text-[28px]">{helper.numberWithCommas(currentPoint)}</p> คะแนน</span>
                            </Grid>
                        </Grid>

                        <p className="font-light text-[16px] leading-5">คะแนนของท่านจะหมดอายุในวันที่ 31/12/{dayjs().year()}</p>
                    </div> */}

                    <div id="item-list" className="bg-white rounded-[4px] mt-[24px]">
                        <div id="tab-select">
                            <div className="flex items-center">
                                {/* <p
                                    onClick={() => setPage("scan")}
                                    className={
                                        `w-full text-[18px] bText-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                                        (page === "scan" && "!bText-primary-d !border-b-[4px] !border-blue")
                                    }
                                >
                                    จากการสแกน
                                </p> */}
                                {/* <p
                                    onClick={() => setPage("event")}
                                    className={
                                        `w-full text-[18px] bText-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                                        (page === "event" &&
                                            "!bText-primary-d !border-b-[4px] !border-blue")
                                    }
                                >
                                    จากกิจกรรม
                                </p> */}
                                <p
                                    // onClick={() => setPage("expire")}
                                    className={
                                        `w-full text-[18px] text-center py-[9px] border-b-[1px] border-lightGray !bText-primary-d !border-b-[4px] !border-blue`
                                    }
                                    style={{color:'#00693E'}}
                                >
                                    ประวัติการใช้คูปอง
                                </p>
                            </div>
                        </div>

                        <div id="item-card">
                            <>
                                {ItemListHistory()}
                            </>
                        </div>

                    </div>

                </div>
            </div>
            <div
                className="flex flex-col justify-center bg-white px-[20px] pt-[16px] pb-[24px] fixed bottom-0 left-0 right-0">
                <BackButton
                    onClick={() => navigate(-1)}>
                    <p className="text-[24px] text-center">
                        ย้อนกลับ
                    </p>
                </BackButton>
            </div>
        </div>
    )
}