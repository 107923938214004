import React, {useCallback, useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import redeemHistoryLabel from "../../assets/images/redeem-gift/redeem-history-label.svg";
import boxEmpty from "../../assets/images/icon/box-empty.svg";
import TopBanner from "../../components/layout/TopBanner";
import {SearchId} from "../../services/SearchId";
import {useLiff} from "react-liff";
import TopLogo from '../../components/layout/TopLogo';
import dayjs from 'dayjs';
import {SearchFreebies, SearchFreebiesHistory} from '../../services/RedeemGift';
import ShopCard from '../../components/card/ShopCard';
import {FreeMode} from "swiper";
import InfiniteScroll from 'react-infinite-scroller';
import {useNavigate} from 'react-router-dom';

export default function RedeemGift() {
  const navigate = useNavigate();
  let [page, setPage] = useState(1);

  let [freebiesHit, setFreebiesHit] = useState([]);
  let [freebiesNew, setFreebiesNew] = useState([]);
  let [freebies, setFreebies] = useState([]);
  let [freebiesHistory, setFreebiesHistory] = useState([]);
  let [clinicPetShop, setClinicPetShop] = useState({});

  const lazyLoadItem = 4;
  const [nextPage, setNextPage] = useState(null);
  const [items, setItems] = useState([]);
  const [fetching, setFetching] = useState(false);

  const {liff} = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  useEffect(() => {
    (async () => {
      try {
        const {clinicPetShopId} = await getSearchId();
        if (!clinicPetShopId) throw 'clinicPetShopId not found';
        const {clinicPetShop, freebies, freebiesHit, freebiesNew} = await getSearchFreebies(clinicPetShopId);
        setClinicPetShop(clinicPetShop);
        setFreebiesHit(freebiesHit);
        setFreebiesNew(freebiesNew);
        setFreebies(freebies);
        setNextPage('0');
        const freebiesHistory = await getSearchFreebiesHistory(clinicPetShopId)
        setFreebiesHistory(freebiesHistory)
      } catch (error) {
        console.log('fetchData error', error)
      }
    })()
  }, []);

  async function getSearchId() {
    const {data} = await SearchId({params: {lineId}})
    if (+data['resultCode'] === 20000) {
      return data.body || {};
    } else {
      return {};
    }
  }

  async function getSearchFreebies(clinicPetShopId) {
    const {data} = await SearchFreebies({params: {type: 'EMPLOYEE', clinicPetShopId}})
    if (+data['resultCode'] === 20000) {
      return data.body || {};
    } else {
      return {};
    }
  }

  async function getSearchFreebiesHistory(clinicPetShopId) {
    const {data} = await SearchFreebiesHistory({params: {clinicPetShopId}})
    if (+data['resultCode'] === 20000) {
      return data.body || {};
    } else {
      return {};
    }
  }

  function Item(data) {
    return (
      <div onClick={() => {
        navigate(`/redeem-gift-detail/${data['freebiesId']}/${clinicPetShop['shopId']}`);
      }}>
        <img src={data['urlPicture']} alt="image" className="mb-3"/>
        <div className="text-[24px] text-darkGray font-[600] leading-[100%]">
          {data['name']}
        </div>
        <div className="text-[20px] bText-primary-d font-[600] leading-[100%]">
          {data['points']?.toLocaleString()} คะแนน
        </div>
      </div>
    );
  }

  function ItemHistory(data) {
    const freebies = data['freebies'];
    return (
      <div className="relative">
        <img src={redeemHistoryLabel} alt="redeem-history-label" className="absolute right-0"/>
        <img src={freebies.urlPicture} alt="image" className="mb-3"/>
        <div className="text-[24px] text-darkGray font-[600] leading-[100%]">
          {freebies.name}
        </div>
        <div className="text-[20px] bText-primary-d font-[600] leading-[100%]">
          {freebies.points?.toLocaleString()} คะแนน
        </div>
        <div className="bText-gray text-[12px]">
          แลกเมื่อวันที่ {dayjs(data['createdDate']).format('DD/MM/YYYY')}
        </div>
      </div>
    );
  }

  useEffect(() => {
    console.log('items.length', items.length);
    console.log('freebies.length', freebies.length);
    if (items.length < freebies.length) {
      setNextPage(items.length);
    } else {
      setNextPage(null);
    }
    setFetching(false);
  }, [items]);

  const fetchItems = useCallback(() => {
      if (items.length < freebies.length) {
        console.log('fetching', fetching);
        if (fetching) {
          return;
        }
        setFetching(true);
        setTimeout(() => {
          const sliceItems = freebies.slice(+nextPage, +nextPage + lazyLoadItem);
          setItems([...items, ...sliceItems]);
        }, 1500)
      } else {
        setNextPage(null);
      }
    },
    [fetching, freebies, items, nextPage]
  );

  const hasMoreItems = !!nextPage;

  const loader = (
    <div key="loader" className="loader text-center text-[20px] bText-gray">
      Loading ...
    </div>
  );

  return (
    <>
      <div id="redeem-gift-shop-page">
        <TopLogo/>
        <TopBanner type="redeemGift"/>
        <div id="redeem-gift-shop" className="bgPrimaryGD mt-[-20px] px-[20px] pb-2">
          <div className="relative">
            <ShopCard data={clinicPetShop}/>
            <div className="py-[16px] text-white">
              <div className="flex justify-between text-[20px]">
                <div>NexCoin สะสมรวม</div>
                <div>
                  <span className="text-[24px] font-[600]">{clinicPetShop['currentPoint']?.toLocaleString()}</span>
                  &nbsp;คะแนน
                </div>
              </div>
              <div className="mt-[-10px] mb-5">
                {/* คะแนนของท่านจะหมดอายุในวันที่ 31/12/{dayjs().year()} */}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-[5px] mx-4 mt-[-30px] mb-10">
          <div className="flex items-center">
            <p
              onClick={() => setPage(1)}
              className={
                `w-full text-[20px] bText-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                (page === 1 && "!bText-primary-d !border-b-[4px] !border-blue")
              }
            >
              แลกของรางวัล
            </p>
            <p
              onClick={() => setPage(2)}
              className={
                `w-full text-[20px] bText-gray text-center py-[9px] border-b-[1px] border-lightGray ` +
                (page === 2 &&
                  "!bText-primary-d !border-b-[4px] !border-blue")
              }
            >
              ประวัติการแลกของรางวัล
            </p>
          </div>
          {page === 1 && (
            <>
              {freebiesHit.length > 0 &&
                <div className="px-[16px]">
                  <p className="text-[28px] text-lightBlue">รายการยอดฮิต</p>
                  <Swiper
                    slidesPerView={2}
                    spaceBetween={10}
                    freeMode={true}
                    modules={[FreeMode]}
                  >
                    {freebiesHit.map(obj => (
                      <SwiperSlide>
                        {Item(obj)}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              }
              {freebiesNew.length > 0 &&
                <div className="px-[16px]">
                  <p className="text-[28px] text-lightBlue">รายการมาใหม่</p>
                  <Swiper
                    slidesPerView={2}
                    spaceBetween={10}
                    freeMode={true}
                    modules={[FreeMode]}
                  >
                    {freebiesNew.map(obj => (
                      <SwiperSlide>
                        {Item(obj)}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              }
              <div className="px-[16px] pb-10">
                <hr className="border-b-1px border-lightGray w-full"/>
                <p className="text-[28px] text-lightBlue pt-2">รายการทั้งหมด</p>
                {freebies.length > 0 &&
                  <InfiniteScroll
                    loadMore={fetchItems}
                    hasMore={hasMoreItems}
                    loader={loader}
                  >
                    <div className="grid grid-cols-2 gap-2 gap-y-12 pb-10">
                      {items.map(obj => (
                        <div>
                          {Item(obj)}
                        </div>
                      ))}
                    </div>
                  </InfiniteScroll>
                }
                {freebies.length === 0 &&
                  <div className="text-center">
                    <img src={boxEmpty} alt="data not found" className="inline-block mb-3"/>
                    <div className="text-darkGray text-[24px] font-bold">
                      ยังไม่มีของรางวัลในขณะนี้
                    </div>
                    <div className="bText-gray text-[16px] leading-[100%]">
                      กรุณากลับมาเช็กของรางวัลของท่านอีกครั้งในโอกาสหน้า<br/>หรือติดตามข่าวสารจาก Line @NextComboCat
                    </div>
                  </div>
                }
              </div>
            </>
          )}
          {page === 2 && (
            <div className="p-[16px]">
              <p className="text-[24px] text-lightBlue">ประวัติการแลกของรางวัล</p>
              {freebiesHistory.length > 0 &&
                <div className="grid grid-cols-2 gap-2 gap-y-12 pb-20">
                  {freebiesHistory.map(obj => (
                    <div>
                      {ItemHistory(obj)}
                    </div>
                  ))}
                </div>
              }
              {freebiesHistory.length === 0 &&
                <div className="text-center">
                  <img src={boxEmpty} alt="data not found" className="inline-block mb-3"/>
                  <div className="text-darkGray text-[24px] font-bold">
                    ยังไม่มีประวัติการแลกของรางวัล
                  </div>
                  <div className="bText-gray text-[16px]">
                    คุณสามารถแลกของรางวัลได้ เมื่อมี NexCoin สะสมที่เพียงพอ
                  </div>
                </div>
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
}
