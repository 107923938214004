import { get, post } from "./Api";

export function SearchRegister({ params }) {
  return get({ url: "/searchRegister", params });
}

export function CreatePetOwnerRegister({ data }) {
  return post({ url: "/createPetOwnerRegister", data });
}

export function CreateOwnerRegister({ data }) {
  return post({ url: "/createOwnerRegister", data });
}

export function SearchClinicPetShop({ params }) {
  return get({ url: "/searchClinicPetShop", params });
}

export function searchRecommendIdByMember({ params }) {
  return get({ url: "/searchRecommendId", params });
}
