
import React from "react";
import Grid from '@mui/material/Grid';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import checkmark from "../../assets/images/checkmark.png";
import TopLogo from "../../components/layout/TopLogo";

export default function MyPointSuccess(props) {
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const { title, points, listDetail = [] } = props
    const { open, toPage } = props
    console.log('props >>', props)
    function DetailInfo(title, data) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    {title}:
                </Grid>
                {data ? (
                    <Grid item xs={9} className="text-end">
                        {data}
                    </Grid>
                ) : (
                    <Grid item xs={9} className="text-end">-</Grid>
                )}
            </Grid>
        );
    }
    const isOpen = open ?? true
    if (isOpen) {
        return (
            <div id="PageSuccess">
                <TopLogo />
                <div className="bGetPointSuccess bgPrimaryGD">
                    <div className="bTop">
                        {/* <img className="mx-auto" src={checkmark} alt="" width="52px" height="52px" /> */}
                        <i className="bCheckPoint"></i>
                        <p className="text-center text-white text-[36px]">
                            {title}
                        </p>
                        <div className="bScore">
                            <p className="tLabel">NexCoin ที่จะได้รับ</p>
                            <p className="tPoint">{points} คะแนน</p>
                            {/* <p className="tPointUnit">คะแนน</p> */}
                        </div>

                        <div className="bScoreDesc">
                            <div className="tTitle">รายละเอียด</div>
                            <div className="bDescMain">
                                {/* {listDetail.map(item => DetailInfo(item.name, item.value))} */}
                                {
                                    listDetail.map(item => {
                                        return  (
                                            <div className="bDes">
                                                <div className="bLeft">{item.name}:</div>
                                                <div className="bRight">{item?.value ?? '-'}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="bBottom">
                        <div className="bDesc">
                            <p>*คุณสามารถร่วมกิจกรรมสแกนรับพ้อยท์ได้เดือนละครั้งเท่านั้น</p>
                        </div>
                        <button onClick={() => { navigate(toPage)}} className="btn transparentL w-full">
                            เสร็จสิ้น
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    return <></>

}