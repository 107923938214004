import {get, post} from './Api';

export function SearchFreebies({params}) {
  return get({url: '/searchFreebies', params});
}
export function SearchFreebiesById({params}) {
  return get({url: '/searchFreebiesById', params});
}

export function SearchFreebiesHistory({params}) {
  return get({url: '/searchFreebiesHistory', params});
}
export function CreateUsePoint({data}) {
  return post({url: '/createUsePoint', data});
}