import {createSlice} from '@reduxjs/toolkit'
import RegisterMemberUnselect from '../../assets/images/register/register-member-unselect.png';
import RegisterShopUnselect from '../../assets/images/register/register-shop-unselect.png';

const defaultData = {
  active: 0,
  openCookieDrawer: false,
  openCookieDialog: false,
  imageList: [RegisterMemberUnselect, RegisterShopUnselect],
  textColorList: ['bText-gray', 'bText-gray']
}

export const RegisterMainSlice = createSlice({
  name: 'registerMain',
  initialState: defaultData,
  reducers: {
    setRegisterMain: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetRegisterMain: () => defaultData
  },
})

// Action creators are generated for each case reducer function
export const {
  setRegisterMain,
  resetRegisterMain
} = RegisterMainSlice.actions

export default RegisterMainSlice.reducer