import React from "react";
// {

//   {
//     'text':
//         'หากท่านมีข้อสงสัยใด ๆ เกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่านโดยบริษัทฯ หรือประสงค์จะขอข้อมูลเพิ่มเติม หรือใช้สิทธิใด ๆ ของท่านท่านสามารถติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data ProtectionOfficer) ของบริษัทฯ โดยตรงตามรายละเอียดดังต่อไปนี้',
//     'type': 'normal',
//   },
//   {
//     'text': 'เบอริงเกอร์ อินเกลไฮม์ ประเทศไทย',
//     'type': 'normal-bold',
//   },
//   {
//     'text': 'เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer)',
//     'type': 'normal-small',
//   },
//   {
//     'text': ['เบอร์ติดต่อ:', '+66 2 308-8522'],
//     'type': 'normal-tel',
//   },
//   {
//     'text': ['อีเมลล์:', 'zzLEGCompDPOThailand@boehringer-ingelheim.com'],
//     'type': 'normal-email',
//   },
//   {
//     'text': 'นอกจากนี้ ท่านยังสามารถติดต่อกับตัวแทนของบริษัทฯ ได้',
//     'type': 'normal',
//   },
export default function Policy() {
  return (
    <>
      <div className="ml-[-3%]">
        <div className="leading-[100%]">
          บริษัท เบอริงเกอร์ อินเกลไฮม์ แอนิมอล เฮลท์ (ประเทศไทย)
          จํากัด(“บริษัทฯ”) มีความจำเป็นต้องเก็บรวบรวม ใช้ เปิดเผย โอน
          และประมวลผลข้อมูลส่วนบุคคลของท่าน ทั้งที่เป็นข้อมูลส่วนบุคคลทั่วไป
          (“ข้อมูลส่วนบุคคลทั่วไป”) และข้อมูลส่วนบุคคลที่ละเอียดอ่อน
          (“ข้อมูลส่วนบุคคลที่ละเอียดอ่อน”) (รวมเรียกว่า “ข้อมูลส่วนบุคคล”)
          ดังต่อไปนี้
        </div>
        <div className="py-1"></div>
        <div className="text-[120%] text-[#00B960]">ข้อมูลส่วนบุคคลทั่วไป</div>
        <ul className="list-disc pl-5">
          <li>ชื่อและนามสกุล</li>
          <li>หมายเลขติดต่อ</li>
          <li>ที่อยู่อีเมลล์</li>
          <li>วันเดือนปีเกิด</li>
          <li>ที่อยู่</li>
          <li>ไอดีไลน์ (LINE ID)</li>
        </ul>
        <div className="py-1"></div>
        <div className="text-[120%] text-[#00B960]">
          วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล
        </div>
        <div className="py-1"></div>
        <div className="leading-[100%]">
          บริษัทฯ จะเก็บรวบรวม ใช้ เปิดเผย โอน
          และประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้
        </div>
        <div className="py-1"></div>
        <ul className="list-disc pl-5">
          <li>
            การก่อตั้ง พัฒนา จัดการ ดำรงไว้ เก็บรักษา
            หรือเข้าตกลงในเรื่องการสิ้นสุดซึ่งความสัมพันธ์ทางธุรกิจระหว่างท่านกับบริษัทฯ
          </li>
          <li>
            การดำเนินกิจกรรมทางการขาย การตลาด และ
            ทางการแพทย์ทั้งในรูปแบบพบปะและแบบดิจิตอล
            เมื่อกิจกรรมนั้นได้ผ่านการรับรองแล้ว
          </li>
          <li>
            การจัดเตรียม
            หรือบริหารจัดการด้านลอจิสติกส์สำหรับกิจกรรมที่เกี่ยวข้องกับบริษัทฯ
          </li>
          <li>การดำเนินการรับรองลูกค้า</li>
          <li>การบริหารจัดการบัญชีลูกค้า</li>
          <li>การตรวจสอบและรายงานการเฝ้าระวังความปลอดภัยจากการใช้ยา</li>
          <li>การรายงานตามกฎระเบียบภายในของบริษัทฯ</li>
          <li>
            การจัดเตรียม ดำเนินการ หรือบริหารจัดการโครงการการตลาด
            เช่นการโฆษณาสินค้าและผลิตภัณฑ์ (Advertising Product)
            การสร้างแบรนด์และทำให้เข้าใจรับรู้ในเรื่องโรคภัยต่าง ๆ (Building
            Brandand Disease Awareness
          </li>
          <li>การจัดหาและให้บริการข้อมูลสินค้า</li>
          <li>
            การจัดส่งข้อมูลให้แก่ท่านซึ่งเกี่ยวกับสินค้าของบริษัทฯ
            การศึกษาทางการแพทย์ สื่อและเอกสารส่งเสริมการขาย
            ข้อมูลที่เกี่ยวข้องกับการเฝ้าระวังความปลอดภัยจากการใช้ยา(pharmacovigilance-related
            information) โครงการพัฒนาข้อมูลวิชาชีพอย่างต่อเนื่อง การประชุม
            และข้อมูลทางการเงินและสัญญาที่บริษัทฯ เห็นว่าท่านอาจให้ความสนใจ
          </li>
        </ul>
        <div className="py-1"></div>
        <div className="text-[120%] text-[#00B960]">
          ความจำเป็นในการประมวลผลข้อมูลส่วนบุคคล
        </div>
        <div className="py-1"></div>
        <div className="leading-[100%]">
          บริษัทฯ
          จำเป็นต้องได้รับข้อมูลส่วนบุคคลของท่านเพื่อดำเนินการตามวัตถุประสงค์ดังกล่าวข้างต้น
        </div>
        <div className="py-1"></div>
        <div className="text-[120%] text-[#00B960]">
          ผลกระทบหากบริษัทฯ ไม่ได้รับข้อมูลส่วนบุคคลของท่าน
        </div>
        <div className="py-1"></div>
        <div className="leading-[100%]">
          บริษัทฯ
          จะไม่สามารถดำเนินการใดๆเพื่อให้บรรลุวัตถุประสงค์ตามที่กล่าวมาได้
          หากบริษัทไม่ได้รับข้อมูลส่วนบุคคลข้างต้น
        </div>
        <div className="py-1"></div>
        <div className="text-[120%] text-[#00B960]">
          การเปิดเผยหรือโอนข้อมูลส่วนบุคคล
        </div>
        <div className="py-1"></div>
        <div className="leading-[100%]">
          บริษัทฯจะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นความลับอย่างเคร่งครัดและจะไม่เปิดเผยหรือโอนข้อมูลดังกล่าวให้บุคคลภายนอก
          เว้นแต่บริษัทฯ
          จะได้รับความยินยอมโดยชัดแจ้งจากท่านหรือได้รับคำสั่งจากเจ้าหน้าที่ผู้มีอำนาจ
          เพื่อวัตถุประสงค์ดังกล่าวข้างต้น บริษัทฯ
          จะเปิดเผยและ/หรือโอนข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกซึ่งรวมถึง
        </div>
        <div className="py-1"></div>
        <ul className="list-disc pl-5">
          <li>บริษัทแม่ บริษัทในเครือ และบริษัทในกลุ่มของบริษัทฯ</li>
          <li>
            คู่ค้าหรือพันธมิตรทางธุรกิจ ผู้ให้บริการ
            ซัพพลายเออร์หรือผู้จำหน่ายสินค้าที่ได้รับอนุญาต
          </li>
          <li>ธนาคารพาณิชย์</li>
          <li>หน่วยงานหรือเจ้าหน้าที่ของรัฐบาล</li>
        </ul>
        <div className="py-1"></div>
        <div className="leading-[100%]">
          ในกรณีที่ต้องมีการโอนข้อมูลส่วนบุคคลออกนอกประเทศไทย
          บริษัทฯจะดำเนินการโดยเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ.
          2562 (“พรบ. คุ้มครองข้อมูลส่วนบุคคล”)
          ซึ่งรวมถึงแต่ไม่จำกัดเพียงกฎกระทรวง ประกาศ การตีความ หรือกฎระเบียบอื่น
          ๆ ที่ออกภายใต้พรบ.คุ้มครองข้อมูลส่วนบุคคล
        </div>
        <div className="py-1"></div>
        <div className="text-[120%] text-[#00B960]">
          ระยะเวลาการเก็บรักษาข้อมูล
        </div>
        <div className="py-1"></div>
        <div className="leading-[100%]">
          บริษัทฯ มีนโยบายในการเก็บรักษาข้อมูลส่วนบุคคลของท่าน
          ตามระยะเวลาที่กำหนดไว้ ทั้งในรูปแบบกระดาษหรืออิเล็กทรอนิกส์ บริษัทฯ
          จะไม่เก็บรักษาข้อมูลส่วนบุคคลของคุณเมื่อไม่จำเป็นต้องใช้เพื่อวัตถุประสงค์ทางธุรกิจหรือกฎหมายอีกต่อไป
          หากท่านถอนคำยินยอมของท่านที่ให้บริษัทฯขอลบบัญชีผู้ใช้
          หรือคัดค้านการประมวลผลข้อมูล บริษัทฯ จะลบข้อมูลของท่าน
          ที่ได้รวบรวมไว้ภายในระยะเวลาที่เหมาะสมบริษัทฯ
          ขอรับรองว่าข้อมูลส่วนบุคคลของท่านจะได้รับการเก็บรักษาไว้ในสถานที่ที่ปลอดภัย
          ซึ่งเฉพาะผู้ได้รับอนุญาตเท่านั้นที่จะสามารถเข้าถึงข้อมูลนั้นได้
          และบริษัทฯ
          จะทำลายข้อมูลส่วนบุคคลของท่านเป็นการถาวรทันทีที่สิ้นสุดระยะเวลาเก็บรักษาข้อมูล
          เว้นแต่กฎหมายจะกำหนดให้บริษัทฯต้องเก็บรักษาข้อมูลเป็นเวลานานกว่านั้น
          ซึ่งบริษัทฯ จะได้แจ้งให้ท่านทราบโดยทันที
        </div>
        <div className="py-1"></div>
        <div className="text-[120%] text-[#00B960]">
          สิทธิของท่านเกี่ยวกับข้อมูลส่วนบุคลภายใต้พรบ.คุ้มครองข้อมูลส่วนบุคคล
        </div>
        <div className="py-1"></div>
        <div className="leading-[100%]">
          ภายใต้การคุ้มครองข้อมูลส่วนบุคคลของท่านนั้น บริษัทฯ
          มีการดำเนินการอย่างเคร่งครัดและจริงจัง
          ภายใต้นโยบายและมาตรการในการคุ้มครองข้อมูลส่วนบุคคล
          เพื่อให้เป็นไปตามพรบ. คุ้มครองข้อมูลส่วนบุคคลและกฎหมายอื่น ๆ
          ที่เกี่ยวข้องท่านมีสิทธิทุกประการตามที่พรบ.
          คุ้มครองข้อมูลส่วนบุคคลได้กำหนดไว้เว้นแต่ในบางสถานการณ์ที่บริษัทฯ
          อาจจำเป็นต้องปฏิเสธคำขอใช้สิทธิของท่านและที่สำคัญคือท่านมีสิทธิที่จะถอนความยินยอมของท่านที่ให้บริษัทฯเก็บรวบรวม
          ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านได้ไม่ว่าจะในเวลาใดก็ตาม ทั้งนี้
          หากท่านมีความประสงค์ให้บริษัทฯ ทำการเปลี่ยนแปลงใด ๆ
          เกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่าน ท่านสามารถแจ้งให้บริษัทฯ
          ทราบได้ทุกเวลา
        </div>
        <div className="py-1"></div>
        <div className="leading-[100%]">
          ท่านสามารถศึกษารายละเอียดเพิ่มเกี่ยวกับสิทธิของท่านและขั้นตอนการขอถอนความยินยอมได้จากนโยบายความเป็นส่วนตัวของบริษัทฯที่ปรากฏบนเว็บไซต์:
          &nbsp;
          <a
            className="bText-primary-d"
            href="https://www.boehringerone.com/th/privacy"
          >
            https://www.boehringerone.com/th/privacy
          </a>
        </div>
        <div className="py-1"></div>
        <div className="text-[120%] text-[#00B960]">ติดต่อเรา</div>
        <div className="py-1"></div>
        <div className="leading-[100%]">
          หากท่านมีข้อสงสัยใด ๆ
          เกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่านโดยบริษัทฯ
          หรือประสงค์จะขอข้อมูลเพิ่มเติม หรือใช้สิทธิใด ๆ
          ของท่านท่านสามารถติดต่อเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data
          ProtectionOfficer) ของบริษัทฯ โดยตรงตามรายละเอียดดังต่อไปนี้
        </div>
        <div className="py-1"></div>
        <div className="font-[600]">เบอริงเกอร์ อินเกลไฮม์ ประเทศไทย</div>
        <div className="py-1"></div>
        <div className="text-[90%]">
          เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer)
        </div>
        <div>
          เบอร์ติดต่อ: &nbsp;
          <a className="bText-primary-d" href="tel:+66 2 308-8522">
            +66 2 308-8522
          </a>
        </div>
        <div>
          อีเมล: &nbsp;
          <a
            className="bText-primary-d"
            href="mailto:zzLEGCompDPOThailand@boehringer-ingelheim.com"
          >
            zzLEGCompDPOThailand@boehringer-ingelheim.com
          </a>
        </div>
        <div className="py-1"></div>
        <div className="leading-[100%]">
          นอกจากนี้ ท่านยังสามารถติดต่อกับตัวแทนของบริษัทฯ ได้
        </div>
        <div className="py-1"></div>
      </div>
    </>
  );
}
