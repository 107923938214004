const checkboxStyle = {
  "& .MuiSvgIcon-root": { fontSize: 28 },
  color: "#00693E",
  "&.Mui-checked": {
    color: "#00693E",
  },
  padding: "0px",
};

const radioStyle = {
  "& .MuiSvgIcon-root": { fontSize: 28 },
  color: "#E0E0E0",
  "&.Mui-checked": {
    color: "#00693E",
    //   backgroundColor: "#00693E"
  },
  padding: "0px",
};

const globalStyle = { checkboxStyle, radioStyle };

export default globalStyle;
