import { get, post } from "./Api";

export function SearchDogBreed({ params, keyword }) {
  return get({ url: `/searchDdlDogBreed?search=${keyword}`, params });
}

export function SearchFavoriteProduct({ params }) {
  return get({ url: `/searchDdlFavoriteProduct`, params });
}

export function SearchPetOwnerProfile({ params, member_id }) {
  return get({ url: `/searchPetOwnerProfile?memberId=${member_id}`, params });
}

export function SearchPetDetail({ params, member_id }) {
  return get({ url: `/searchPetDetail?memberId=${member_id}`, params });
}

export function SearchDdlPet({ params, member_id }) {
  return get({ url: `/searchDdlPet?memberId=${member_id}`, params });
}

export function SearchDdlFavHospital({ params, keyword }) {
  return get({ url: `/searchDdlFavoriteHospital?search=${keyword}`, params });
}

export function SearchDdlFavShop({ params, keyword }) {
  return get({ url: `/searchDdlFavoriteShop?search=${keyword}`, params });
}

export function SearchCountRecommendStamp({ member_id }) {
  return get({ url: `/countRecommendStamp/${member_id}` });
}

export function postCreatePet({ data }) {
  return post({ url: `/createPet`, data });
}

export function postUpdatePet({ data }) {
  return post({ url: `/updatePet`, data });
}

export function postUpdatePetOwnerProfile({ data }) {
  return post({ url: `/updatePetOwnerProfile`, data });
}

export function postDeletePet({ data }) {
  return post({ url: `/deletePet`, data });
}

export function postCreatRedeemRecommendPoint({ data }) {
  return post({ url: `/redeemRecommendPoint`, data });
}
