
import React from "react";
import Grid from '@mui/material/Grid';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";


import checkmark from "../../assets/images/checkmark.png";
import TopLogo from "../../components/layout/TopLogo";
import LogoDogDang from "../../assets/images/logo-dog-dang.png";

export default function MyPointSuccess(props) {
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const { title, subtitle, points, listDetail = [] } = props
    const { open, toPage } = props
    console.log('props >>', props)
    
    const isOpen = open ?? true
    if (isOpen) {
        return (
            <div id="PageSuccess">
                <TopLogo />
                <div className="bGetPointSuccess bgPrimaryGD">
                    <div className="bTop">
                        <img className="mx-auto" src={checkmark} alt="" width="52px" height="52px" />
                        <p className="text-center text-white text-[32px]">
                            {title}
                        </p>
                        <p className="text-center text-white text-[36px] leading-[84%]">
                            {subtitle}
                        </p>
                        <div className="mt-[60px]">
                            <img
                                className="mx-auto max-w-[190px] w-[100%] pl-[10px]"
                                src={LogoDogDang}
                                alt="logo"
                            />
                        </div>
                    </div>
                    <div className="bBottom">
                        <p className="text-left text-white text-[16px] leading-[84%] mb-[10%]">
                        *คุณสามารถร่วมกิจกรรมสแกนรับพ้อยท์ได้เดือนละครั้งเท่านั้น
                        </p>
                        <button onClick={() => { navigate(toPage)}} className="btn _outline transparentL w-full">
                            เสร็จสิ้น
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    return <></>

}