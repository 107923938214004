import React from "react";
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import TopLogo from '../../components/layout/TopLogo';
import paperplane from "../../assets/images/icon/paperplane.png";
import telephone from "../../assets/images/icon/phone.svg";
import facebook from "../../assets/images/icon/facebook.svg";
import line from "../../assets/images/icon/line.svg";
import { SearchId } from "../../services/SearchId";
import axios from "axios";
import { useLiff } from "react-liff";
import PrimaryButtonSmall from '../button/PrimaryButtonSmall';
import dayjs from "dayjs";
import { Campaign } from "@mui/icons-material";

const ShowRouteButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "showLabel"
})({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '21px',
    height: '48px',
    borderRadius: '28px',
    backgroundColor: '#00693E',
    color: 'white',
    minWidth: '100px',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1',
    display: 'flex',
    padding: '15px',
})

const BackButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "showLabel"
})({
    fontFamily: 'inherit',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
    height: '48px',
    borderRadius: '28px',
    backgroundColor: 'white',
    color: '#00693E',
    minWidth: '100px',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1',
    display: 'flex',
    padding: '15px',
})

export default function SpectraNearYouDetail(props) {

    const { liff } = useLiff();
    const lineId = liff.getDecodedIDToken().sub;
    const navigate = useNavigate();
    const [_memberId, setMemberId] = React.useState();
    const [couponList, setCouponList] = React.useState([]);

    const send_data_back = () => {
        console.log("send_data_back");
        props.func(true);
    }

    const [distance, setDistance] = React.useState(null);
    const HaveTime = props.funz.openTime !== null && props.funz.openTime !== undefined && props.funz.openTime !== '';

    const handleDistance = () => {
        if (props.funz.distance < 1000) {
            setDistance(Math.round(props.funz.distance) + ' ม.')
        } else {
            setDistance((Math.round(props.funz.distance) / 1000).toFixed(1) + ' กม.')
        }
    }

    const handleRoute = () => {
        if (props.distance < 1000) {
            return 'https://www.google.com/maps/dir/?api=1&destination=' + props.funz.latitude + ',' + props.funz.longitude;
        } else {
            return 'https://www.google.com/maps/dir/?api=1&destination=' + props.funz.latitude + ',' + props.funz.longitude;
        }
    }

    async function getSearchId() {
        const { data } = await SearchId({ params: { lineId } });
        if (+data["resultCode"] === 20000) {
            return data.body || {};
        } else {
            return {};
        }
    }

    function goToCoupon(item) {
        navigate('/spectra-near-you-coupons', {
            state: {
                memberId: _memberId,
                campaignId: item.campaignid,
                shopName: props.funz.shopName,
                clinicPetShopId: props.funz.clinicPetShopId
            }
        });
    }


    React.useEffect(() => {
        const fetch = async () => {
            const baseUrl = process.env.REACT_APP_BASE_URL || 'https://api-ngs.scapp.work'
            const { memberId } = await getSearchId();
            setMemberId(memberId)
            try {
                const { data } = await axios.get(baseUrl + '/searchCouponBySpectranearyou', {
                    params: {
                        memberId: memberId,
                        clinicPetShopId: props.funz.clinicPetShopId,
                    }
                });

                setCouponList(data.body);
                console.log(couponList)
            } catch (err) {
                console.error(err);
            }
        };
        fetch();
        window.scrollTo(0, 0);
        handleDistance();
    }, []);

    function Coupon(item) {
        return (
            <div className="flex h-[96px] w-full mb-[20px]"
                onClick={() => {
                    // fnAction(item)
                }
                }
            >
                <div style={{ borderRight: "2px dashed #E8E8E8", borderRadius: "8px", width: "30%" }} className="relative bg-white coupon">
                    {item.urlPicture ? (
                        <img style={{
                            width: "80%",
                            // height: "80%",
                            aspectRatio: '1/1',
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                        }} src={item.urlPicture} alt="" />
                    ) : (
                        <img style={{
                            width: "85%",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                        }} src="" alt="" />
                        // default pic for no pic coupon
                    )}
                </div>
                <div className="bg-white" style={{ borderRadius: "8px", width: "70%" }}>
                    <div className="flex justify-between px-[10px] pt-[7px]">
                        <div className="w-full">
                            <div className="text-[12px] flex justify-between">
                                <div className="flex">
                                    <p className="text-lightBlue">{item.clinicPetShop.shopName}</p>
                                </div>
                            </div>
                            <p className="text-blue text-[16px] leading-[12px]">{item.name}</p>
                            <p className="text-gray text-[14px]" >ใช้คูปองได้ถึง {dayjs(item.expire).format("DD/MM/YYYY")}</p>
                            <div className="flex justify-between mt-[5px] " style={{ float: "right" }}>
                                <PrimaryButtonSmall className="w-[73px] small-btn"
                                    onClick={() => {
                                        console.log('Go to coupon')
                                        goToCoupon(item)
                                    }}>ใช้คูปอง</PrimaryButtonSmall>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    return (
        <React.Fragment>
            <TopLogo />
            <div className="bgGraphics"
                style={{
                    minHeight: "calc(100vh)",
                    padding: '50px 10px 10px 10px'
                }}
            >
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{
                            fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
                            fontSize: 30
                        }} color="text.primary"
                            component={'div'}
                        >
                            {props.funz.shopName}
                        </Typography>

                        <Typography sx={{
                            mb: 1.5,
                            fontSize: 20
                        }} color="text.secondary" component={'div'}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <p className="text-[20px]">ระยะทาง:</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p style={{
                                        textAlign: 'end',
                                        color: '#00B960',
                                        fontSize: 35
                                    }}>{distance}</p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p className="text-[20px]">เวลาทำการ:</p>
                                </Grid>
                                <Grid item xs={6}>
                                    {HaveTime ?
                                        (<p className="text-[20px]" style={{ textAlign: 'end' }}>{`${props.funz.openTime.substring(0, 5)} - ${props.funz.closeTime.substring(0, 5)}`} น.</p>) :
                                        (<p className="text-[20px]" style={{ textAlign: 'end' }}>ไม่มีข้อมูล</p>)
                                    }
                                </Grid>
                            </Grid>
                        </Typography>

                        <Typography sx={{ mb: 1.5 }} color="text.secondary" component={'div'}>
                            <p className="text-[20px]">ช่องทางการติดต่อ</p>
                            {props.funz.mobileNumber &&
                                <Link href={"tel:" + props.funz.mobileNumber} target="_blank" underline="none" style={{
                                    float: 'left', clear: 'left', color: '#00693E'
                                }}><img src={telephone} alt="telephone" style={{ display: 'inline', marginBottom: "5px", marginRight: "5px" }} /> {props.funz.mobileNumber}</Link>
                            }
                            {props.funz.facebook &&
                                <Link href={props.funz.facebook} target="_blank" underline="none" style={{
                                    float: 'left', clear: 'left', color: '#00693E'
                                }}><img src={facebook} alt="facebook" style={{ display: 'inline', marginBottom: "5px", marginRight: "5px" }} /> {props.funz.shopName}</Link>
                            }
                            {props.funz.line &&
                                <Link href="#" underline="none" style={{
                                    float: 'left', clear: 'left', cursor: 'default', color: '#00693E'
                                }}><img src={line} alt="line" style={{ display: 'inline', marginBottom: "5px", marginRight: "5px" }} /> {props.funz.line}</Link>
                            }
                        </Typography>

                        <Typography sx={{ mb: 1.5 }} color="text.secondary" component={'div'}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                    <ShowRouteButton
                                        variant="contained"
                                        href={handleRoute()}
                                        target="_blank"
                                        style={{
                                            fontSize: '22px',
                                        }}
                                    >
                                        <img src={paperplane} alt="arrow" /> แสดงเส้นทาง
                                    </ShowRouteButton>
                                </Grid>
                            </Grid>
                        </Typography>
                    </CardContent>
                </Card>


                {/* <PrimaryButton
                    variant="contained"
                    style={{
                        minWidth: "50px",
                        background: "#197FC5",
                        color: "white",
                        width: "90%",
                    }}
                // onClick={handleOpen2}
                >
                    coupon
                </PrimaryButton> */}


                {couponList.length > 0 && <div className="container gradientBg px-[13px] overflow-hidden">
                    <div className="text-white text-[35px] mt-[20px]">
                        สิทธิพิเศษสำหรับ NexFamily
                    </div>
                    {couponList.map((item, index) => (
                        <div key={index}>
                            {Coupon(item)}
                        </div>
                    ))}
                </div>
                }

                {/* <div className="container">
                    <div className="card">
                        <div className="main">
                            <div className="co-img">
                                <img
                                    src="https://i.pinimg.com/originals/c7/84/67/c78467db9ff497393cb548a48f02d451.png"
                                    alt=""
                                />
                            </div>
                            <div className="vertical"></div>
                            <div className="content">
                                <h2>Mcdonalds</h2>
                                <h1>10% <span>Coupon</span></h1>
                                <p>Valid till 30 April 2021</p>
                            </div>
                        </div>
                        <div className="copy-button">
                            <input id="copyvalue" type="text" readonly value="GOFREE" />
                            <button onclick="copyIt()" className="copybtn">COPY</button>
                        </div>
                    </div>
                </div> */}



                <Paper sx={{
                    position: 'fixed', bottom: 0, left: 0, right: 0
                }} elevation={1}>
                    <BottomNavigation
                        style={{
                            alignItems: 'center', height: '90px'
                        }}
                    >
                        <button className="btn _outline w-[80%]" style={{
                            minWidth: "90px",
                        }} onClick={send_data_back}>
                            ย้อนกลับ
                        </button>
                    </BottomNavigation>
                </Paper>
            </div>
        </React.Fragment>
    )
}