import { Dialog } from "@mui/material";
import React from "react";
import PrimaryButton from "../button/PrimaryButton";
import SecondaryButton from "../button/SecondaryButton";
import NexFamily from "../../assets/images/nex-family.png";
import Dog from "../../assets/images/logo-dog-register.png";
import DogStar from "../../assets/images/logo-dog-star.png";

export default function PopupQuestionnaireSuccess(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="pb-[36px] pt-[24px] px-[24px] w-[320px] rounded-[4px]">
        <p className="text-lightBlue text-[28px] text-center leading-[100%]">
          ยินดีด้วย
        </p>
        <p className="text-lightBlue text-[32px] font-medium text-center leading-[100%] mb-[10px]">
          คุณตอบคำถามครบแล้ว
        </p>
        <i className="pt-[65%] pl-[70%] mx-auto" data-icon="dogstar"></i>
        <p className="text-darkGray text-[24px] text-center leading-[100%] mt-[15px]">
          คุณได้รับ NexCoin
        </p>
        <p className="text-lightBlue text-[42px] text-center leading-[100%]">
          {props.point} คะแนน
        </p>
        <div className="flex itesm-center gap-[13px] justify-center mt-[24px]">
          <SecondaryButton
            variant="outlined"
            className="w-[50%]"
            onClick={() => props.actionFunction()}
          >
            ปิด
          </SecondaryButton>
        </div>
      </div>
    </Dialog>
  );
}
