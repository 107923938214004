import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

import PrimaryButton from "../../components/button/PrimaryButton";
import TopLogo from '../../components/layout/TopLogo';
import {CreateUsePoint, SearchFreebiesById} from '../../services/RedeemGift';
import SecondaryButton from '../../components/button/SecondaryButton';
import RedeemGiftMemberError from './RedeemGiftMemberError';
import RedeemGiftMemberSuccess from './RedeemGiftMemberSuccess';
import dayjs from 'dayjs';
import SelectChannelDialog from '../../components/dialog/SelectChannelDialog';
import {SearchPetOwnerProfile} from '../../services/Dogbook';
import CustomPopup from '../../components/popup/CustomPopup';

export default function RedeemGiftMemberDetail() {
  const navigate = useNavigate();
  let {freebiesId, memberId} = useParams();
  const [detail, setDetail] = React.useState({});
  const [errorMsg, setErrorMsg] = React.useState('');
  const [usePointError, setUsePointError] = React.useState(false);
  const [successData, setSuccessData] = React.useState(null);
  const [openSelectChannelDialog, setOpenSelectChannelDialog] = React.useState(false);
  const [openEditedPopup, setOpenEditedPopup] = React.useState(false);

  useEffect(() => {
    (async () => {
      try {
        const detail = await getSearchFreebiesById();
        console.log('detail', detail);
        setDetail(detail)
      } catch (error) {
        console.log('fetchData error', error)
      }
    })()
  }, []);


  async function getSearchFreebiesById() {
    const {data} = await SearchFreebiesById({params: {freebiesId, memberId}})
    if (+data['resultCode'] === 20000) {
      setErrorMsg('');
      return data.body || {};
    } else if (+data['resultCode'] === 40304 ) {
      setErrorMsg('คะแนนของคุณไม่เพียงพอ');
      return data.body || {};
    }else if (+data['resultCode'] === 40301) {
      setErrorMsg('มีผู้แลกของรางวัลเต็มจำนวนแล้ว');
      return data.body || {};
    } else {
      setErrorMsg('');
      return {};
    }
  }

  async function getSearchPetOwnerProfile() {
    const {data} = await SearchPetOwnerProfile({member_id: memberId})
    if (+data['resultCode'] === 20000) {
      return data.body || {};
    } else if (+data['resultCode'] === 40304) {
      return data.body || {};
    } else {
      return {};
    }
  }

  async function postCreateUsePoint({pickUpType = "DELIVERY", shopId}) {
    const payload = {
      freebiesId: freebiesId,
      memberId: memberId,
      pickUpType,
    }
    if (shopId) {
      payload.clinicPetShopId = shopId;
    }
    const {data} = await CreateUsePoint({
      data: payload
    })
    if (+data['resultCode'] === 20000) {
      setUsePointError(false)
      const body = data['body'] || {};
      let address = '-';
      if (pickUpType === 'DELIVERY') {
        // set address
        const member = body['member'] || {};
        const deAddressDetail = member['deAddressDetail'] || '';
        const province = member['deAddress']['province'] || '';
        const amphoe = member['deAddress']['amphoe'] || '';
        const subDistrict = member['deAddress']['subDistrict'] || '';
        const zipcode = `${member['deAddress']['zipcode'] || ''}`;
        address = `${deAddressDetail} ${subDistrict} ${amphoe} ${province} ${zipcode}`;
      }
      // set success data
      setSuccessData({
        code: body['code'],
        shopName: detail['shopName'],
        isPartner: detail['isPartner'],
        urlPicture: detail['urlPicture'],
        name: detail['name'],
        createdDate: dayjs(body['createdDate']).format('DD/MM/YYYY HH:mm:ss'),
        points: detail['points']?.toLocaleString(),
        address,
      })
    } else {
      setUsePointError(true)
      setSuccessData(null)
    }
  }

  function openSelectChannel() {
    setOpenSelectChannelDialog(true);
  }

  function onClickBackFromError() {
    setUsePointError(false)
    setSuccessData(null)
  }

  const toggleSelectChannelDialog = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenSelectChannelDialog(open);
  };

  async function onOk({channel, shopId}) {
    console.log("on ok channel", {channel, shopId});
    setOpenSelectChannelDialog(false);

    let pickUpType = '';
    let dataView = {};
    if (channel === 1) {
      pickUpType = 'DELIVERY';
      dataView = await getSearchPetOwnerProfile();
      dataView['isFormRedeem'] = true;
    } else if (channel === 2) {
      pickUpType = 'SELF';
    } else {
      pickUpType = '';
    }

    if (channel === 1 && dataView['deAddress'] == null) {
      setOpenEditedPopup(true);
    } else {
      if (channel === 2 && !shopId) {
        return;
      }
      await postCreateUsePoint({pickUpType, shopId});
    }
  }

  return (
    <div id="PageRedeemGiftMemberDetail">
      <TopLogo/>
      {!usePointError && !successData &&
        <div className="bgPrimaryGD  h-screen-logo p-[20px]">
          <div className="flex flex-col justify-between bg-white px-5 py-10 rounded-[5px]"
               style={{minHeight: "calc(100vh - 100px)"}}>
            <div className="text-center leading-8">
              <div className="text-[18px] text-red font-[600]">
                สิทธิการแลกรางวัล {detail['qty']?.toLocaleString()} สิทธิ
              </div>
              <div className="text-[36px] text-lightBlue">
                ยืนยันการแลกของรางวัล
              </div>
              <div className="text-[24px]">
                {detail['name']}
              </div>
            </div>
            <div className="text-center">
              <img src={detail['urlPicture']} alt="redeem image" className="w-1/2 inline-block"/>
            </div>
            <div>
              <div className="text-[24px] text-lightBlue">
                เงื่อนไขการรับของรางวัล
              </div>
              <div className="pl-4">
                <ul className="list-disc list-outside">
                  {detail['description']?.split("\n").map((str) => (
                    <li>{str}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              {!errorMsg &&
                <PrimaryButton variant="contained" className="w-full" disabled={+detail['points'] <= 0}
                               onClick={openSelectChannel}>
                  ใช้ {detail['points']?.toLocaleString()} คะแนน
                </PrimaryButton>
              }
              {!!errorMsg &&
                <PrimaryButton variant="contained" className="w-full" disabled>
                  {errorMsg}
                </PrimaryButton>
              }
              <div className="my-3"></div>
              <SecondaryButton variant="outlined" className="w-full" onClick={() => {
                navigate(-1);
              }}>
                ยกเลิก
              </SecondaryButton>
              <div className="my-3"></div>
            </div>
          </div>
        </div>
      }
      {usePointError &&
        <RedeemGiftMemberError onClick={onClickBackFromError}/>
      }
      {!usePointError && successData &&
        <RedeemGiftMemberSuccess detail={successData}/>
      }
      <SelectChannelDialog title="เลือกวิธีการรับของรางวัล"
                           open={openSelectChannelDialog}
                           toggle={toggleSelectChannelDialog}
                           clinicList={detail['clinicList']}
                           onOk={onOk}>
      </SelectChannelDialog>
      <CustomPopup
        title={"ยังไม่มีที่อยู่จัดส่งของคุณในระบบ"}
        desc={"กรุณากรอกข้องมูลที่อยู่จัดส่งที่หน้า ‘สมุดพกน้องแมวและข้อมูลผู้ใช้’ โดยกดที่ปุ่มด้านล่างนี้"}
        cancelText={"ปิด"}
        actionText={'กรอกข้อมูลที่อยู่'}
        actionFunction={() => {
          navigate('/dogbook-edit-info')
          setOpenEditedPopup(false);
        }}
        open={openEditedPopup}
      />
    </div>
  )
}