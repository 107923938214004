import { Dialog } from "@mui/material";
import React from "react";
import PrimaryButton from "../button/PrimaryButton";
import SecondaryButton from "../button/SecondaryButton";

export default function CustomPopupShopMember(props) {
    const {
        onClose,
        selectedValue,
        open,
        // confirmType = true,
    } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
            <div className="pb-[36px] pt-[24px] px-[23px] rounded-[4px]">
                {props.title && <p className="text-lightBlue text-[28px] text-center">{props.title}</p>}
                <p className="bText-gray text-[18px] text-center whitespace-pre-line">{props.desc}</p>
                <div className="flex itesm-center gap-[13px] justify-center mt-[24px]">
                    {props.cancelFunction && (
                        <SecondaryButton
                            variant="outlined"
                            className={props.actionFunction ? 'w-full' : 'w-1/2'}
                            onClick={() => props.cancelFunction()}
                        >
                            {props.cancelText ? props.cancelText : "ยกเลิก"}
                        </SecondaryButton>
                    )}
                    {props.actionFunction && (
                        <PrimaryButton
                            onClick={() => props.actionFunction()}
                            variant="outlined"
                            className="w-full"
                        >
                            {props.actionText ? props.actionText : "ยืนยัน"}
                        </PrimaryButton>
                    )}
                </div>
            </div>
        </Dialog>
    );
}
