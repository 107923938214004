import React from "react";
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";

import TopLogo from '../../components/layout/TopLogo';
import helper from "../../functions/helper";
const SpectraNearYou = helper.PUBLICPATH("/assets/img/ci/spectra-near-you/img-map-shop.png")

export default function SpectraNearYouMain() {
    const [latitude, setLat] = React.useState(null);
    const [longitude, setLong] = React.useState(null);
    const navigate = useNavigate();

    const goToClinics = () =>
        navigate('/spectra-near-you-list', {
            state: {
                latitude: latitude,
                longitude: longitude,
                type: 'CLINIC'
            }
        });

    const goToShops = () =>
        navigate('/spectra-near-you-list', {
            state: {
                latitude: latitude,
                longitude: longitude,
                type: 'SHOP'
            }
        });

    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setLat(position.coords.latitude);
            setLong(position.coords.longitude);
        })
    }, []);

    // return (
    //     <div id="PageSpectraNearYouDetail">
    //         <TopLogo />
    //         <div className="bgPrimaryGD h-screen-logo bContent">
    //             <div className="bCardDetailMain">
    //                 <h1>Pet House Clinic</h1>
    //                 <div className="bCardDetailContent">
    //                     <p>ระยะทาง:</p>
    //                     <p><span>5 กม.</span></p>
    //                 </div>
    //                 <div className="bCardDetailFooter">

    //                     <div className="bCardDetailDesc">
    //                         <p>ช่องทางการติดต่อ:</p>
    //                         <div className="bSocial">
    //                             <a className="iTel">092 221  8921</a>
    //                             <a className="iFacebook">Pet House Clinic</a>
    //                             {/* <a className="iWeb"></a> */}
    //                             <a className="iLine">@pethouseclinic12</a>
    //                         </div>
    //                     </div>
    //                     <div className="bCardBtn">
    //                         <a className="btn"><i></i>แสดงเส้นทาง</a>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // )

    return (
        <div id="PageSpectraNearYouMain">
            <TopLogo />
            <div className="bgGraphics h-screen-logo bContent">
                <h1>ค้นหาโรงพยาบาลสัตว์ คลินิก<br />และเพ็ทช็อป ใกล้ฉัน</h1>
                <div className="bDescShareMain">
                    <div className="bDescShare">
                        <i></i><p>กรุณาแชร์ตำแหน่งของคุณก่อนใช้งาน</p> 
                    </div>
                    <img src={SpectraNearYou} alt="logo"/>
                </div>

                <div className="bBtnShare">
                    <button className="btn _outline" onClick={goToClinics} >
                        โรงพยาบาลสัตว์/คลินิกที่ใกล้ฉัน
                    </button>
                    <button  className="btn _outline" onClick={goToShops} >
                        เพ็ทช็อปที่ใกล้ฉัน
                    </button>
                </div>
            </div>
        </div>
    )
}
