/* eslint-disable react/jsx-no-undef */
import React, { useEffect } from "react";
import PrimaryButton from "../../components/button/PrimaryButton";
import PopupQuestionnaireSuccess from "../../components/popup/PopupQuestionnaireSuccess";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SecondaryButton from '../../components/button/SecondaryButton';
import { useDispatch, useSelector } from 'react-redux';
import TopLogo from '../../components/layout/TopLogo';
import { SearchId } from "../../services/SearchId";
import { useLiff } from "react-liff";
import { setQuestionnaire } from '../../features/QuestionnaireSlice';
import helper from "../../functions/helper";

import Checkmark from "../../assets/images/checkmark.png";
import LogoDogSit from "../../assets/images/logo-dog-sit.png";


export default function QuestionnaireSuccess() {
  const store = useSelector((state) => state.questionnaire)
  const [openCancelPopup, setOpenCancelPopup] = React.useState(true);
  const [memberId, setMemberId] = React.useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const dispatch = useDispatch()
  // const qRegisterAns = searchParams.get('registerAns')
  // const registerAns = JSON.parse(qRegisterAns)

  const registerAnsStr = window.localStorage.getItem('p')
  let registerAns = {}
  if (registerAnsStr) {
    // registerAns = JSON.parse(atob(registerAnsStr))
    registerAns = helper.decodeJWT(registerAnsStr)
  }

  const isMember = memberId ?? false
  const isShowPoint = store['points'] !== 0

  useEffect(() => {
    getSearchId();
    // eslint-disable-next-line
    const { memberId, points, payload} = registerAns
    fnSetData('memberId', memberId ?? null)
    fnSetData('points', points )
    fnSetData('payload', payload)
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          setMemberId(null)
        } else {
          setMemberId(response.data.body.memberId)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function fnSetData(name, value) {
    const tmpObj = {};
    tmpObj[name] = value;
    dispatch(setQuestionnaire(tmpObj));
  }

  return (
    <div id="PageQuestionnaireSuccess">
     <PopupQuestionnaireSuccess
        point={store['points']}
        actionFunction={() => {
          setOpenCancelPopup(false);
        }}
        open={isShowPoint && isMember && openCancelPopup}
        onClose={() => {
          setOpenCancelPopup(false);
        }}
      />
      <TopLogo/>
      {/* {JSON.stringify(store)} */}
      <div className="bgGraphics h-screen-logo p-[20px]">
        <i className="mt-[18px] w-[52px] h-[52px] mx-auto" data-icon="check"></i>
        <div className="text-white text-center text-[36px] mt-[10px] leading-[100%]">
        ตอบคำถามเรียบร้อย
        </div>
        <div className="text-white text-center text-[24px] leading-[100%] mb-[24px]">
        ขอบคุณที่ร่วมสนุก
        </div>

        <i className="w-[230px] h-[230px] mx-auto" data-icon="dogsit"></i>
        <div className="text-center mt-[50px]">
            <div className="my-4">
            { 
                isMember
                ? (
                  <button 
                    className="btn _outline transparentL w-fit"
                    onClick={() => {
                      window.localStorage.removeItem('p')
                      navigate('/dogbook')
                    }}
                  >
                    กลับไปที่สมุดพกน้องแมว
                  </button> 
                )
                : (
                  <button 
                    className="btn _outline transparentL w-fit"
                    onClick={() => {
                      window.localStorage.removeItem('p')
                      navigate('/register')
                    }}
                  >
                    ลงทะเบียน NEX Family
                  </button> 
                  
                )
              }
              </div>
          </div>
      </div>
    </div>
  );
}
