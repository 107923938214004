import React from "react";
import PrimaryButton from "../../components/button/PrimaryButton";
import check from "../../assets/images/icon/check.svg";
import {useNavigate} from 'react-router-dom';

export default function RedeemGiftMemberSuccess({detail}) {
  const navigate = useNavigate();

  function detailRow(text1, text2, line = 2) {
    return (
      <div className={`grid grid-cols-${line} text-white`}>
        <div className="text-[16px] font-[600]">{text1}</div>
        <div className={`text-[20px] font-[400] ${line === 2 && 'text-right'}`}>{text2}</div>
      </div>
    )
  }

  return (
    <>
      <div className="bg-img-blue h-screen-logo flex flex-col justify-between">
        <div className="px-5 leading-7">
          <div className="text-center">
            <img src={check} alt="error icon" className="inline-block py-10"/>
            <div className="text-[36px] text-white">แลกของรางวัลเรียบร้อยแล้ว</div>
            <div className="text-[24px] text-white">{detail["name"]}</div>
            <img src={detail['urlPicture']} alt="redeem image" className="w-1/2 inline-block py-5"/>
          </div>
          <div className="text-[24px] text-white leading-[100%]">
            รายละเอียด
          </div>
          <hr className="bText-primary-d my-2"/>
          {detailRow('รหัสรางวัล:', detail["code"])}
          {detailRow('วันที่/เวลาแลกของรางวัล:', detail["createdDate"])}
          {detailRow('NexCoin ที่ใช้:', `${detail["points"]} คะแนน`)}
          {detailRow('สถานที่จัดส่ง:', detail["address"], 1)}
        </div>
        <div className="text-center py-10">
          <PrimaryButton
            variant="contained"
            className="w-[70%] !bg-[#082e683d]"
            onClick={() => {
              navigate('/redeem-gift-member')
            }}
          >
            เสร็จสิ้น
          </PrimaryButton>
        </div>
      </div>
    </>
  )
}