import React, { useEffect, useState } from "react";

import { Autocomplete, Checkbox, Radio, TextField } from "@mui/material";
import SecondaryButton from "../../components/button/SecondaryButton";
import PrimaryButton from "../../components/button/PrimaryButton";
import TopBanner from "../../components/layout/TopBanner";
// import { useNavigate } from "react-router-dom";
import globalStyle from "../../assets/styles/constants";
import CustomPopup from "../../components/popup/CustomPopup";
import { SearchId } from "../../services/SearchId";
import {
  postUpdatePetOwnerProfile,
  SearchDdlFavHospital,
  SearchDdlFavShop,
  SearchPetOwnerProfile,
} from "../../services/Dogbook";
// import { tempLineId1 } from "../../services/Api";
import { Controller, useForm } from "react-hook-form";
import { SearchDdlAddress } from "../../services/Dropdown";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useLiff } from "react-liff";
import TopLogo from '../../components/layout/TopLogo';
import {useNavigate} from 'react-router-dom';

const initialInputValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  addressDetail: "",
  deAddressDetail: "",
  favoriteHospital: "",
  favoriteShop: "",
};

const initialErrorInput = {
  firstName: false,
  lastName: false,
  email: false,
  mobileNumber: false,
  address: false,
  addressDetail: false,
  isDeliveryAddress: false,
  deAddress: false,
  deAddressDetail: false,
  isFavorite: false,
  isHaveCat: false,
  favoriteHospital: false,
  favoriteShop: false,
};

export default function EditInfo() {
  const navigate = useNavigate();
  const txt = {
    require: "กรุณากรอก",
    province: "จังหวัด",
    amphoe: "เขต/อำเภอ",
    tambol: "แขวง/ตำบล",
    zipcode: "รหัสไปรษณีย์",
  };

  const [sameAddress, setSameAddress] = useState(false);
  const [hasClinic, setHasClinic] = useState("no");
  const [hasCat, setHasCat] = useState("no");

  const [openCancelPopup, setOpenCancelPopup] = React.useState(false);
  const [openEditedPopup, setOpenEditedPopup] = React.useState(false);
  const [userInput, setUserInput] = useState(initialInputValues);

  let [ownerProfile, setOwnerProfile] = useState(null);

  //address
  const [shippingAddressId, setShippingAddressId] = React.useState(null);
  const [shippingTambol, setShippingTambol] = React.useState(null);
  const [shippingAmphoe, setShippingAmphoe] = React.useState(null);
  const [shippingProvince, setShippingProvince] = React.useState(null);
  const [shippingZipcode, setShippingZipcode] = React.useState(null);

  const [openShippingTambol, setOpenShippingTambol] = React.useState(false);
  const [openShippingAmphoe, setOpenShippingAmphoe] = React.useState(false);
  const [openShippingProvince, setOpenShippingProvince] = React.useState(false);
  const [openShippingZipcode, setOpenShippingZipcode] = React.useState(false);

  //deAddress

  const [deShippingAddressId, setDeShippingAddressId] = React.useState(null);
  const [deShippingTambol, setDeShippingTambol] = React.useState(null);
  const [deShippingAmphoe, setDeShippingAmphoe] = React.useState(null);
  const [deShippingProvince, setDeShippingProvince] = React.useState(null);
  const [deShippingZipcode, setDeShippingZipcode] = React.useState(null);

  const [openDeShippingTambol, setOpenDeShippingTambol] = React.useState(false);
  const [openDeShippingAmphoe, setOpenDeShippingAmphoe] = React.useState(false);
  const [openDeShippingProvince, setOpenDeShippingProvince] =
    React.useState(false);
  const [openDeShippingZipcode, setOpenDeShippingZipcode] =
    React.useState(false);

  const [addressList, setAddressList] = React.useState([]);
  const [delayId, setDelayId] = React.useState(null);

  const [errorInput, setErrorInput] = useState(initialErrorInput);

  const [favHospital, setFavHospital] = useState(null);
  const [favShop, setFavShop] = useState(null);

  const [defaultFavHospital, setDefaultFavHospital] = useState(null);
  const [defaultFavShop, setDefaultFavShop] = useState(null);

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const {
    formState: { errors },
    setValue,
    control,
  } = useForm({
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      isDeliveryAddress: false,
    },
  });

  useEffect(() => {
    getSearchId();
    getFavHospital('');
    getFavShop('');
    // eslint-disable-next-line
  }, []);

  async function getSearchDdlAddress(search) {
    try {
      const { data } = await SearchDdlAddress({ params: { search } });
      if (+data["resultCode"] === 20000) {
        const body = data.body || [];
        // console.log("body", body);
        if (Object.keys(body).length === 0) {
          setAddressList([]);
        } else {
          setAddressList(body);
        }
      } else {
        setAddressList([]);
      }
    } catch (error) {
      console.log("getSearchDdlAddress error", error);
    }
  }

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          window.location.replace("/register");
        } else getOwnerProfile(response.data.body.memberId);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getOwnerProfile(member_id) {
    return await SearchPetOwnerProfile({
      params: { lineId: lineId || null },
      member_id: member_id,
    })
      .then(async (response) => {
        setOwnerProfile(response.data.body);
        if (response.data.body.isHaveCat) {
          setHasCat("yes");
        }
        if (response.data.body.isFavorite) {
          setHasClinic("yes");
        }

        let ownerRes = response.data.body;
        if (ownerRes) {
          let updatedInput = {
            ...userInput,
            firstName: ownerRes.firstName,
            lastName: ownerRes.lastName,
            email: ownerRes.email,
            mobileNumber: ownerRes.mobileNumber,
            addressDetail: ownerRes.addressDetail,
            favoriteHospital: ownerRes.favoriteHospital?.clinicPetShopId,
            favoriteShop: ownerRes.favoriteShop?.clinicPetShopId
          };
          setUserInput(updatedInput);

          // console.log(ownerRes);

          if (ownerRes) {
            setDefaultFavHospital({
              id: ownerRes.favoriteHospital?.clinicPetShopId,
              name: ownerRes.favoriteHospital?.shopName,
            });
            setDefaultFavShop({
              id: ownerRes.favoriteShop?.clinicPetShopId,
              name: ownerRes.favoriteShop?.shopName,
            });
          }

          let addressRes = ownerRes.address;
          console.log('AAA')
          setShippingAddressId(addressRes.id);

          setShippingTambol(addressRes);
          setValue("shippingTambol", addressRes?.subDistrict, {
            shouldValidate: true,
          });
          setShippingAmphoe(addressRes);
          setValue("shippingAmphoe", addressRes?.amphoe, {
            shouldValidate: true,
          });
          setShippingProvince(addressRes);
          setValue("shippingProvince", addressRes?.province, {
            shouldValidate: true,
          });
          setShippingZipcode(addressRes);
          setValue("shippingZipcode", addressRes?.zipcode, {
            shouldValidate: true,
          });

          if (ownerRes.isDeliveryAddress) {
            setSameAddress(true);
          } else {
            let deAddressRes = ownerRes.deAddress;

            setDeShippingAddressId(deAddressRes.id);

            setDeShippingTambol(deAddressRes);
            setValue("deShippingTambol", deAddressRes.subDistrict, {
              shouldValidate: true,
            });
            setDeShippingAmphoe(deAddressRes);
            setValue("deShippingAmphoe", deAddressRes.amphoe, {
              shouldValidate: true,
            });
            setDeShippingProvince(deAddressRes);
            setValue("deShippingProvince", deAddressRes.province, {
              shouldValidate: true,
            });
            setDeShippingZipcode(deAddressRes);
            setValue("deShippingZipcode", deAddressRes.zipcode, {
              shouldValidate: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // console.log(ownerProfile);

  async function onInputChangeAddress(e, type) {
    setShippingAddressId(null);
    fnSetOpenAddress(type, false);
    const value = e.target.value;
    if (type === txt.tambol) {
      setValue("shippingTambol", value, { shouldValidate: true });
    } else if (type === txt.amphoe) {
      setValue("shippingAmphoe", value, { shouldValidate: true });
    } else if (type === txt.province) {
      setValue("shippingProvince", value, { shouldValidate: true });
    } else if (type === txt.zipcode) {
      setValue("shippingZipcode", value, { shouldValidate: true });
    }
    clearTimeout(delayId);
    const timeoutId = setTimeout(async () => {
      if (value) {
        await getSearchDdlAddress(value);
        fnSetOpenAddress(type, true);
      } else {
        setAddressList([]);
      }
    }, 500);
    setDelayId(timeoutId);
  }

  async function onInputChangeDeAddress(e, type) {
    setDeShippingAddressId(null);
    fnSetOpenDeAddress(type, false);
    const value = e.target.value;
    if (type === txt.tambol) {
      setValue("deShippingTambol", value, { shouldValidate: true });
    } else if (type === txt.amphoe) {
      setValue("deShippingAmphoe", value, { shouldValidate: true });
    } else if (type === txt.province) {
      setValue("deShippingProvince", value, { shouldValidate: true });
    } else if (type === txt.zipcode) {
      setValue("deShippingZipcode", value, { shouldValidate: true });
    }
    clearTimeout(delayId);
    const timeoutId = setTimeout(async () => {
      if (value) {
        await getSearchDdlAddress(value);
        fnSetOpenDeAddress(type, true);
      } else {
        setAddressList([]);
      }
    }, 500);
    setDelayId(timeoutId);
  }

  function fnRenderFavOption(props, option, { inputValue }) {
    const favHospital = `${option.name}`;

    const matches1 = match(favHospital, inputValue, { insideWords: true });
    const parts1 = parse(favHospital, matches1);

    return (
      <li {...props} className="text-left pl-1 border-address-list">
        <div>
          {parts1.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
                color: part.highlight ? "#00693E" : "#8F8F8F",
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </li>
    );
  }

  function fnRenderOption(props, option, { inputValue }) {
    const address1 = `${option.subDistrict}, ${option.amphoe}, ${option.zipcode}`;
    const address2 = `จังหวัด ${option.province}`;

    const matches1 = match(address1, inputValue, { insideWords: true });
    const parts1 = parse(address1, matches1);

    const matches2 = match(address2, inputValue, { insideWords: true });
    const parts2 = parse(address2, matches2);

    return (
      <li {...props} className="text-left pl-1 border-address-list">
        {/*display address*/}
        <div>
          {parts1.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
                color: part.highlight ? "#00693E" : "#8F8F8F",
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
        {/*display province*/}
        <div>
          {parts2.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
                color: part.highlight ? "#00693E" : "#8F8F8F",
              }}
            >
              {part.text}
            </span>
          ))}
        </div>
      </li>
    );
  }

  function fnSetOpenDeAddress(type, open) {
    if (type === txt.tambol) {
      setOpenDeShippingTambol(open);
    } else if (type === txt.amphoe) {
      setOpenDeShippingAmphoe(open);
    } else if (type === txt.province) {
      setOpenDeShippingProvince(open);
    } else if (type === txt.zipcode) {
      setOpenDeShippingZipcode(open);
    }
  }

  function fnSetOpenAddress(type, open) {
    if (type === txt.tambol) {
      setOpenShippingTambol(open);
    } else if (type === txt.amphoe) {
      setOpenShippingAmphoe(open);
    } else if (type === txt.province) {
      setOpenShippingProvince(open);
    } else if (type === txt.zipcode) {
      setOpenShippingZipcode(open);
    }
  }

  function fnOnChangeDeAddress(e, value) {
    setDeShippingAddressId(value.id);
    fnSetOpenDeAddress(txt.tambol, false);
    fnSetOpenDeAddress(txt.amphoe, false);
    fnSetOpenDeAddress(txt.province, false);
    fnSetOpenDeAddress(txt.zipcode, false);
    setDeShippingTambol(value);
    setValue("deShippingTambol", value.subDistrict, { shouldValidate: true });
    setDeShippingAmphoe(value);
    setValue("deShippingAmphoe", value.amphoe, { shouldValidate: true });
    setDeShippingProvince(value);
    setValue("deShippingProvince", value.province, { shouldValidate: true });
    setDeShippingZipcode(value);
    setValue("deShippingZipcode", value.zipcode, { shouldValidate: true });
  }

  function fnOnChangeAddress(e, value) {
    setShippingAddressId(value.id);
    fnSetOpenAddress(txt.tambol, false);
    fnSetOpenAddress(txt.amphoe, false);
    fnSetOpenAddress(txt.province, false);
    fnSetOpenAddress(txt.zipcode, false);
    setShippingTambol(value);
    setValue("shippingTambol", value.subDistrict, { shouldValidate: true });
    setShippingAmphoe(value);
    setValue("shippingAmphoe", value.amphoe, { shouldValidate: true });
    setShippingProvince(value);
    setValue("shippingProvince", value.province, { shouldValidate: true });
    setShippingZipcode(value);
    setValue("shippingZipcode", value.zipcode, { shouldValidate: true });
  }

  async function postUpdatePetDetail() {
    const payload = {
      memberId: ownerProfile.memberId,
      firstName: userInput.firstName,
      lastName: userInput.lastName,
      email: userInput.email || '',
      mobileNumber: userInput.mobileNumber || '',
      address: shippingAddressId,
      addressDetail: userInput.addressDetail || '',
      isDeliveryAddress: sameAddress,
      deAddress: deShippingAddressId,
      deAddressDetail: userInput.deAddressDetail || '',
      isFavorite: hasClinic === "no" ? false : true,
      isHaveCat: hasCat === "no" ? false : true,
      favoriteHospital: userInput.favoriteHospital,
      favoriteShop: userInput.favoriteShop,
    };
    if(sameAddress) {
      payload.deAddress = shippingAddressId;
      payload.deAddressDetail = userInput.addressDetail;
    }
    if (validateInput()) {
      try {
        const { data } = await postUpdatePetOwnerProfile({ data: payload });
        console.log("resData.data >>", data);
        if (data.resultCode === 20000) {
          // console.log(payload);
          setOpenEditedPopup(true);
        }
      } catch (error) {
        console.log("postUpdatePetOwnerProfile error", error);
      }
    }
  }

  async function getFavHospital(keyword) {
    return await SearchDdlFavHospital({
      params: { lineId: lineId || null },
      keyword: keyword,
    })
      .then(async (response) => {
        setFavHospital(response.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getFavShop(keyword) {
    return await SearchDdlFavShop({
      params: { lineId: lineId || null },
      keyword: keyword,
    })
      .then(async (response) => {
        setFavShop(response.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function validateInput() {
    console.log(hasCat)
    let updatedError = {
      firstName: userInput.firstName !== null ? false : true,
      lastName: userInput.lastName !== null ? false : true,
      email: userInput.email !== "" ? false : true,
      mobileNumber: userInput.mobileNumber !== null ? false : true,
      address: userInput.address !== null ? false : true,
      // addressDetail: userInput.addressDetail !== null ? false : true,
      isDeliveryAddress: userInput.isDeliveryAddress !== null ? false : true,
      deAddress: userInput.deAddress !== null ? false : true,
      deAddressDetail: userInput.deAddressDetail !== null ? false : true,
      isFavorite: userInput.isFavorite !== null ? false : true,
      isHaveCat: hasCat !== null ? false : true,
      favoriteHospital: hasClinic === "no" || (hasClinic === "yes" && !!userInput.favoriteHospital)
          ? false
          : true,
      favoriteShop:
        userInput.favoriteShop !== "" && userInput.favoriteShop !== null
          ? false
          : true,
    };
    console.log(updatedError)
    let check = false;
    const entries = Object.entries(updatedError);
    // eslint-disable-next-line
    for (const [key, value] of entries) {
      if (value === true) {
        check = true;
        break;
      }
    }
    if (!check) {
      return true;
    }

    setErrorInput(updatedError);
    return false;
  }

  return (
    <div id="PageDogbookEdit">
      <div id="editinfo-page">
        <CustomPopup
          title={"บันทึกการแก้ไขเรียบร้อย"}
          desc={"ข้อมูลผู้ใช้ที่คุณแก้ไขได้รับการบันทึกแล้ว"}
          cancelText={"ปิด"}
          cancelFunction={() => {
            setOpenEditedPopup(false);
            navigate(-1);
          }}
          open={openEditedPopup}
          onClose={() => {
            setOpenEditedPopup(false);
            navigate(-1);
          }}
        />

        <CustomPopup
          title={"ยกเลิกการแก้ไขข้อมูลผู้ใช้"}
          desc={
            "ข้อมูลผู้ใช้ที่คุณเปลี่ยนแปลงจะยังไม่ถูกบันทึก หากมั่นใจแล้วกรุณากด ยืนยัน"
          }
          cancelFunction={() => {
            setOpenCancelPopup(false);
          }}
          actionText={'ยืนยัน'}
          actionFunction={() => {
            setOpenCancelPopup(false);
            navigate(-1);
          }}
          open={openCancelPopup}
          onClose={() => {
            setOpenCancelPopup(false);
          }}
        />
        <TopLogo/>
        <TopBanner type="dogbook" />
        <div id="add-dog" className="relative mt-[-20px] px-[20px]">
          <div className="bg-white py-[24px] rounded-[4px] mb-[36px] px-[17px]">
            <p className="text-lightBlue text-[28px] text-left">
              แก้ไขข้อมูลส่วนตัว
            </p>

            {ownerProfile && (
              <div className="flex flex-col mt-[16px] gap-[16px]">
                <div className="flex gap-[15px]">
                  <TextField
                    className="w-full text-[20px]"
                    label="ชื่อ"
                    required
                    variant="standard"
                    error={errorInput.firstName}
                    helperText="ชื่อไม่ถูกต้อง"
                    defaultValue={ownerProfile.firstName}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        let updatedInput = {
                          ...userInput,
                          firstName: event.target.value || null,
                        };
                        setUserInput(updatedInput);
                      }
                    }}
                  />
                  <TextField
                    className="w-full text-[20px]"
                    label="นามสกุล"
                    required
                    variant="standard"
                    error={errorInput.lastName}
                    helperText="นามสกุลไม่ถูกต้อง"
                    defaultValue={ownerProfile.lastName}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        let updatedInput = {
                          ...userInput,
                          lastName: event.target.value || null,
                        };
                        setUserInput(updatedInput);
                      }
                    }}
                  />
                </div>

                <TextField
                  className="w-full text-[20px]"
                  label="อีเมล"
                  required
                  error={errorInput.email}
                  helperText="อีเมลไม่ถูกต้อง"
                  variant="standard"
                  defaultValue={ownerProfile.email}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      let updatedInput = {
                        ...userInput,
                        email: event.target.value || null,
                      };
                      setUserInput(updatedInput);
                    }
                  }}
                />

                <TextField
                  className="w-full text-[20px]"
                  label="เบอร์โทรศัพท์"
                  required
                  variant="standard"
                  error={errorInput.mobileNumber}
                  helperText="เบอร์โทรศัพท์ไม่ถูกต้อง"
                  defaultValue={ownerProfile.mobileNumber}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      let updatedInput = {
                        ...userInput,
                        mobileNumber: event.target.value || null,
                      };
                      setUserInput(updatedInput);
                    }
                  }}
                />
              </div>
            )}

            <p className="text-lightBlue text-[28px] text-left mt-[32px]">
              แก้ไขข้อมูลที่อยู่
            </p>

            {ownerProfile && (
              <>
                <div className="flex flex-col gap-[16px]">
                  <div>
                    <TextField
                      className="w-full text-[20px]"
                      label="รายละเอียดที่อยู่"
                      required
                      variant="standard"
                      defaultValue={ownerProfile.addressDetail}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          let updatedInput = {
                            ...userInput,
                            addressDetail: event.target.value || null,
                          };
                          setUserInput(updatedInput);
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Controller
                      name="shippingTambol"
                      control={control}
                      rules={{
                        required: `${txt.require}${txt.tambol}`,
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          freeSolo
                          disableClearable
                          onChange={fnOnChangeAddress}
                          options={addressList}
                          open={openShippingTambol}
                          onClose={() => fnSetOpenAddress(txt.tambol, false)}
                          getOptionLabel={(option) => option.subDistrict}
                          value={shippingTambol}
                          // defaultValue={ownerProfile.address.subDistrict}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                              label={txt.tambol}
                              variant="standard"
                              // error={!!errors?.shippingTambol}
                              // helperText={errors?.shippingTambol?.message}
                              onInput={(e) => onInputChangeAddress(e, txt.tambol)}
                            />
                          )}
                          renderOption={fnRenderOption}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="shippingAmphoe"
                      control={control}
                      rules={{
                        required: `${txt.require}${txt.amphoe}`,
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          freeSolo
                          disableClearable
                          onChange={fnOnChangeAddress}
                          options={addressList}
                          open={openShippingAmphoe}
                          onClose={() => fnSetOpenAddress(txt.amphoe, false)}
                          getOptionLabel={(option) => option.amphoe}
                          value={shippingAmphoe}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                              label={txt.amphoe}
                              variant="standard"
                              error={!!errors?.shippingAmphoe}
                              helperText={errors?.shippingAmphoe?.message}
                              onInput={(e) => onInputChangeAddress(e, txt.amphoe)}
                            />
                          )}
                          renderOption={fnRenderOption}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="shippingProvince"
                      control={control}
                      rules={{
                        required: `${txt.require}${txt.province}`,
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          freeSolo
                          disableClearable
                          onChange={fnOnChangeAddress}
                          options={addressList}
                          open={openShippingProvince}
                          onClose={() => fnSetOpenAddress(txt.province, false)}
                          getOptionLabel={(option) => option.province}
                          value={shippingProvince}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                              label={txt.province}
                              variant="standard"
                              error={!!errors?.shippingProvince}
                              helperText={errors?.shippingProvince?.message}
                              onInput={(e) =>
                                onInputChangeAddress(e, txt.province)
                              }
                            />
                          )}
                          renderOption={fnRenderOption}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="shippingZipcode"
                      control={control}
                      rules={{
                        required: `${txt.require}${txt.zipcode}`,
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          freeSolo
                          disableClearable
                          onChange={fnOnChangeAddress}
                          options={addressList}
                          open={openShippingZipcode}
                          onClose={() => fnSetOpenAddress(txt.zipcode, false)}
                          getOptionLabel={(option) => option.zipcode}
                          value={shippingZipcode}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                              label={txt.zipcode}
                              variant="standard"
                              error={!!errors?.shippingZipcode}
                              helperText={errors?.shippingZipcode?.message}
                              onInput={(e) =>
                                onInputChangeAddress(e, txt.zipcode)
                              }
                            />
                          )}
                          renderOption={fnRenderOption}
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="flex gap-[12px] items-center mt-[22px]">
              <Checkbox
                checked={sameAddress}
                onChange={(event) => setSameAddress(event.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
                sx={globalStyle.checkboxStyle}
              />
              <p className="text-[20px]">ใช้ที่อยู่นี้เป็นที่อยู่จัดส่ง</p>
            </div>

            {!sameAddress && ownerProfile && (
              <div>
                <p className="text-lightBlue text-[28px] text-left mt-[32px]">
                  แก้ไขข้อมูลที่จัดส่ง
                </p>

                <>
                  <div className="flex flex-col gap-[16px]">
                    <div>
                      <TextField
                        className="w-full text-[20px]"
                        label="รายละเอียดที่อยู่"
                        required
                        variant="standard"
                        defaultValue={ownerProfile.deAddressDetail}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            let updatedInput = {
                              ...userInput,
                              deAddressDetail: event.target.value || null,
                            };
                            setUserInput(updatedInput);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <Controller
                        name="shippingTambol"
                        control={control}
                        rules={{
                          required: `${txt.require}${txt.tambol}`,
                        }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            freeSolo
                            disableClearable
                            onChange={fnOnChangeDeAddress}
                            options={addressList}
                            open={openDeShippingTambol}
                            onClose={() => fnSetOpenAddress(txt.tambol, false)}
                            getOptionLabel={(option) => option.subDistrict}
                            value={deShippingTambol}
                            // defaultValue={ownerProfile.address.subDistrict}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                label={txt.tambol}
                                variant="standard"
                                // error={!!errors?.shippingTambol}
                                // helperText={errors?.shippingTambol?.message}
                                onInput={(e) =>
                                  onInputChangeDeAddress(e, txt.tambol)
                                }
                              />
                            )}
                            renderOption={fnRenderOption}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Controller
                        name="shippingAmphoe"
                        control={control}
                        rules={{
                          required: `${txt.require}${txt.amphoe}`,
                        }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            freeSolo
                            disableClearable
                            onChange={fnOnChangeDeAddress}
                            options={addressList}
                            open={openDeShippingAmphoe}
                            onClose={() => fnSetOpenAddress(txt.amphoe, false)}
                            getOptionLabel={(option) => option.amphoe}
                            value={deShippingAmphoe}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                label={txt.amphoe}
                                variant="standard"
                                error={!!errors?.shippingAmphoe}
                                helperText={errors?.shippingAmphoe?.message}
                                onInput={(e) =>
                                  onInputChangeDeAddress(e, txt.amphoe)
                                }
                              />
                            )}
                            renderOption={fnRenderOption}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Controller
                        name="shippingProvince"
                        control={control}
                        rules={{
                          required: `${txt.require}${txt.province}`,
                        }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            freeSolo
                            disableClearable
                            onChange={fnOnChangeDeAddress}
                            options={addressList}
                            open={openDeShippingProvince}
                            onClose={() => fnSetOpenAddress(txt.province, false)}
                            getOptionLabel={(option) => option.province}
                            value={deShippingProvince}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                label={txt.province}
                                variant="standard"
                                error={!!errors?.shippingProvince}
                                helperText={errors?.shippingProvince?.message}
                                onInput={(e) =>
                                  onInputChangeDeAddress(e, txt.province)
                                }
                              />
                            )}
                            renderOption={fnRenderOption}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <Controller
                        name="shippingZipcode"
                        control={control}
                        rules={{
                          required: `${txt.require}${txt.zipcode}`,
                        }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            freeSolo
                            disableClearable
                            onChange={fnOnChangeDeAddress}
                            options={addressList}
                            open={openDeShippingZipcode}
                            onClose={() => fnSetOpenAddress(txt.zipcode, false)}
                            getOptionLabel={(option) => option.zipcode}
                            value={deShippingZipcode}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                label={txt.zipcode}
                                variant="standard"
                                error={!!errors?.shippingZipcode}
                                helperText={errors?.shippingZipcode?.message}
                                onInput={(e) =>
                                  onInputChangeDeAddress(e, txt.zipcode)
                                }
                              />
                            )}
                            renderOption={fnRenderOption}
                          />
                        )}
                      />
                    </div>
                  </div>
                </>
              </div>
            )}

            <p className="text-lightBlue text-[28px] text-left mt-[32px]">
              แก้ไขข้อมูลส่วนอื่น
            </p>

            <div className="flex flex-col gap-[12px] mt-[12px]">
              <p className="text-[20px] text-darkGray">
                เพื่อรับสิทธิประโยชน์จากโรงพยาบาลสัตว์/คลินิก และร้าน
                เพ็ทช็อปที่คุณใช้บริการประจำ กรุณากรอกข้อมูล ให้ครบถ้วน
              </p>
              <div>
                <p className="text-[24px] text-darkGray text-left">
                  ท่านมีโรงพยาบาลสัตว์/คลินิกประจำหรือไม่?{" "}
                  <span className="!text-red">*</span>
                </p>

                <div className="flex gap-[36px] items-center">
                  <div className="flex gap-[8px] items-center">
                    <Radio
                      checked={hasClinic === "yes"}
                      onChange={(event) => setHasClinic(event.target.value)}
                      value={"yes"}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "yes" }}
                      sx={globalStyle.radioStyle}
                    />
                    <p className="text-[24px]">มี</p>
                  </div>
                  <div className="flex gap-[8px] items-center">
                    <Radio
                      checked={hasClinic === "no"}
                      onChange={(event) => {
                        setHasClinic(event.target.value)
                        let updatedInput = {
                          ...userInput,
                          favoriteHospital: null,
                        };
                        setDefaultFavHospital({})
                        setUserInput(updatedInput);
                      }}
                      value={"no"}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "no" }}
                      sx={globalStyle.radioStyle}
                    />
                    <p className="text-[24px]">ไม่มี</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-[16px] gap-[16px]">
                {ownerProfile && favHospital && (
                  <Autocomplete
                    disablePortal
                    freeSolo
                    value={defaultFavHospital}
                    disabled={hasClinic === "no" ? true : false}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        let updatedInput = {
                          ...userInput,
                          favoriteHospital: newValue.id || 0,
                        };
                        setUserInput(updatedInput);
                      } else {
                        let updatedInput = {
                          ...userInput,
                          favoriteHospital: null,
                        };
                        setUserInput(updatedInput);
                      }
                    }}
                    options={favHospital}
                    getOptionLabel={(item) => item.name || ""}
                    renderInput={(params) => (
                      <TextField
                        // disabled={hasClinic === "no" ? true : false}
                        {...params}
                        onChange={(e) => {
                          getFavHospital(e.target.value);
                        }}
                        className="w-full text-[20px]"
                        label="โรงพยาบาลสัตว์/คลินิก/เพ็ทช็อปประจำ"
                        error={errorInput.favoriteHospital}
                        helperText="กรุณากรอกโรงพยาบาลสัตว์/คลินิก/เพ็ทช็อปประจำ"
                        required
                        variant="standard"
                      />
                    )}
                    renderOption={fnRenderFavOption}
                  />
                )}
                {ownerProfile && favShop && (
                  <Autocomplete
                    disablePortal
                    freeSolo
                    value={defaultFavShop}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        let updatedInput = {
                          ...userInput,
                          favoriteShop: newValue.id || 0,
                        };
                        setUserInput(updatedInput);
                      } else {
                        let updatedInput = {
                          ...userInput,
                          favoriteShop: null,
                        };
                        setUserInput(updatedInput);
                      }
                    }}
                    options={favShop}
                    getOptionLabel={(item) => item.name || ""}
                    renderInput={(params) => (
                      <TextField
                        // disabled={hasClinic === "no" ? true : false}
                        {...params}
                        onChange={(e) => {
                          console.log(e.target.value)
                          getFavShop(e.target.value);
                        }}
                        className="w-full text-[20px]"
                        label="ร้านเพ็ทช็อปประจำ"
                        error={errorInput.favoriteShop}
                        helperText="ร้านเพ็ทช็อปประจำไม่ถูกต้อง"
                        required
                        variant="standard"
                      />
                    )}
                    renderOption={fnRenderFavOption}
                  />
                )}
              </div>

              <div>
                <p className="text-[24px] text-darkGray text-left">
                  ที่บ้านของท่านเลี้ยงสุนัขด้วยหรือไม่?
                </p>

                <div className="flex gap-[36px] items-center">
                  <div className="flex gap-[8px] items-center">
                    <Radio
                      checked={hasCat === "yes"}
                      onChange={(event) => setHasCat(event.target.value)}
                      value={"yes"}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "yes" }}
                      sx={globalStyle.radioStyle}
                    />
                    <p className="text-[24px]">เลี้ยง</p>
                  </div>
                  <div className="flex gap-[8px] items-center">
                    <Radio
                      checked={hasCat === "no"}
                      onChange={(event) => setHasCat(event.target.value)}
                      value={"no"}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "no" }}
                      sx={globalStyle.radioStyle}
                    />
                    <p className="text-[24px]">ไม่เลี้ยง</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex itesm-center gap-[13px] justify-center bg-white px-[20px] pt-[16px] pb-[24px]">
          <SecondaryButton
            variant="outlined"
            className="w-full"
            onClick={() => setOpenCancelPopup(true)}
          >
            ยกเลิก
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              postUpdatePetDetail();
            }}
            variant="outlined"
            className="w-full"
          >
            บันทึก
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
