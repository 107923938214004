import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import calendar from "../../assets/images/icon/calendar.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TopBanner from "../../components/layout/TopBanner";
import TopLogo from "../../components/layout/TopLogo";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomPopup from "../../components/popup/CustomPopup";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { SearchPetDaily } from "../../services/PetDaily";
import { useLiff } from "react-liff";
import { SearchId } from "../../services/SearchId";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { Pagination, FreeMode, Navigation } from "swiper";
import PetDailyCalendar from "./PetDiaryCalendar";
import dayjs from "dayjs";

import DogPaw from "../../assets/images/icon/DogPaw";
import IconExport from "../../components/IconExport";
const { edit, petFace, editWhite } = IconExport

const initCurrentDate = dayjs().format('YYYY-MM-DD');
const initActDetail = [];
export default function PetDailyForm() {
  const [searchParams] = useSearchParams();
  const [isAdded, setIsAdded] = useState(false);
  const qPetId = searchParams.get('petId')
  const navigate = useNavigate();

  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;
  // const lineId = 'Uaac2ca5a02feab67a18d5521b572b5aa'

  const [currentDate, setCurrentDate] = React.useState(initCurrentDate);
  const [actDetail, setActDetail] = React.useState(initActDetail);
  const [petDiaryCount, setPetDiaryCount] = React.useState(0);
  const [openCancelPopup, setOpenCancelPopup] = React.useState(false);
  const [memberId, setMemberId] = React.useState(null);



  
  // Use memberId
  useEffect(() => {
    getSearchId();
    // eslint-disable-next-line
  }, []);

  async function getSearchId() {
    return await SearchId({
      params: { lineId: lineId || null },
    })
      .then(async (response) => {
        if (!response.data.body.memberId) {
          window.location.replace("/register");
        } else {
          setMemberId(response.data.body.memberId);
          getSearchPetDaily(response.data.body.memberId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (memberId) {
      getSearchPetDaily();
    }
  }, [currentDate]);

  async function getSearchPetDaily(member_id) {
    return await SearchPetDaily({
      params: { diaryDate: currentDate, memberId: memberId ?? member_id  },
    })
      .then(async (response) => {
        const resData = response.data
        if (resData.resultCode == "20000") {
          console.log('resData.body >>', resData.body)
          setActDetail(resData.body)
          setPetDiaryCount(resData.petDiaryCount)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  const ItemActivity = (item, petName = "", countAct = 0) => {
    return (
      <div
        onClick={() => navigate(`/pet-diary-view?memberId=${memberId}&diaryDate=${dayjs(currentDate).format('YYYY-MM-DD')}&petDiaryId=${item.petDiaryId}`)}
        className="mt-[20px] flex flex-col justify-center items-center bg-lightBlue2 rounded-[6px] w-full py-[12px]  px-[17px]"
      >
        {item.pet?.urlPicture ? (
          <img
            className="x-auto rounded-full h-[50px] w-[50px] bg-blueGray flex justify-center items-center object-cover"
            src={
              item.pet?.urlPicture.startsWith("data:image/png;base64")
                ? item.pet?.urlPicture
                : `data:image/png;base64,${item.pet?.urlPicture}`
            }
            alt=""
          />
        ) : (
          <div className="mx-auto rounded-full h-[50px] w-[50px] bg-blueGray flex justify-center items-center object-cover">
            <img className="w-[50px] h-[50px]" src={petFace} alt="" />
          </div>
        )}
        <p className="text-[20px] text-center  mb-[4px]">{petName}</p>
        <button
          className="w-full flex gap-[5px] items-center justify-center py-[2px] rounded-[28px] border-[1px] border-lightBlue"
        >
          <p className="text-lightBlue text-[14px]">{countAct} Activity</p>
        </button>
      </div>
    );
  };


  const selectDCalendar = (event) => {
    setCurrentDate(event.dateIso)
  }
  return (
    <div id="PagePetDiary">
      <div id="pet-diary-page">
        <div>
          <TopLogo/>
          <TopBanner type="petDiary" />
          <div id="pet-diary-page" className="bgPrimaryGD mt-[-20px] px-[20px] pb-[36px]" style={{ background: 'linear-gradient(152.04deg, #00693E -0.97%, #00B960 81.57%)'}}>
            <div className="bg-white pt-[7px] rounded-[4px]">
              <PetDailyCalendar value={currentDate} onSelect={selectDCalendar}/>
            </div>
            <div className="flex justify-between bg-blue mb-[12px] py-[9px] px-[17px]">
              <p className="text-[20px] text-white leading-[0.9] font-light">
                คุณมีบันทึกสุขภาพประจำวันในวันนี้
              </p>
              <p className="text-[20px] text-white leading-[0.9] font-light">
                <span className="font-normal">{actDetail.length}</span> รายการ
              </p>
            </div>
            <div className="bg-white pt-[12px] rounded-[4px] mb-[19px]">
              <div className="flex justify-between items-center">
                <p className="text-lightBlue text-[28px] text-left px-[17px]">
                  บันทึกสุขภาพ
                </p>
                <p className="text text-[20px] text-darkGray text-left px-[17px]">{dayjs(currentDate).format('DD/MM/YYYY')}</p>
              </div>
              <div className="px-[17px]">
                { actDetail && actDetail.length 
                  ? 
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={10}
                      freeMode={true}
                      modules={[FreeMode]}
                      className="mySwiper"
                    >
                      { 
                        actDetail.map((item, i) => (
                          <SwiperSlide key={i}>
                            {ItemActivity(item, item.pet.petName, item.petDiaryActivityCount)}
                          </SwiperSlide>
                        ))
                      }
                    </Swiper>
                  : <div className="w-full min-h-[155px] mb-[40px] flex items-center">
                      <p className="w-full text-center">ไม่พบข้อมูลบันทึกสุขภาพ</p>
                    </div>
                }
              </div>
              <div className="pt-[0px] pb-[25px] px-[10%]">
                <button
                  onClick={() => navigate(`/pet-diary-form?diaryDate=${currentDate}${qPetId ? `&petId=${qPetId}` : '' }`)}
                  className="flex justify-center items-center bg-blue rounded-[28px] w-full gap-[10px] py-[8px] px-[20px]"
                >
                  <img className="w-[14px] " src={editWhite} alt="" />
                  <p className="text-[24px] text-white text-center">
                    เขียนบันทึกสุขภาพประจำวัน
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
