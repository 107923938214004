import {createSlice} from '@reduxjs/toolkit'

const defaultData = {
  memberId: null,
  points: 0,
  payload: {}
}

export const QuestionnaireSlice = createSlice({
  name: 'Questionnaire',
  initialState: defaultData,
  reducers: {
    setQuestionnaire: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        console.log(`${key}: ${value}`);
        state[key] = value;
      }
    },
    resetQuestionnaire: () => defaultData
  },
})

// Action creators are generated for each case reducer function
export const {
  setQuestionnaire,
  resetQuestionnaire
} = QuestionnaireSlice.actions

export default QuestionnaireSlice.reducer