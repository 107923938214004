import { Dialog } from "@mui/material";
import React from "react";
import PrimaryButton from "../button/PrimaryButton";
import SecondaryButton from "../button/SecondaryButton";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";

export default function CustomPopupQr(props) {
    const {
        onClose,
        selectedValue,
        open,
        // confirmType = true,
    } = props;

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        onClose(selectedValue);
    };

    const { register, formState: { errors }, handleSubmit, setValue, control, watch } = useForm({
        shouldFocusError: false,
        shouldUnregister: true,
        // defaultValues: {
        //     qrCode: "xxx",
        // }
    });

    const onSubmit = (data) => {
        props.func(data)
    }

    return (

        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
            <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                <div className="pb-[36px] pt-[24px] px-[23px] rounded-[4px]">
                    {props.title && <p className="text-lightBlue text-[28px] text-center">{props.title}</p>}
                    <p className="bText-gray text-[18px] text-center whitespace-pre-line">{props.desc}</p>

                    <div className="flex mb-[50px]">
                        <TextField
                            required
                            fullWidth
                            label="QR Code"
                            variant="standard"
                            error={!!errors?.qrCode}
                            helperText={errors?.qrCode?.message}
                            {...register("qrCode", { required: "กรุณากรอก QR Code" })}
                        />
                    </div>

                    <div className="flex itesm-center gap-[13px] justify-center mt-[24px]">
                        {props.cancelFunction && (
                            <SecondaryButton
                                variant="outlined"
                                className={props.actionFunction ? 'w-full' : 'w-1/2'}
                                onClick={() => props.cancelFunction()}
                            >
                                {props.cancelText ? props.cancelText : "ยกเลิก"}
                            </SecondaryButton>
                        )}
                        {props.actionFunction && (
                            <PrimaryButton
                                // onClick={() => props.actionFunction()}
                                type="submit"
                                variant="outlined"
                                className="w-full"
                            >
                                {props.actionText ? props.actionText : "ยืนยัน"}
                            </PrimaryButton>
                        )}
                    </div>
                </div>
            </form>
        </Dialog>
    );
}
