import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import liff from "@line/liff"

export default function LiffBasic() {
  const [searchParams] = useSearchParams();
  const [ lineId, setLineId] = useState(null)
  const qWeb = searchParams.get('web')

  const getLineLiff = async () => {
    await liff.init({ liffId: '1656777670-9wXJ3po7' })
    if (!liff.isLoggedIn()){
      const destinationUrl = window.location.href
      liff.login({ redirectUri: destinationUrl })
    }

    const _lineId = liff.getDecodedIDToken().sub
    window.localStorage.removeItem('sub')
    window.localStorage.setItem('sub', _lineId)
    setLineId(_lineId)
  }

  useEffect(() => {
    getLineLiff()
  }, [])

  useEffect(() => {
    const reUrl = qWeb
  }, [lineId])

  return (
    <div>Query... {qWeb}</div>
  )
  
}
