/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useState } from "react";
import PrimaryButton from "../../components/button/PrimaryButton";
import { TextField } from "@mui/material";
import TopLogo from '../../components/layout/TopLogo';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../features/LoadingSlice';
import { VerifyToken } from "../../services/NotAuth";

export default function QuestionnaireSuccess(props) {
    const [ text, setText] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    
    function fnSetLoading(value) {
        let tmpObj = {};
        tmpObj.loading = value;
        dispatch(setLoading(tmpObj));
    }
    async function gotoDashBoard() {
        const payload = { token: text }
        fnSetLoading(true)
        try {
          const { data } = await VerifyToken({ data: payload });
          console.log("resData.data >>", data);
          if (data.resultCode === 20000) {
            console.log("resData.data >>", data.body.link);
            window.location.replace(data.body.link)
          }
          fnSetLoading(false)
        } catch (error) {
          console.log("create pet error", error);
          fnSetLoading(false)
        }
    }

    return (
        <>
            {/* <TopLogo/> */}
            <div
                className="flex flex-col bg-white"
                style={{
                // background:
                //     "linear-gradient(152.04deg, #00693E -0.97%, #00B960 81.57%)",
                // minHeight: "calc(100vh - 60px)",
                height: "100vh",
                padding: "20px",
                paddingBottom: "36px"
                }}
            >
                <div className="flex-1 flex flex-col justify-center">
                {/* <img className="mx-auto mt-[18px]"  src={Checkmark}alt="logo"style={{maxWidth: "52px"}}/> */}
                <div className="text-gr text-center text-[36px] mt-[10px] leading-[100%] mb-[20px]">
                    Enter Token From Email
                </div>
                <div className="bg-white rounded-[4px]">
                    <TextField fullWidth label="" id="fullWidth" value={text} onChange={(e) =>{setText(e.target.value)}}  />
                </div>
                <div className="mt-[28px]">
                    <PrimaryButton variant="contained" fullWidth onClick={gotoDashBoard} disabled={!text && false}>
                        Verify
                    </PrimaryButton>
                    </div>
                </div>
                
            </div>
        </>
  );
}
