import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../functions/getScreen";
import getTime from "../../functions/getTime";

//dogbook
import { SearchTierConfig } from "../../services/Dropdown";
import dayjs from "dayjs";
import helper from "../../functions/helper";

//member
// import welcomeMember from "../../assets/images/member-card/member/welcome.png";
// import blackMember from "../../assets/images/member-card/member/black.png";
// import ultimateMember from "../../assets/images/member-card/member/ultimate.png";

const bronzeDog = helper.PUBLICPATH("/assets/img/ci/member-card/dogbook/bronze.png")
const silverDog = helper.PUBLICPATH("/assets/img/ci/member-card/dogbook/silver.png")
const goldDog = helper.PUBLICPATH("/assets/img/ci/member-card/dogbook/gold.png")


const initalConfigTier = [
  {
    name: 'BRONZE',
    minPoint: 0,
    Maxpoint: 900,
    nextTierName: 'SILVER'
  },
  {
    name: 'SILVER',
    minPoint: 901,
    Maxpoint: 1200,
    nextTierName: 'GOLD'
  },
  {
    name: 'GOLD',
    minPoint: 1200,
    Maxpoint: true,
    nextTierName: 'GOLD'
  },
]

export default function MemberCard(props) {
  const { width } = useWindowDimensions();
  const [configTier, setConfigTier] = useState(initalConfigTier)

  useEffect(() => {
    getSearchTierConfig();
    // eslint-disable-next-line
  }, []);

  async function getSearchTierConfig() {
    return await SearchTierConfig()
      .then(async (response) => {
        setConfigTier(response.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //   const memberCardImage = () => {
  //     if (props.data) {
  //       if (props.data.tier === "WELCOME") return bronzeDog;
  //       else if (props.data.tier === "BLACK") return silverDog;
  //       else if (props.data.tier === "ULTIMATE") return goldDog;
  //       return null;
  //     }
  //   };

  const dogbookCardImage = (Tier) => {
    // const Tier = pointToTier(props.data?.tierTotalPoints ?? 0)
    // const Tier = pointToTier(-1)
    console.log('Tier >>', Tier)
    if (Tier) {
      if (Tier === "BRONZE") return bronzeDog;
      else if (Tier === "SILVER") return silverDog;
      else if (Tier === "GOLD") return goldDog;
    }
    return bronzeDog;
  };

  const pointToTier = (point, _configTier = []) => {
    console.log('point >>', point)
    const TIER = _configTier.find(item => (point >= item.minPoint && point <= item.Maxpoint ) || ((point >= item.minPoint && item.Maxpoint === true)))
    if(TIER) return TIER
    if(_configTier.length) return _configTier[0]
    return null
  }

  console.log('props.data >>', props.data)
  const tierPoint =  props.data?.tierTotalPoints ?? 0 

  const TierName = props?.data?.tier ?? 'BRONZE'
  console.log('configTier >>', configTier)
  let Tier = pointToTier(props.data?.tierTotalPoints ?? 0, configTier)
  if (TierName === 'GOLD'){
    Tier = configTier.find(item => item.name === 'GOLD')
  }
  console.log('Tier >>', Tier)
  const nextTierPoint = Tier?.Maxpoint ?? null
  const nextTierName = Tier?.nextTierName ?? null
  return (
    <>
      {props.data && (
        <>
          <div className="relative">
            <img className="w-full mt-[-20px] sh-card-member" src={dogbookCardImage(TierName)} alt="" />
            <span
                className="text-white absolute"
                style={{
                    bottom: '10%',
                    left: '7%',
                }}
            >
                <p style={{ fontSize: 'min(20px, 4vw)'}}>{`${props.data.firstName} ${props.data.lastName}`}</p>
            </span>

            <span
                className="text-white absolute"
                style={{
                    bottom: '10%',
                    left: '58.5%',
                }}
            >
                <p style={{ fontSize: 'min(20px, 4vw)'}}>{getTime.getTimeString(props.data.createdDate)}</p>
            </span>
          </div>
          { 
            props.showTierBar && (
            <div className="pt-[16px]">
              <div className="flex justify-between items-center">
                <p className="text-[24px] text-white leading-[100%]">NexCoin สะสม</p>
                <div className="flex items-center gap-[10px]">
                  <p className="text-[36px] text-white leading-[100%]">{tierPoint}</p>
                  <p className="text-[24px] text-white leading-[100%]">คะแนน</p>
                </div>
              </div>
              {
                Tier.Maxpoint === true
                ? (
                  <div className="flex justify-between mb-[4px] items-start mt-[-10px]">
                    <p className="text-[16px] text-white leading-[100%] mt-[14px]">สะสม NexCoin อีก</p>
                    <div className="flex items-start gap-[10px]">
                      <p className="text-[36px] text-white leading-[100%]">{Tier.minPoint - tierPoint}</p>
                      <p className="text-[16px] text-white leading-[100%] mt-[14px]">เพื่อรักษาระดับ {helper.capitalizeFirstLetter(TierName)}</p>
                    </div>
                  </div>
                ) 
                :(
                  <div className="flex justify-between mb-[4px] items-start mt-[-10px]">
                    <p className="text-[16px] text-white leading-[100%] mt-[14px]">สะสม NexCoin อีก</p>
                    <div className="flex items-start gap-[10px]">
                      <p className="text-[36px] text-white leading-[100%]">{nextTierPoint - tierPoint + 1}</p>
                      <p className="text-[16px] text-white leading-[100%] mt-[14px]">เพื่อเป็นสมาชิกระดับ {helper.capitalizeFirstLetter(nextTierName)}</p>
                    </div>
                  </div>
                )
              }
              {/* <h3 className="text-[24px] text-white leading-[120%] mb-[0px]">ยินดีต้อนรับ คุณ {`${props.data.firstName} ${props.data.lastName}`}</h3> */}
              <div className=" mb-[8px]">
                <TierProgress tierName={Tier.name} width={width}/>
              </div>
              {/* <div className="text-left">
                <p className="text-[16px] text-white leading-[120%] mt-[3px] mb-[16px]">คะแนนที่จะหมดอายุในวันที่ {props.data?.expire?.expireDate ? dayjs(props.data.expire.expireDate).format('DD/MM/YYYY'): '-'}</p>
              </div> */}
            </div>
          )
        }
        </>
      )}
    </>
  );
}

const TierProgress = (props) => {
  const { tierName, width = 1 } = props
      
  if(tierName === 'GOLD') {
    return (
      <div className="tier-progress">
        <div className="tier-box-gold">
          <div className="w-full flex justify-center items-center tier-filtter font-semibold">
            <p className="text-white" style={{fontSize: 'min(20px, 4vw)',}}>GOLD</p>
          </div>
        </div>
      </div>
    )
  }
  if(tierName === 'SILVER') {
    return (
      <div className="tier-progress">
        <div className="tier-box-silver">
          <div className="w-full flex justify-center items-center tier-filtter font-semibold">
            <p className="text-white" style={{fontSize: 'min(20px, 4vw)',}}>Sliver</p>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="tier-progress">
      <div className="tier-box">
        <div className="w-full flex justify-center items-center tier-filtter font-semibold">
          <p className="text-white" style={{fontSize: 'min(20px, 4vw)',}}>BRONZE</p>
        </div>
      </div>
    </div>
  )
}