import React from "react";
import Layouts from "./layout/Layout";
import { Route, Routes } from "react-router-dom";
import RegisterMain from "./pages/register/RegisterMain";
import DogBook from "./pages/dogbook/DogBookMain";
import Home from "./pages/Home";
import RegisterOwner from "./pages/register/RegisterOwner";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AddDog from "./pages/dogbook/AddDog";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditDog from "./pages/dogbook/EditDog";
import EditInfo from "./pages/dogbook/EditInfo";
import EditNotification from "./pages/dogbook/EditNotification";
import RegisterPetShop from "./pages/register/RegisterPetShop";
import RegisterSuccess from "./pages/register/RegisterSuccess";
import SpectraNearYouMain from "./pages/spectra-near-you/SpectraNearYouMain";
import SpectraNearYouList from "./pages/spectra-near-you/SpectraNearYouList";
import PetDaily from "./pages/petdaily/PetDiaryMain";
import PetDailyForm from "./pages/petdaily/PetDiaryForm";
import PetDailyView from "./pages/petdaily/PetDiaryView";
import SpectraNearYouCoupons from "./pages/spectra-near-you/SpectraNearYouCoupons";
import { useLiff } from "react-liff";
import { CircularProgress } from "@mui/joy";
import RegisterPetShopConfirm from './pages/register/RegisterPetShopConfirm';
import MyPointMain from './pages/my-point/MyPointMain';
import MyPointPhotoUpload from './pages/my-point/MyPointPhotoUpload';
import MemberCardOwnerMain from "./pages/member-card-owner/MemberCardOwnerMain"
import MemberCardOwnerEdit from "./pages/member-card-owner/MemberCardOwnerEdit"
import MemberCardOwnerEditEmployee from "./pages/member-card-owner/MemberCardOwnerEditEmployee"
import MyPointScan from './pages/my-point/MyPointScan';
import MyPointQrCode from "./pages/my-point/MyPointSuccess";
import MyPointHistory from "./pages/my-point/MyPointHistory";
import RedeemGift from './pages/redeem-gift-shop/RedeemGiftMain';
import GenerateQRCodeMain from './pages/generate-qr-code/GenerateQRCodeMain';
import WelcomeBackPopup from "./components/popup/WelcomeBackPopup";
import RedeemGiftDetail from './pages/redeem-gift-shop/RedeemGiftDetail';
import Questionnaire from './pages/questionnaire/QuestionnaireMain';
import QuestionnaireSuccess from './pages/questionnaire/QuestionnaireSuccess';
import QuestionnaireNotFound from './pages/questionnaire/QuestionnaireNotFound';
import RedeemGiftMember from './pages/redeem-gift-member/RedeemGiftMemberMain';
import RedeemGiftMemberDetail from './pages/redeem-gift-member/RedeemGiftMemberDetail';
import LoadingMain from './components/dialog/LoadingMain'
import CopyTag from './pages/i-frame/copy-tag';
import Game01 from './pages/auth-redirect/Game01';
import MyPointShop from "./pages/my-point-shop/MyPointShopMain";
import MyPointShopExport from "./pages/my-point-shop/MyPointShopExport";
import ArticlePoint from "./pages/auth-redirect/ArticlePoint";
import helper from "./functions/helper";
import InviteFriend from "./pages/dogbook/InviteFriend";
import InviteFriendConfirm from "./pages/dogbook/InviteFriendConfirm";
import InviteFriendSuccess from "./pages/dogbook/InviteFriendSuccess";

const theme = createTheme({
  typography: {
    fontFamily: `'DB Helvethaica X', 'Kanit', sans-serif`,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

function App() {
  const { isLoggedIn, liff  } = useLiff();

  window.localStorage.removeItem('sub')
  if (isLoggedIn) {
    const sub = liff.getDecodedIDToken().sub;
    window.localStorage.setItem('sub', sub)
  }

  return (
    <>
      {isLoggedIn && (
        <ThemeProvider theme={theme}>
          <Layouts>
            <WelcomeBackPopup /> {/* ทำทุกหน้า */}
            <LoadingMain/>
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/register" element={<RegisterMain />} />
              <Route path="/dogbook" element={<DogBook />} />
              <Route path="/dogbook-invite-friend" element={<InviteFriend/>} />
              <Route path="/dogbook-invite-friend-confrim" element={<InviteFriendConfirm/>} />
              <Route path="/dogbook-invite-friend-success" element={<InviteFriendSuccess/>} />
              <Route path="/dogbook-add" element={<AddDog />} />
              <Route path="/dogbook-edit/:petId" element={<EditDog />} />
              <Route path="/dogbook-edit-noti" element={<EditNotification />} />
              <Route path="/dogbook-edit-info" element={<EditInfo />} />
              <Route path="/register-owner" element={<RegisterOwner />} />
              <Route path="/register-pet-shop" element={<RegisterPetShop />} />
              <Route path="/register-pet-shop-confirm" element={<RegisterPetShopConfirm />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/register-success" element={<RegisterSuccess />} />
              <Route path="/pet-diary" element={<PetDaily />} />
              <Route path="/pet-diary-form" element={<PetDailyForm />} />
              <Route path="/pet-diary-view" element={<PetDailyView />} />
              <Route path="/my-point" element={<MyPointMain />} />
              <Route path="/my-point-scan" element={<MyPointScan />} />
              <Route path="/my-point-photo-upload" element={<MyPointPhotoUpload />} />
              <Route path="/my-point-history-owner" element={<MyPointHistory />} />
              <Route path="/generate-qr-code" element={<GenerateQRCodeMain />} />
              <Route
                path="/spectra-near-you-main"
                element={<SpectraNearYouMain />}
              />
              <Route
                path="/spectra-near-you-list"
                element={<SpectraNearYouList />}
              />
              <Route
                path="/spectra-near-you-coupons"
                element={<SpectraNearYouCoupons />}
              />
              <Route
                path="/member-card-owner"
                element={<MemberCardOwnerMain />}
              />
              <Route
                path="/member-card-owner-edit"
                element={<MemberCardOwnerEdit />}
              />
              <Route
                path="/member-card-owner-edit-employee"
                element={<MemberCardOwnerEditEmployee />}
              />
              <Route path="/redeem-gift" element={<RedeemGift />} />
              <Route path="/redeem-gift-detail/:freebiesId/:clinicPetShopId" element={<RedeemGiftDetail />} />
              {/* <Route path="/questionnaire" element={<Questionnaire />} /> */}
              <Route path="/questionnaire-success" element={<QuestionnaireSuccess />} />
              <Route path="/questionnaire-not-found" element={<QuestionnaireNotFound />} />
              <Route path="/redeem-gift-member" element={<RedeemGiftMember />} />
              <Route path="/redeem-gift-member-detail/:freebiesId/:memberId" element={<RedeemGiftMemberDetail />} />
              <Route path="/iframe/copy-tag" element={<CopyTag />} />
              <Route path="/authgame" element={<Game01 />} />
              <Route path="/my-point-shop" element={<MyPointShop />} />
              <Route path="/my-point-export" element={<MyPointShopExport />} />
              {/* <Route path="/article-point" element={<ArticlePoint />} /> */}
            </Routes>
          </Layouts>
        </ThemeProvider>
      )}
      {!isLoggedIn && (
        <div>
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default App;
