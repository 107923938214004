import React, { useEffect, useState } from "react";
import birthDay from "../../assets/images/rich-message-birth-day.png";
import meetDoctor from "../../assets/images/rich-message-meet-doctor.png";
import { useSearchParams } from "react-router-dom";
import liff from "@line/liff"

export default function Asset() {

  return (
    <div>
      <img className="" src={birthDay} alt="" />
      <img className="" src={meetDoctor} alt="" />
    </div>
  )
  
}
