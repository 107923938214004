// Html5QrcodePlugin.jsx

import { Html5QrcodeScanner, Html5Qrcode } from "html5-qrcode";
import React from 'react';

const qrcodeRegionId = "html5qr-code-full-region";
class Html5QrcodePlugin extends React.Component {
  render() {
    return <div id={qrcodeRegionId}/>;
  }

  componentWillUnmount() {
    // TODO(mebjas): See if there is a better way to handle
    //  promise in `componentWillUnmount`.
    this.html5QrcodeScanner.clear().catch(error => {
      console.error("Failed to clear html5QrcodeScanner. ", error);
    });
  }

  componentDidMount() {
    let qrboxFunction = function(viewfinderWidth, viewfinderHeight) {
      let minEdgePercentage = 0.8; // 70%
      let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
      let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
      return {
        width: qrboxSize,
        height: qrboxSize
      };
    }
    // Creates the configuration object for Html5QrcodeScanner.
    function createConfig(props) {
      var config = {};
      if (props.fps) {
        config.fps = props.fps;
      }
      if (props.qrbox) {
        config.qrbox = props.qrbox;
      }else {
        config.qrbox = qrboxFunction;
      }
      if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
      }
      if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
      }
      return config;
    }

    var config = createConfig(this.props);
    var verbose = this.props.verbose === true;

    // Suceess callback is required.
    if (!(this.props.qrCodeSuccessCallback )) {
      throw "qrCodeSuccessCallback is required callback.";
    }

    const html5QrCode = new Html5Qrcode(qrcodeRegionId);
    html5QrCode.start({ facingMode: "environment" }, config, this.props.qrCodeSuccessCallback);
  }
};

export default Html5QrcodePlugin;
