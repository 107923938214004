import React, { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PrimaryButton from "../../components/button/PrimaryButton";

export default function CopyTag() {
  const [searchParams] = useSearchParams();

  const qQuiz= searchParams.get('quiz')
  const aaa = window.location.href

  const [copySuccess, setCopySuccess] = useState('');
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!');
  };
  const quizId = `quizdog=${qQuiz}`
  const linkCopy = `https://staging-react-ngs.scapp.work/external?quiz=${btoa(quizId)}`
  // document.body.style.background = '#FFF'
  return (
    <div>
      {/* <a>https://staging-react-ngs.scapp.work/external?quiz=cXVpemRvZz0x</a> */}
      <div className="py-[8px] px-[8px]">
        {aaa}
        <form>
          <input className="rounded-[4px] px-[5px]" ref={textAreaRef} value={linkCopy} style={{ width: '100%' , border: '1px solid #393939'}} />
        </form>
        {
          document.queryCommandSupported('copy') && (
            <div className="flex justify-end px-[2px] items-center gap-[5px] mt-[8px] ">
              {copySuccess}
              <button className="bg-blue text-white px-[16px] rounded-[16px]" onClick={copyToClipboard}>Copy</button> 
            </div>
            )
        }
      </div>
    </div>
  )
}
