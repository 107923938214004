import React from "react";
import { useLiff } from "react-liff";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useNavigate, useLocation } from "react-router-dom";
import { Autocomplete } from '@mui/material';
import FormControlLabel from "@mui/material/FormControlLabel";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import TopLogo from '../../components/layout/TopLogo';
import ShopBanner from "../../assets/images/membercardowner/banner.png";
import lineBlue from "../../assets/images/icon/lineBlue.svg";
import PrimaryButton from "../../components/button/PrimaryButton";
import SecondaryButton from "../../components/button/SecondaryButton";
import CustomPopupShopMember from "../../components/popup/CustomPopupShopMember";
import { SearchDdlAddress } from "../../services/Dropdown";
import {
    PostUpdateShopProfile
} from "../../services/MemberCardOwner";
import TopBanner from "../../components/layout/TopBanner";

export default function MemberCardOwnerEdit() {
    const clinicPetShop = useLocation()?.state?.clinicPetShop;
    const employee = useLocation()?.state?.employee;

    const txt = {
        require: 'กรุณากรอก',
        province: 'จังหวัด',
        amphoe: 'เขต/อำเภอ',
        tambol: 'แขวง/ตำบล',
        zipcode: 'รหัสไปรษณีย์',
    }

    const [delayId, setDelayId] = React.useState(null);
    const [openCancelPopup, setOpenCancelPopup] = React.useState(false);
    const [openEditedPopup, setOpenEditedPopup] = React.useState(false);

    const [deAddressList, setDeAddressList] = React.useState([]);

    const [deShippingAddressId, setDeShippingAddressId] = React.useState(null);
    const [deShippingTambol, setDeShippingTambol] = React.useState(null);
    const [deShippingAmphoe, setDeShippingAmphoe] = React.useState(null);
    const [deShippingProvince, setDeShippingProvince] = React.useState(null);
    const [deShippingZipcode, setDeShippingZipcode] = React.useState(null);

    const [openDeShippingTambol, setOpenDeShippingTambol] = React.useState(false);
    const [openDeShippingAmphoe, setOpenDeShippingAmphoe] = React.useState(false);
    const [openDeShippingProvince, setOpenDeShippingProvince] =
        React.useState(false);
    const [openDeShippingZipcode, setOpenDeShippingZipcode] =
        React.useState(false);

    const navigate = useNavigate();

    const { register, formState: { errors }, handleSubmit, setValue, control, watch } = useForm({
        shouldFocusError: false,
        shouldUnregister: true,
        defaultValues: {
            firstName: employee.firstName,
            lastName: employee.lastName,
            email: employee.email,
            mobileNumber: employee.mobileNumber,
            deAddressDetail: clinicPetShop.deAddressDetail,
        }
    });

    React.useEffect(() => {
        getInitialValueForAutocomplete();
    }, []);

    function getInitialValueForAutocomplete() {
        setDeShippingTambol(clinicPetShop.deAddress);
        setValue("deShippingTambol", clinicPetShop.deAddress.subDistrict, {
            shouldValidate: true,
        });
        setDeShippingAmphoe(clinicPetShop.deAddress);
        setValue("deShippingAmphoe", clinicPetShop.deAddress.amphoe, {
            shouldValidate: true,
        });
        setDeShippingProvince(clinicPetShop.deAddress);
        setValue("deShippingProvince", clinicPetShop.deAddress.province, {
            shouldValidate: true,
        });
        setDeShippingZipcode(clinicPetShop.deAddress);
        setValue("deShippingZipcode", clinicPetShop.deAddress.zipcode, {
            shouldValidate: true,
        });
    }

    async function getSearchDdlAddress(search) {
        try {
            const { data } = await SearchDdlAddress({ params: { search } })
            if (+data['resultCode'] === 20000) {
                const body = data.body || [];
                console.log('body', body);
                if (Object.keys(body).length === 0) {
                    setDeAddressList([]);
                } else {
                    setDeAddressList(body);
                }
            } else {
                setDeAddressList([]);
            }
        } catch (error) {
            console.log('getSearchDdlAddress error', error)
        }
    }

    async function onInputChangeDeAddress(e, type) {
        setDeShippingAddressId(null);
        fnSetOpenDeAddress(type, false);
        const value = e.target.value;
        if (type === txt.tambol) {
            setValue("deShippingTambol", value, { shouldValidate: true });
        } else if (type === txt.amphoe) {
            setValue("deShippingAmphoe", value, { shouldValidate: true });
        } else if (type === txt.province) {
            setValue("deShippingProvince", value, { shouldValidate: true });
        } else if (type === txt.zipcode) {
            setValue("deShippingZipcode", value, { shouldValidate: true });
        }
        clearTimeout(delayId);
        const timeoutId = setTimeout(async () => {
            if (value) {
                await getSearchDdlAddress(value);
                fnSetOpenDeAddress(type, true);
            } else {
                setDeAddressList([]);
            }
        }, 500);
        setDelayId(timeoutId);
    }

    function fnSetOpenDeAddress(type, open) {
        if (type === txt.tambol) {
            setOpenDeShippingTambol(open);
        } else if (type === txt.amphoe) {
            setOpenDeShippingAmphoe(open);
        } else if (type === txt.province) {
            setOpenDeShippingProvince(open);
        } else if (type === txt.zipcode) {
            setOpenDeShippingZipcode(open);
        }
    }

    function fnOnChangeDeAddress(e, value) {
        setDeShippingAddressId(value.id);
        fnSetOpenDeAddress(txt.tambol, false);
        fnSetOpenDeAddress(txt.amphoe, false);
        fnSetOpenDeAddress(txt.province, false);
        fnSetOpenDeAddress(txt.zipcode, false);
        setDeShippingTambol(value);
        setValue("deShippingTambol", value.subDistrict, { shouldValidate: true });
        setDeShippingAmphoe(value);
        setValue("deShippingAmphoe", value.amphoe, { shouldValidate: true });
        setDeShippingProvince(value);
        setValue("deShippingProvince", value.province, { shouldValidate: true });
        setDeShippingZipcode(value);
        setValue("deShippingZipcode", value.zipcode, { shouldValidate: true });
    }

    function fnRenderOption(props, option, { inputValue }) {
        const address1 = `${option.subDistrict}, ${option.amphoe}, ${option.zipcode}`;
        const address2 = `จังหวัด ${option.province}`;

        const matches1 = match(address1, inputValue, { insideWords: true });
        const parts1 = parse(address1, matches1);

        const matches2 = match(address2, inputValue, { insideWords: true });
        const parts2 = parse(address2, matches2);

        return (
            <li {...props} className="text-left pl-1 border-address-list">
                {/*display address*/}
                <div>
                    {parts1.map((part, index) => (
                        <span
                            key={index}
                            style={{
                                fontWeight: part.highlight ? 700 : 400,
                                color: part.highlight ? "#00693E" : "#8F8F8F",
                            }}
                        >
                            {part.text}
                        </span>
                    ))}
                </div>
                {/*display province*/}
                <div>
                    {parts2.map((part, index) => (
                        <span
                            key={index}
                            style={{
                                fontWeight: part.highlight ? 700 : 400,
                                color: part.highlight ? "#00693E" : "#8F8F8F",
                            }}
                        >
                            {part.text}
                        </span>
                    ))}
                </div>
            </li>
        );
    }

    const onSubmit = async (data) => {
        const myPayload = {
            clinicPetShop: {
                clinicPetShopId: clinicPetShop.clinicPetShopId,
                deAddress: deShippingAddressId,
                deAddressDetail: data.deAddressDetail,
            },
            employee: {
                employeeId: parseInt(employee.employeeId),
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                mobileNumber: data.mobileNumber,
            }
        };
        console.log("myPayload", myPayload);
        try {
            const { data } = await PostUpdateShopProfile({ data: myPayload });
            console.log("resData.data >>", data);
            if (data.resultCode === 20000) {
                setOpenEditedPopup(true);
            }
        } catch (error) {
            console.log("PostUpdateShopProfile error", error);
        }
        // fnSetData('isPetShop', false);
        // navigate("/privacy-policy", { state: data });
    };

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="PageShopEdit">
            <div id="shop-edit-page">
                <TopLogo />
                <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <CustomPopupShopMember
                        title={"บันทึกการแก้ไขเรียบร้อย"}
                        desc={"ข้อมูลผู้ใช้ที่คุณแก้ไขได้รับการบันทึกแล้ว"}
                        cancelText={"ปิด"}
                        cancelFunction={() => {
                            window.open("/member-card-owner", "_self");
                            setOpenEditedPopup(false);
                        }}
                        open={openEditedPopup}
                        onClose={() => {
                            window.open("/member-card-owner", "_self");
                            setOpenEditedPopup(false);
                        }}
                    />

                    <CustomPopupShopMember
                        title={"ยกเลิกการแก้ไขข้อมูล"}
                        desc={
                            "ข้อมูลผู้ใช้ที่คุณเปลี่ยนแปลงจะยังไม่ถูกบันทึก หากมั่นใจแล้วกรุณากด ยืนยัน"
                        }
                        cancelFunction={() => {
                            setOpenCancelPopup(false);
                        }}
                        actionFunction={() => {
                            navigate(-1);
                            setOpenCancelPopup(false);
                        }}
                        open={openCancelPopup}
                        onClose={() => {
                            setOpenCancelPopup(false);
                        }}
                    />
                    <TopBanner type="memberCardOwner" />
                    {/* <TopBanner/> */}
                    <div id="edit" className="relative mt-[-20px] px-[20px]">

                        <div className="bg-white py-[24px] rounded-[4px] mb-[36px] px-[17px]">
                            <p className="text-lightBlue text-[28px] text-left">
                                แก้ไขข้อมูลของฉัน
                            </p>
                            <div className="flex flex-col mt-[16px] gap-[16px]">
                                <div className="flex gap-[15px]">
                                    <TextField
                                        required
                                        fullWidth
                                        label="ชื่อ"
                                        variant="standard"
                                        error={!!errors?.firstName}
                                        helperText={errors?.firstName?.message}
                                        {...register("firstName", { required: "กรุณากรอกชื่อ" })}
                                    />

                                    <TextField
                                        required
                                        fullWidth
                                        label="นามสกุล"
                                        variant="standard"
                                        error={!!errors?.lastName}
                                        helperText={errors?.lastName?.message}
                                        {...register("lastName", { required: "กรุณากรอกนามสกุล" })}
                                    />
                                </div>
                                <TextField
                                    fullWidth
                                    label="อีเมล"
                                    variant="standard"
                                    error={!!errors?.email}
                                    helperText={errors?.email?.message}
                                    {...register("email")}
                                />
                                <TextField
                                    required
                                    fullWidth
                                    label="เบอร์โทรศัพท์"
                                    variant="standard"
                                    error={!!errors?.mobileNumber}
                                    helperText={errors?.mobileNumber?.message}
                                    inputProps={{ maxLength: 10 }}
                                    {...register("mobileNumber", {
                                        required: "กรุณากรอกเบอร์โทรศัพท์",
                                        minLength: {
                                            value: 10,
                                            message: "กรุณากรอกเบอร์โทรศัพท์อย่างน้อย 10 หลัก",
                                        },
                                    })}
                                />
                            </div>
                            <p className="text-lightBlue text-[28px] text-left mt-[32px]">
                                แก้ไขข้อมูลโรงพยาบาลสัตว์ คลินิก และร้านเพ็ทช็อป
                            </p>
                            <p className="text-black text-[23px] text-left">
                                ต้องการแก้ไขข้อมูลร้านค้าใช่หรือไม่? ติดต่อ
                            </p>
                            <a className="bText-primary-d text-[23px] text-left font-semibold"
                                href="https://line.me/ti/p/@NextComboCat"
                                target="_blank">
                                <img src={lineBlue} alt="lineBlueIcon" className="inline-block" /> @NextComboCat
                            </a>
                            <p className="text-lightBlue text-[28px] text-left mt-[32px]">
                                เปลี่ยนข้อมูลที่อยู่จัดส่ง
                            </p>

                            <div className="flex flex-col mt-[16px] gap-[16px]">
                                <TextField
                                    required
                                    fullWidth
                                    label="รายละเอียดที่อยู่"
                                    variant="standard"
                                    error={!!errors?.deAddressDetail}
                                    helperText={errors?.deAddressDetail?.message}
                                    {...register("deAddressDetail", { required: "กรุณากรอกรายละเอียดที่อยู่" })}
                                />
                                <Controller
                                    name="deShippingTambol"
                                    control={control}
                                    rules={{
                                        required: `${txt.require}${txt.tambol}`
                                    }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            freeSolo
                                            disableClearable
                                            onChange={fnOnChangeDeAddress}
                                            options={deAddressList}
                                            open={openDeShippingTambol}
                                            onClose={() => fnSetOpenDeAddress(txt.tambol, false)}
                                            getOptionLabel={(option) => option.subDistrict}
                                            value={deShippingTambol}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    fullWidth
                                                    label={txt.tambol}
                                                    variant="standard"
                                                    error={!!errors?.deShippingTambol}
                                                    helperText={errors?.deShippingTambol?.message}
                                                    onInput={(e) => onInputChangeDeAddress(e, txt.tambol)}
                                                />
                                            )}
                                            renderOption={fnRenderOption}
                                        />
                                    )}
                                />
                                <Controller
                                    name="deShippingAmphoe"
                                    control={control}
                                    rules={{
                                        required: `${txt.require}${txt.amphoe}`
                                    }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            freeSolo
                                            disableClearable
                                            onChange={fnOnChangeDeAddress}
                                            options={deAddressList}
                                            open={openDeShippingAmphoe}
                                            onClose={() => fnSetOpenDeAddress(txt.amphoe, false)}
                                            getOptionLabel={(option) => option.amphoe}
                                            value={deShippingAmphoe}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    fullWidth
                                                    label={txt.amphoe}
                                                    variant="standard"
                                                    error={!!errors?.deShippingAmphoe}
                                                    helperText={errors?.deShippingAmphoe?.message}
                                                    onInput={(e) => onInputChangeDeAddress(e, txt.amphoe)}
                                                />
                                            )}
                                            renderOption={fnRenderOption}
                                        />
                                    )}
                                />
                                <Controller
                                    name="deShippingProvince"
                                    control={control}
                                    rules={{
                                        required: `${txt.require}${txt.province}`
                                    }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            freeSolo
                                            disableClearable
                                            onChange={fnOnChangeDeAddress}
                                            options={deAddressList}
                                            open={openDeShippingProvince}
                                            onClose={() => fnSetOpenDeAddress(txt.province, false)}
                                            getOptionLabel={(option) => option.province}
                                            value={deShippingProvince}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    fullWidth
                                                    label={txt.province}
                                                    variant="standard"
                                                    error={!!errors?.deShippingProvince}
                                                    helperText={errors?.deShippingProvince?.message}
                                                    onInput={(e) => onInputChangeDeAddress(e, txt.province)}
                                                />
                                            )}
                                            renderOption={fnRenderOption}
                                        />
                                    )}
                                />
                                <Controller
                                    name="deShippingZipcode"
                                    control={control}
                                    rules={{
                                        required: `${txt.require}${txt.zipcode}`
                                    }}
                                    render={({ field }) => (
                                        <Autocomplete
                                            {...field}
                                            freeSolo
                                            disableClearable
                                            onChange={fnOnChangeDeAddress}
                                            options={deAddressList}
                                            open={openDeShippingZipcode}
                                            onClose={() => fnSetOpenDeAddress(txt.zipcode, false)}
                                            getOptionLabel={(option) => option.zipcode}
                                            value={deShippingZipcode}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    fullWidth
                                                    label={txt.zipcode}
                                                    variant="standard"
                                                    error={!!errors?.deShippingZipcode}
                                                    helperText={errors?.deShippingZipcode?.message}
                                                    onInput={(e) => onInputChangeDeAddress(e, txt.zipcode)}
                                                />
                                            )}
                                            renderOption={fnRenderOption}
                                        />
                                    )}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="flex itesm-center gap-[13px] justify-center bg-white px-[20px] pt-[16px] pb-[24px]">
                        <SecondaryButton
                            variant="outlined"
                            className="w-full"
                            onClick={() => setOpenCancelPopup(true)}
                        >
                            ยกเลิก
                        </SecondaryButton>
                        <PrimaryButton
                            variant="outlined"
                            className="w-full"
                            type="submit"
                        >
                            บันทึก
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    )
}