// import DogbookBanner from "../../assets/images/dogbook/banner.png";
// import MyPointBanner from "../../assets/images/my-point/banner.png";
// import MyPointShopBanner from "../../assets/images/my-point-shop/banner.png";
import redeemGiftBanner from "../../assets/images/redeem-gift/banner.png";
// import redeemGiftMemberBanner from "../../assets/images/redeem-gift/banner-member.png";
// import quiz from "../../assets/images/quiz3.png";
// import inviteFriend from "../../assets/images/topbanner/invite-friend.png";
import React from "react";

const HOST = window.location.origin
const URL_PATH = HOST + "/assets/img/ci/components/top-banner"

const Banner = URL_PATH + "/img-register.png"
const MemberCardOwner = URL_PATH + "/img-shop.png"
const DogbookBanner = URL_PATH + "/img-dogbook.png"
const inviteFriend = URL_PATH + "/img-invite-friend.svg";
const MyPointBanner = URL_PATH + "/img-my-point.png";
const redeemGiftMemberBanner = URL_PATH + "/img-redeem-gift.png";
const MyPointShopBanner = URL_PATH + "/img-my-point-shop.png";
const quizBanner = URL_PATH + "/img-questionnaire.png";
const patDiaryBanner = URL_PATH + "/img-pet-diary.png";


export default function TopBanner({ type }) {
  let bannerImage;
  switch (type) {
    case "dogbook":
      bannerImage = DogbookBanner;
      break;
    case "myPoint":
      bannerImage = MyPointBanner;
      break;
    case "memberCardOwner":
      bannerImage = MemberCardOwner;
      break;
    case "redeemGift":
      bannerImage = redeemGiftBanner;
      break;
    case "redeemGiftMember":
      bannerImage = redeemGiftMemberBanner;
      break;
    case "myPointShop":
      bannerImage = MyPointShopBanner;
      break;
    case "quiz":
      bannerImage = quizBanner;
      break;
    case "inviteFriend":
      bannerImage = inviteFriend;
      break;
    case "petDiary":
      bannerImage = patDiaryBanner;
      break;
    default:
      bannerImage = Banner;
  }
  return (
    <div className="bTopBanner">
      <img className="imgBanner" src={bannerImage} alt="logo" />
    </div>
  );
}
