/* eslint-disable react/jsx-no-undef */
import React, { useState } from "react";
import PrimaryButton from "../components/button/PrimaryButton";
import SecondaryButton from "../components/button/SecondaryButton";
import ConditionDialog from "../components/dialog/ConditionDialog";
import Policy from "../components/text/Policy";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../features/LoadingSlice";
import { useLiff } from "react-liff";
import {
  CreateOwnerRegister,
  CreatePetOwnerRegister,
} from "../services/Register";
import { CreateUserAnswer } from "../services/Questionnaire";

export default function PrivacyPolicy() {
  const store = useSelector((state) => state.registerPetShop);
  const dispatch = useDispatch();
  const storeQuiz = useSelector((state) => state.questionnaire);
  const [isLoading, setLoading] = useState(false);

  // lineId
  const { liff } = useLiff();
  const lineId = liff.getDecodedIDToken().sub;

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const { state } = useLocation();
  const { width } = windowDimensions;
  console.log(state);
  const navigate = useNavigate();
  const [openPolicyDialog, setOpenPolicyDialog] = React.useState(false);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  React.useEffect(() => {
    console.log("width", width);
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });
  const togglePolicyDialog = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenPolicyDialog(open);
  };

  async function onOk(e) {
    console.log("on ok", e);
    // setOpenPolicyDialog(false);
    if (store["isPetShop"]) {
      await postCreateOwnerRegister(true);
    } else {
      await postCreatePetOwnerRegister(true);
    }
  }

  async function onCancel(e) {
    console.log("on cancel", e);
    // setOpenPolicyDialog(false);
    if (store["isPetShop"]) {
      await postCreateOwnerRegister(false);
    } else {
      await postCreatePetOwnerRegister(false);
    }
  }

  function onClickLinkPolicy() {
    setOpenPolicyDialog(true);
  }

  function fnSetLoading(value) {
    let tmpObj = {};
    tmpObj.loading = value;
    // dispatch(setLoading(tmpObj));
  }

  async function postCreatePetOwnerRegister(isTerm) {
    const payload = {
      firstName: store["firstName"],
      lastName: store["lastName"],
      favoriteProductId: +store["favoriteProductId"],
      mobileNumber: store["mobileNumber"],
      isHaveCat: store["isHaveCat"] === "yes",
      recommenderId: state["recommenderId"],
      lineId: lineId,
      cookie: true,
      isTerm: isTerm,
    };
    console.log("payload", payload);
    // console.log("storeQuiz['payload'] >>", storeQuiz['payload'])
    fnSetLoading(true);
    setLoading(true);
    try {
      const { data } = await CreatePetOwnerRegister({ data: payload });
      console.log("resData.data >>", data);
      if (+data["resultCode"] === 20000) {
        console.log("resData.data >>", data.body);

        // ยิง Questionnaire เพิ่ม Flow ตอบคำถามก่อนเเร้วค่อยสมัครสมาชิก
        // await postCreateUserAnswer({
        //   ...storeQuiz["payload"],
        //   memberId: data.body.memberId,
        // });

        navigate("/register-success", {
          state: { point: data.body.currentPoint },
        });
      }
      setLoading(false);
      fnSetLoading(false);
    } catch (error) {
      setLoading(false);
      fnSetLoading(false);
      console.log("createPetOwnerRegister error", error);
    }
  }

  async function postCreateOwnerRegister(isTerm) {
    const payload = {
      firstName: store["firstName"],
      lastName: store["lastName"],
      isOwner: store["isOwner"] === "yes",
      clinicPetShop: {
        clinicPetShopId: +store["clinicPetShopData"]["clinicPetShopId"],
        isDeliveryAddress: store["isDeliveryAddress"],
        deAddress: +store["deAddressId"],
        deAddressDetail: store["deAddressDetail"],
      },
      lineId: lineId,
      cookie: true,
      isTerm: isTerm,
    };
    if (payload.isOwner) {
      payload.mobileNumber = store["mobileNumber"];
      payload.email = store["email"];
    } else {
      payload.clinicPetShop.isDeliveryAddress = true;
    }
    if (!payload.clinicPetShop.isDeliveryAddress) {
      payload.clinicPetShop.deAddress = store["shippingZipcode"]["id"];
      payload.clinicPetShop.deAddressDetail = store["shippingAddress"];
    }
    console.log("payload", payload);
    fnSetLoading(true);
    setLoading(true);
    try {
      const { data } = await CreateOwnerRegister({ data: payload });
      console.log("resData.data >>", data);
      if (+data["resultCode"] === 20000) {
        console.log("resData.data >>", data.body);
        navigate("/register-success", {
          state: { point: data.body.currentPoint },
        });
      }
      fnSetLoading(false);
      setLoading(false);
    } catch (error) {
      fnSetLoading(false);
      setLoading(false);
      console.log("postCreateOwnerRegister error", error);
    }
  }

  const postCreateUserAnswer = (payload = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await CreateUserAnswer({ data: payload });
        if (data.resultCode === 20000) {
          resolve(data);
        } else {
          resolve(null);
        }
      } catch (error) {
        resolve(null);
        console.log("create pet error", error);
      }
    });
  };

  return (
    <div id="PageRegister">
      <div className="bgPrimaryGD p-[20px] min-h-[100vh]">
        <div className="bg-white p-[20px] rounded-[5px]">
          <div className="primary-color " style={{ fontSize: width * 0.06 }}>
            นโยบายคุ้มครองข้อมูลส่วนบุคคล
          </div>
          <div
            className="text-[18px] mb-[10px]"
            style={{ fontSize: width * 0.04 }}
          >
            ข้าพเจ้า ผู้ลงนามข้างท้ายแบบฟอร์มนี้ ขอยืนยันว่าข้าพเจ้าได้อ่าน
            และเข้าใจในรายละเอียดข้างต้นเกี่ยวกับการเก็บรวบรวม ใช้เปิดเผย โอน
            หรือประมวลผลข้อมูลส่วนบุคคลของข้าพเจ้าแล้ว ตลอดจน รับทราบสิทธิต่าง ๆ
            ที่ข้าพเจ้าพึงมีในฐานะเจ้าของข้อมูล
            ส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
            และกฎหมายอื่น ๆ ที่เกี่ยวข้อง
          </div>
          <div
            className="text-[18px] mb-[10px]"
            style={{ fontSize: width * 0.04 }}
          >
            ข้าพเจ้าเข้าใจข้อความและวัตถุประสงค์ต่าง ๆ ในการเก็บรวบรวม ใช้
            เปิดเผย โอน หรือประมวลผลข้อมูลส่วนบุคคลของข้าพเจ้า ที่บริษัทฯ
            ระบุไว้ในประกาศนโยบายความเป็นส่วนตัวของบริษัทฯ โดยตลอดแล้ว
            ตามรายละเอียดข้างต้น จึงขอแสดงเจตนาเกี่ยวกับ
            การให้ความยินยอมโดยการใส่เครื่องหมายลงในช่องที่กำหนดไว้ ด้านล่างนี้
          </div>
          <div
            className="text-[18px] mb-[10px]"
            style={{ fontSize: width * 0.04 }}
          >
            ข้าพเจ้าทราบดีว่าการที่บริษัทฯ ไม่ได้รับความยินยอมโดยชัดแจ้ง
            จากข้าพเจ้าจะมีผลต่อบริษัทฯ ในการเสนอสินค้าและ/หรือบริการ
            แก่ข้าพเจ้า ตลอดจนทราบดีว่าข้าพเจ้าสามารถขอถอนความ
            ยินยอมที่ข้าพเจ้าได้ให้ไว้แก่บริษัทฯ ได้ทุกเมื่อ และบริษัทฯ
            จะปฏิบัติ ต่อข้อมูลส่วนบุคคลของข้าพเจ้าด้วยความเคารพข้อมูลส่วน
            บุคคลทั่วไป
          </div>
          <div className="text-center mb-[15px]">
            <button
              className="bText-primary-d text-[18px]"
              onClick={onClickLinkPolicy}
            >
              รายละเอียดนโยบายความเป็นส่วนตัว
            </button>
          </div>
          <div className="flex flex-row place-content-center gap-5">
            <SecondaryButton
              type="button"
              variant="outlined"
              style={{ minWidth: "30%" }}
              onClick={onCancel}
            >
              ไม่ยินยอม
            </SecondaryButton>
            <PrimaryButton
              variant="contained"
              style={{ minWidth: "30%" }}
              // onClick={onOk}
              onClick={onOk}
              disabled={isLoading}
            >
              ยินยอม
            </PrimaryButton>
          </div>
        </div>
      </div>
      <ConditionDialog
        title="ประกาศนโยบายความเป็นส่วนตัว"
        open={openPolicyDialog}
        toggle={togglePolicyDialog}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Policy />
      </ConditionDialog>
    </div>
  );
}
