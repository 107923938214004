
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

import TopLogo from '../../components/layout/TopLogo';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../components/button/BackButton';
import SecondaryButton from '../../components/button/SecondaryButton';
import PrimaryButton from '../../components/button/PrimaryButton';
import excelIcon from "../../assets/images/my-point-shop/excel-icon.png";
import { InputLabel, MenuItem, Select, TextField, Checkbox } from "@mui/material";
import helper from '../../functions/helper';
import dayjs from 'dayjs';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { SearchId } from "../../services/SearchId";
import InfiniteScroll from 'react-infinite-scroller';
import { SearchPoint, SearchDownloadPointHistory } from "../../services/MyPoint";
import MyPointShopExportSuccess from "./MyPointShopExportSuccess";
import { setLoading } from '../../features/LoadingSlice';
import { useLiff } from "react-liff";
import DatePickerRange from "./DatePickerRange"


export default function MyPointHistory() {
    const store = useSelector((state) => state.myPoint)
    const [ values, setValues ] = useState([])
    const [ isSuccess, setIsSuccess ] = useState(false)
    const [ isSubmit, setIsSubmit ] = useState(false)
    const dispatch = useDispatch()
    const [ clinicPetShopId, setClinicPetShopId ] = useState(null)
    const [ employeeId, setEmployeeId ] = useState(null)
    const [ memberId, setMemberId ] = useState(null)
    const [ email, setEmail ] = useState(null)
    const navigate = useNavigate();
    const { liff } = useLiff();
    const lineId = liff.getDecodedIDToken().sub;

    useEffect(() => {
        getSearchId();
        // eslint-disable-next-line
    }, []);
    async function getSearchId() {
      return await SearchId({
        params: { lineId: lineId || null },
      })
        .then(async (response) => {
          if (!response.data.body.memberId) {
            window.location.replace("/register");
          } else {
            const clinicPetShopId = response.data?.body?.clinicPetShopId ?? null
            const employeeId = response.data?.body?.employeeId ?? null
            const memberId = response.data?.body?.setMemberId ?? null
            console.log('clinicPetShopId >> ', clinicPetShopId)
            console.log('employeeId >> ', employeeId)
            const payload = { clinicPetShopId, employeeId, limit: 10, offset: 0 }
            const { clinicPetShop } = await getSearchPoint(payload);
            // console.log('res >>', res)
            setClinicPetShopId(clinicPetShopId)
            setEmployeeId(employeeId)
            setMemberId(memberId)
            setEmail(clinicPetShop.employee.email)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getSearchPoint(payload = {}) {
      const { data } = await SearchPoint({ params: payload })
      if (+data['resultCode'] === 20000) {
        return data.body || {};
      } else {
        return {};
      }
    }

    async function postDownloadPointHistory(payload = {}) {
      try {
        fnSetLoading(true)
        const { data } = await SearchDownloadPointHistory({ params: payload });
        console.log("resData.data >>", data);
        if (data.resultCode == 20000) {
          console.log("resData.data >>", data.body);
          setIsSuccess(true)
        }
        fnSetLoading(false)
      } catch (error) {
        fnSetLoading(false)
        console.log("SearchDownloadPointHistory error", error);
      }
    }

    function fnSetLoading(value) {
      let tmpObj = {};
      tmpObj.loading = value;
      dispatch(setLoading(tmpObj));
    }

    function getReport() {
      setIsSubmit(true)
      const { start, end } = helper.rDatetoValue(values)
      const payload = {
        clinicPetShopId: clinicPetShopId,
        memberId: memberId,
        employeeId: employeeId,
        email: email,
        startDate: start,
        endDate: end,
      }
      
      if(values.length === 2){
        postDownloadPointHistory(payload)
      }
    }

    if (isSuccess) {
      return <MyPointShopExportSuccess email={email}/>
    }


    return (
        <div id="my-point-history">
          <TopLogo />
          <div id="gradient" className="bgPrimaryGD px-[13px] pt-[22px] pb-[12px] overflow-hidden">
            <div id="viewport" className="flex flex-col p" style={{ height: "calc(100vh - 94px)"}}>
              <div className='bg-white rounded-[4px] flex-1 px-[17px] py-[24px] text-center flex flex-col'>
                <div className='flex-1  flex flex-col items-center'>
                  <div className="text-[36px] text-lightBlue leading-[84%] mb-[4px]">
                    <img src={excelIcon} alt="excelicon" className="" />
                  </div>
                  <p className="text-[36px] text-lightBlue leading-[84%] mb-[12px]">ประวัติการสะสม NexCoin</p>
                  <p className="text-[16px] bText-gray leading-[120%]">ไฟล์ excel จะส่งไปยังอีเมล</p>
                  <p className="text-[28px] text-darkGray leading-[120%] mb-[8px]">{email}</p>
                  <p className="text-[16px] bText-gray leading-[120%] xmax-w-[250px] w-[75%] mb-[34px]">หากไม่ใช่อีเมลที่คุณต้องการ คุณสามารถเปลี่ยนอีเมลที่เมนู 
                    <span className='text-darkGray'> บัตรสมาชิกและข้อมูลร้านค้า</span>
                  </p>
                  <div className="w-[75%]">
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'th'}>
                      <MobileDatePicker
                        className="w-full"
                        label="เลือกช่วงเวลาของข้อมูล"
                        variant="standard"
                        inputFormat="DD/MM/YYYY"
                        name="vaccineDate"
                        maxDate={dayjs()}
                        // value={userInput.vaccineDate}
                        onChange={(value) => {
                        
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            error={false}
                            helperText="เลือกช่วงเวลาของข้อมูล"
                          />
                        )}
                      />
                    </LocalizationProvider> */}
                    <DatePickerRange label="เลือกช่วงเวลาของข้อมูล" values={values} setValues={setValues} 
                      required={true} error={values.length == 0 && isSubmit} helperText={'กรุณาเลือกช่วงเวลาของข้อมูล'}/>
                  </div>
                </div>
                <div className="flex gap-[13px] px-[16px]">
                  <SecondaryButton className="w-full" variant="outlined" onClick={() => navigate(-1)}  >
                    <p className="text-[24px] text-center"> ยกเลิก </p>
                  </SecondaryButton>
                  <PrimaryButton className="w-full" variant="outlined" onClick={() => getReport()}>
                    ตกลง
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
         
        </div>
    )
}