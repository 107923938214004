import { get } from "./Api";

export function searchDdlFavoriteProduct() {
  return get({ url: "/searchDdlFavoriteProduct" });
}

export function SearchDdlAddress({ params }) {
  return get({ url: "/searchDdlAddress", params });
}

export function SearchDdlEvent({ params }) {
  return get({ url: "/searchDdlEvent", params });
}

export function SearchAdminConfig({ params }) {
  return get({ url: "/searchAdminConfig", params });
}

export function SearchTierConfig() {
  return get({ url: "/searchTierConfig" });
}

export function SearchVaccineConfig() {
  return get({ url: "/searchVaccine" });
}
