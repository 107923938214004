/* eslint-disable react/jsx-no-undef */
import React from "react";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RegisterBanner2 from "../../assets/images/register-banner2.png";
import Cat3 from "../../assets/images/cat3.png";
import helper from "../../functions/helper.js";

import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import SecondaryButton from "../../components/button/SecondaryButton";
import PrimaryButton from "../../components/button/PrimaryButton";
import { searchDdlFavoriteProduct } from "../../services/Dropdown";
import TopBanner from "../../components/layout/TopBanner";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { setRegisterPetShop } from "../../features/register/RegisterPetShopSlice";
import { useDispatch, useSelector } from "react-redux";
import TopLogo from "../../components/layout/TopLogo";
import { searchRecommendIdByMember } from "../../services/Register.js";

export default function RegisterOwner() {
  const store = useSelector((state) => state.registerPetShop);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [recommenderIdError, setRecommenderIdError] = React.useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm({
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      firstName: store["firstName"],
      lastName: store["lastName"],
      email: store["email"],
      mobileNumber: store["mobileNumber"],
      recommenderId: store["recommenderId"],
      favoriteProductId: store["favoriteProductId"],
      isHaveCat: store["isHaveCat"],
    },
  });

  function fnSetData(name, value) {
    const tmpObj = {};
    tmpObj[name] = value;
    dispatch(setRegisterPetShop(tmpObj));
  }

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name && type) {
        console.log(value, name, type);
        fnSetData(name, value[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    console.log("data", data);
    if (recommenderIdError === false) {
      // เช็คเพิ่ม recommenderIdError
      fnSetData("isPetShop", false);
      navigate("/privacy-policy", { state: data });
    }
  };

  const [dropdownMenu, setDropdownMenu] = React.useState([]);

  React.useEffect(() => {
    getSearchDdlFavoriteProduct();
  }, []);

  async function getSearchDdlFavoriteProduct() {
    try {
      const { data } = await searchDdlFavoriteProduct();
      if (data.resultCode === 20000) {
        setDropdownMenu(data.body);
      }
    } catch (error) {
      console.log("getSearchDdlFavoriteProduct error", error);
    }
  }

  const checkRecommenderId = async (recommenderId = "") => {
    if (recommenderId) {
      const recommendData = await checkRecommendIdByMember({
        recommenderId: recommenderId,
      });
      if (recommendData.status) {
        setRecommenderIdError(false);
      } else {
        setRecommenderIdError(true);
      }
    } else {
      setRecommenderIdError(false);
    }
  };

  const checkRecommendIdByMember = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await searchRecommendIdByMember({ params: payload });
      if (data.resultCode === "20000") {
        const resData = data.resultData;
        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  // React.useEffect(() => {
  //   console.log("useEffect recommenderId!", watch("recommenderId"));
  //   checkRecommenderId(watch("recommenderId"));
  //   // Call FN check recommenderId
  // }, [watch("recommenderId")]);

  return (
    <div id="PageRegister">
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <TopLogo />
        <TopBanner />
        <div className="bContentMain">
          <div className="bContent">
            <div className="bSteper registerStep2"/>
            <div className="bg-white">
              <div style={{ fontSize: 30, textAlign: "center" }}>
                ลงทะเบียนสำหรับ &nbsp;
                <span style={{ fontWeight: "bold" }}>ผู้เลี้ยงแมว</span>
              </div>
              <div style={{ textAlign: "center" }}>
                <img
                  src={Cat3}
                  alt="logo"
                  style={{ width: "20%", display: "initial" }}
                />
              </div>
              <div style={{ padding: "0 10px" }}>
                <div className="primary-color title-size">ข้อมูลส่วนตัว</div>
                <div className="flex gap-[20px] justify-between h-input">
                  <TextField
                    required
                    fullWidth
                    label="ชื่อ"
                    variant="standard"
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message}
                    {...register("firstName", { required: "กรุณากรอกชื่อ" })}
                  />

                  <TextField
                    required
                    fullWidth
                    label="นามสกุล"
                    variant="standard"
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message}
                    {...register("lastName", { required: "กรุณากรอกนามสกุล" })}
                  />
                </div>

                <div className="h-input">
                  <TextField
                    fullWidth
                    label="อีเมล"
                    variant="standard"
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                    {...register("email")}
                  />
                </div>

                <div className="h-input">
                  <TextField
                    required
                    fullWidth
                    label="เบอร์โทรศัพท์"
                    variant="standard"
                    error={!!errors?.mobileNumber}
                    helperText={errors?.mobileNumber?.message}
                    inputProps={{ maxLength: 10 }}
                    {...register("mobileNumber", {
                      required: "กรุณากรอกเบอร์โทรศัพท์",
                      minLength: {
                        value: 10,
                        message: "กรุณากรอกเบอร์โทรศัพท์อย่างน้อย 10 หลัก",
                      },
                    })}
                  />
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginTop: "10px",
                  }}
                >
                  กรอกรหัสผู้แนะนำ จะรับคะแนนเพิ่ม
                </div>

                <div className="h-input">
                  <TextField
                    fullWidth
                    label="รหัสผู้แนะนำ"
                    variant="standard"
                    error={recommenderIdError}
                    {...register("recommenderId")}
                    onInput={
                      (helper.FNFORM.handleCharEngOnly, helper.FNFORM.isUpperCase)
                    }
                    onChange={({ target: { value } }) => {
                      if (value.length === 9) {
                        checkRecommenderId(value);
                      } else {
                        setRecommenderIdError(false);
                      }
                    }}
                    inputProps={{ maxLength: 9 }}
                  />
                  <FormHelperText error={recommenderIdError} className="!ml-0">
                    รหัสผู้แนะนำไม่ถูกต้อง
                  </FormHelperText>
                </div>

                <div className="primary-color title-size pb-3">
                  ข้อมูลเพิ่มเติม
                </div>

                <div className="h-input">
                  <Controller
                    name="favoriteProductId"
                    control={control}
                    rules={{
                      required: "กรุณาเลือกชื่อผลิตภัณฑ์ป้องกันปรสิตที่ใช้ประจำ",
                    }}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel required className="ml-[-14px]">
                          ชื่อผลิตภัณฑ์ป้องกันปรสิตที่ใช้ประจำ
                        </InputLabel>
                        <Select
                          {...field}
                          fullWidth
                          variant="standard"
                          error={!!errors?.favoriteProductId}
                          className="!mt-[5px]"
                          IconComponent={KeyboardArrowDownRoundedIcon}
                        >
                          {dropdownMenu.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText
                          error={!!errors?.favoriteProductId}
                          className="!ml-0"
                        >
                          {errors?.favoriteProductId?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>

                <div className="pb-[20px]">
                  <FormControl>
                    <FormLabel>ที่บ้านของท่านเลี้ยงสุนัขด้วยหรือไม่</FormLabel>
                    <Controller
                      name="isHaveCat"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup row {...field}>
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="เลี้ยง"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="ไม่เลี้ยง"
                          />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-[20px] p-[20px] flex gap-[12px]">
          <button type="button" className="btn _outline w-full"  onClick={() => navigate(-1)}>กลับ</button>
          <button className="btn w-full">ยืนยัน</button>
        </div>
      </form>
    </div>
  );
}
